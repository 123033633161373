import React from 'react';
import { RulesType } from 'types/FormTypes';
import { FormItemStyled, StyledTimePicker } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  disabled?: boolean;
  timeProps?: {
    disabledHours?: () => number[];
    disabledMinutes?: () => number[];
  };
  hint?: React.ReactNode | string;
  onChange?: any;
}
const TimePickerComponent: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  disabled,
  timeProps,
  hint,
  onChange,
}) => (
  <FormItemStyled
    name={name}
    label={placeholder}
    rules={rules}
    {...(hint && { tooltip: hint })}
  >
    <StyledTimePicker
      placeholder={placeholder}
      disabled={disabled}
      format="HH:mm"
      {...timeProps}
      onChange={onChange}
    />
  </FormItemStyled>
);
TimePickerComponent.defaultProps = {
  rules: [],
  disabled: false,
};
export default TimePickerComponent;
