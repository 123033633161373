/* eslint-disable indent */
import styled from 'styled-components';
import { Tag, Typography } from 'antd';

export const TextBold = styled(Typography.Text)`
  color: #101828;
  font-weight: 500;
  font-size: 14px;
`;

export const TextBolder = styled(TextBold)`
  font-weight: 600;
`;

export const Text = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  color: #475467;
`;
export const LinkText = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  color: #4185ea;
`;

export const TextLight = styled(Text)`
  color: #101828;
  font-weight: 300;
  font-size: 12px;
`;

export const TextStyle = styled(Typography.Text)`
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
`;

export const StatusTag = styled.div<{
  status: string;
}>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === 'new' && 'background-color:#edeff3; color:#344054'};
  ${({ status }) =>
    status === 'verify' && 'background-color:#ECFDF3; color:#027A48 '};
  ${({ status }) =>
    status === 'hired' && 'background-color:#ECFDF3; color:#027A48 '};
  ${({ status }) =>
    status === 'rejected' && 'background-color:#FEF3F2; color: #B42318 '};

  p {
    margin-bottom: 0;
    margin-left: 1px;
    text-transform: capitalize;
  }
`;
export const RoleTag = styled.div<{
  roles: any;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ roles }) =>
    roles === 'admin' && 'background-color:#ebedff63; color:#0c6686'};
  ${({ roles }) =>
    roles === 'guest' && 'background-color:#1ba46714; color:#1ba467 '};
  ${({ roles }) =>
    roles === 'hr' && 'background-color:#FEF3F2; color: #B42318 '};
  ${({ roles }) =>
    roles === 'guest_tracking_hours' &&
    'background-color:#e1ccf81c; color: #8a2be2 '};
  ${({ roles }) =>
    roles === 'employee' && 'background-color:#ebedff63; color: #2333fed6 '};
  ${({ roles }) =>
    roles === 'editor' && 'background-color:#e01e890a; color: #e01e89'};
  ${({ roles }) =>
    roles === 'payroll_manager' && 'background-color:#f5f5dc; color: #556b2f '};
  p {
    margin-bottom: 0;
    margin-left: 1px;
    text-transform: capitalize;
  }
`;

export const Status = styled(Tag)<{
  status: string;
}>`
  border-radius: 8px;

  ${({ status }) =>
    status === 'APPROVED' &&
    'background-color: #f6ffed; color: #52c41a; border-color: #b7eb8f'};
  ${({ status }) =>
    status === 'PENDING' &&
    'background-color: #e6f7ff;; color: #1890ff;  border-color: #91d5ff;'};
  ${({ status }) =>
    status === 'REJECTED' &&
    'background-color: #fff2f0; color: #ff4d4f; border-color: #ffccc7'};
`;
