import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  InterviewOrderType,
  InterviewInputType,
  InterviewCurrentType,
} from 'types/Interview';
import {
  createNewInterview,
  getCandidateInterviews,
  updateInterview,
} from 'api/interviewService';
import { OptionType } from 'types/OptionTypes';
import { CandidateType } from 'types/Candidates';
import { useInterviewsData } from 'ui-v2/hooks/useInterviewsData';
import { getInterviewTypeOrder } from 'api/interviewTypeService';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';

import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';

import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  disableDatesExcludingCurrent,
  getUserDateFormat,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { fetchInterviews } from 'redux/interviewsPage/actions';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { fetchCandidates } from 'redux/candidates/actions';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import {
  getFormatedDateTime,
  getFormatedTime,
  getOptionTypeMappingContainingFullName,
  getOptionTypeMappingContainingOpenPositionName,
} from './utils';
import { isFormEdited } from '../Form/utils';

export default function InterviewModalForm({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const userDateFormat = getUserDateFormat();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    list: candidates,
    loaded: loadedCandidates,
    loading: loadingCandidates,
  } = useCandidateData();

  const { interview } = useInterviewsData();

  const [loading, setLoading] = useState<boolean>(false);
  const [partecipants, setPartecipants] = useState<OptionType[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<string>();
  const [selectedCandidateOP, setSelectedCandidateOP] = useState<string>();
  const [defaultPartecipants, setDefaultPartecipants] = useState<OptionType[]>(
    []
  );
  const [interviewTypeOptions, setInterviewTypeOptions] = useState<
    OptionType[]
  >([]);
  const { filteredEmployeeSkimOptions, getEmployeeSkimSelectWithLoadOptions } =
    useEmployeeSkimData();

  async function fetchInterviewPartecipants(candidateOpenPositionId: string) {
    if (!interview?.id) return;
    setLoading(true);
    await getCandidateInterviews(candidateOpenPositionId)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data) {
            const currentInterview: InterviewCurrentType = response?.data?.find(
              (i: { id: string }) => i?.id === interview?.id
            );
            const interviewParticipants =
              getOptionTypeMappingContainingFullName(
                currentInterview?.partecipants
              );
            if (interviewParticipants?.length) {
              setDefaultPartecipants(interviewParticipants);
            }
          }
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchInterviewTypes(candidateOpenPositionId: string) {
    setLoading(true);
    getInterviewTypeOrder({ candidateOpenPositionId })
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          const options: Array<OptionType> = res?.data?.map(
            (el: InterviewOrderType) =>
              ({
                id: el?.id,
                value: el?.id,
                label: el?.name,
              } || [])
          );

          const notDuplicate =
            interview?.id &&
            !options.find((el) => el?.id === interview?.interviewType?.id)?.id;

          if (notDuplicate) {
            const defaultInterviewType: OptionType = {
              id: interview?.interviewType?.id,
              value: interview?.interviewType?.id,
              label: interview?.interviewType?.name,
            };
            setInterviewTypeOptions([defaultInterviewType, ...options]);
            form.setFieldsValue({
              interviewTypeId: defaultInterviewType?.id,
            });
          } else {
            setInterviewTypeOptions(options);
            form.resetFields(['interviewTypeId']);
          }
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const init = () => {
    if (interview?.id) {
      fetchInterviewTypes(interview?.candidateOpenPositions?.id);
      setSelectedCandidateOP(interview?.candidateOpenPositions?.id);
      fetchInterviewPartecipants(interview?.candidateOpenPositions?.id);
      setSelectedCandidate(interview?.candidateOpenPositions?.candidate?.id);
    }
  };

  useEffect(() => {
    if (loadedCandidates && candidates?.length) {
      init();
    }
  }, [loadedCandidates]);

  useEffect(() => {
    if (filteredEmployeeSkimOptions) {
      setPartecipants(filteredEmployeeSkimOptions);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCandidates());
  }, []);

  const onSubmit = (value: InterviewInputType) => {
    setLoading(true);

    const date = getFormatedDateTime(
      value.date as string,
      value?.time as string
    );
    delete value?.time;

    if (interview?.id) {
      delete (value as Partial<InterviewInputType>).candidateId;
      delete (value as Partial<InterviewInputType>).candidateOpenPositionId;
    }

    const valuesToSend: InterviewInputType = {
      ...value,
      date: convertDateToUTC(date),
      partecipants: value.partecipants?.map((el: any) => el?.value),
      interviewTypeId: value.interviewTypeId,
      candidateOpenPositionId: value.candidateOpenPositionId,
    };

    if (!interview?.id) {
      createNewInterview(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Interview scheduled successfully!'));
            dispatch(fetchInterviews({ page: 1, take: 10 }));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: interview,
          entity: 'interview',
        })
      ) {
        closeModal();
        setLoading(false);
        return;
      }
      updateInterview(interview.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Interview updated successfully!'));
            dispatch(fetchInterviews({ page: 1, take: 10 }));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCandidateSelect = async (candidateId: string) => {
    setSelectedCandidate(candidateId);
    setSelectedCandidateOP(undefined);
    form.resetFields(['candidateOpenPositionId', 'interviewTypeId']);
  };

  const onOpenPositionSelect = (candidateOpenPositionId: string) => {
    setSelectedCandidateOP(candidateOpenPositionId);
    fetchInterviewTypes(candidateOpenPositionId);
    form.resetFields(['candidateOpenPositionId', 'interviewTypeId']);
  };

  const InterviewFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      !interview?.id
        ? [
            {
              col: 11,
              offset: 0,
              name: 'candidateId',
              label: t('Candidate'),
              type: InputTypes.SELECT,
              defaultValue: interview?.candidateOpenPositions?.candidate?.id,
              selectOptions: getOptionTypeMappingContainingFullName(
                candidates || []
              ),
              onSelect: onCandidateSelect,
              rules: [{ required: true, message: t('Candidate is required!') }],
            },
            {
              col: 11,
              offset: 2,
              name: 'candidateOpenPositionId',
              label: t('Open Position'),
              type: InputTypes.SELECT,
              defaultValue: selectedCandidateOP,
              disabled: !selectedCandidate,
              onSelect: onOpenPositionSelect,
              selectOptions: getOptionTypeMappingContainingOpenPositionName(
                candidates?.find(
                  (el: CandidateType | undefined) =>
                    el?.id === selectedCandidate
                )?.candidateOpenPositions || []
              ),
              rules: [
                { required: true, message: t('Open Position is required!') },
              ],
            },
          ]
        : [
            {
              col: 11,
              offset: 0,
              name: 'candidateId',
              label: t('Candidate'),
              type: InputTypes.INPUT,
              defaultValue: `${interview?.candidateOpenPositions?.candidate?.firstName} ${interview?.candidateOpenPositions?.candidate?.lastName}`,
              disabled: true,
            },
            {
              col: 11,
              offset: 2,
              name: 'candidateOpenPositionId',
              label: t('Open Position'),
              type: InputTypes.INPUT,
              defaultValue:
                interview?.candidateOpenPositions?.openPositions?.name,
              disabled: true,
            },
          ],
      [
        {
          col: 24,
          offset: 0,
          name: 'interviewTypeId',
          label: t('interviewType'),
          type: InputTypes.SELECT,
          selectOptions: interviewTypeOptions,
          defaultValue: interview?.interviewType?.id || '',
          disabled: !selectedCandidateOP,
          rules: [
            {
              required: true,
              message: t('Interview Type is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'date',
          label: t('selectDate'),
          placeholder: t('Select a due date'),
          defaultValue: convertUTCtoLocalTime(interview?.date),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: disableDatesExcludingCurrent,
          },
          type: 'datepicker',
          rules: [
            {
              required: true,
              message: t('Date is required!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'time',
          label: t('selectTime'),
          placeholder: t('Select due time'),
          defaultValue: getFormatedTime(interview),
          type: 'timepicker',
          rules: [
            {
              required: true,
              message: t('Time is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'partecipants',
          label: t('participants'),
          type: InputTypes.SELECT_LOAD,
          isMultiSelect: true,
          mode: 'multiple',
          selectOptions: partecipants,
          defaultValue: defaultPartecipants || [],
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
          rules: [
            {
              required: true,
              message: t('At least one participant is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'comments',
          label: t('comment'),
          type: 'textarea',
          defaultValue: interview?.comments,
          rules: [
            {
              required: true,
              message: t('Comment is required!'),
            },
          ],
        },
      ],
    ],
    [
      candidates,
      selectedCandidateOP,
      selectedCandidate,
      defaultPartecipants,
      interviewTypeOptions,
      partecipants,
    ]
  );

  return (
    <GenericModal
      title={interview?.id ? t('editInterview') : t('scheduleInterview')}
      open={open}
      closeModal={closeModal}
      icon={<ScheduleInterviewModalIcon />}
    >
      <GenericForm
        formConfiguration={InterviewFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading || loadingCandidates}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('schedule')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
