import React, { useEffect, useState } from 'react';
import { Button, Col, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { deleteBonus, getEmployeeBonus } from 'api/bonusService';
import { Bonus } from 'types/Bonus';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import CardItem from '../Card';
import * as Styled from '../Task/TaskStyles';
import { BUTTONS } from '../HeaderActions/config';
import RoleBasedButton from '../RoleBasedButton';
import AddBonusModal from './AddBonusModal';
import { BonusItem } from './BonusItem';

interface IProps {
  employeeId: string;
}

export default function BonusComponent({ employeeId }: IProps) {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bonuses, setBonuses] = useState<Bonus[]>([]);
  const [selectedBonus, setSelectedBonus] = useState<any>(null);
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const onToggleModal = () => setAddModalVisible((state) => !state);

  const fetchEmployeeBonuses = async (id: string) => {
    setLoading(true);

    try {
      setLoading(true);
      const response = await getEmployeeBonus(id);
      if (response.status === 200 && response.data) {
        setBonuses(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t('somethingWentWrong'));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!employeeId) return;
    if (typeof employeeId === 'string') {
      fetchEmployeeBonuses(employeeId);
    }
  }, [employeeId]);

  const deleteEmployeeBonus = async (bonusId: string) => {
    await deleteBonus(bonusId)
      .then(() => {
        toast.success(t('bonusDeleted'));
        fetchEmployeeBonuses(employeeId);
      })
      .catch(() => {
        toast.error(t('errorDeletingthisBonus'));
      });
  };

  return (
    <CardItem title={t('bonuses')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          {bonuses.map((b: Bonus, index: number) => (
            <Col span={24}>
              <BonusItem
                key={b.id}
                {...b}
                item={b}
                onDelete={() => deleteEmployeeBonus(b.id || '')}
                isLast={index === bonuses.length - 1}
                onItemClick={() => {
                  setSelectedBonus(b);
                }}
              />
            </Col>
          ))}
          {!bonuses.length && (
            <Col>
              {authUserRole === AUTH_ROLES.EMPLOYEE ||
              authUserRole === AUTH_ROLES.PAYROLL_MANAGER ? (
                <Styled.Label>{t('youDontHaveAnyBonuses')}</Styled.Label>
              ) : (
                <Styled.Label>{t('assignABonusToThisEmployee')}</Styled.Label>
              )}
            </Col>
          )}
          <RoleBasedButton btn={BUTTONS.ADD_BONUS}>
            <Col span={24}>
              <Button
                icon={
                  <Styled.AddSpaceIcon>
                    <PlusCircleOutlined />
                  </Styled.AddSpaceIcon>
                }
                size="large"
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  fontWeight: '600',
                  fontSize: '16px',
                  color: '344054',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={onToggleModal}
              >
                {t('addBonus')}
              </Button>
            </Col>
          </RoleBasedButton>
        </Styled.RowItem>
        <RoleBasedButton btn={BUTTONS.EDIT_BONUS}>
          {addModalVisible && (
            <AddBonusModal
              open
              closeModal={() => {
                setAddModalVisible(false);
              }}
              employeeId={employeeId}
              fetchEmployeeBonuses={fetchEmployeeBonuses}
              selectedBonus={undefined}
            />
          )}
          {selectedBonus?.id && (
            <AddBonusModal
              closeModal={() => {
                setSelectedBonus(null);
              }}
              open
              employeeId={employeeId}
              fetchEmployeeBonuses={fetchEmployeeBonuses}
              id={selectedBonus.id}
              selectedBonus={selectedBonus}
            />
          )}
        </RoleBasedButton>
      </Skeleton>
    </CardItem>
  );
}
