import { useSelector } from 'react-redux';

export function useKnowledgeData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.knowledge
  );

  return {
    list: data,
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
