import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { t } from 'i18next';

const DepartmentsActions: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onViewClick = () => {
    navigate(`department-details?id=${id}`);
  };

  const onEditClick = () => {
    searchParams.append('edit-department', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('details')}>
              <StyledIcon onClick={onViewClick}>
                <ViewProfileIcon />
              </StyledIcon>
            </Tooltip>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editDepartment')}>
                <StyledIcon onClick={onEditClick}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default DepartmentsActions;
