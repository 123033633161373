import React, { useEffect, useState } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { clearFetchSingleIdeabox, fetchIdeabox } from 'redux/ideabox/actions';
import useIdeaboxData from 'ui-v2/hooks/useIdeaboxData';
import { removeIdeabox } from 'api/ideaboxService';
import IdeaboxModalForm from 'components/NewForms/IdeaBoxForm';

export default function IdeaboxList() {
  const { columns, take } = useHeader();
  const { data, loading, pagination } = useIdeaboxData();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearFetchSingleIdeabox());
    dispatch(fetchIdeabox({ pageOptions: { page: 1, take } }));
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      pageOptions: { page, take },
      filterOptions: { filterText: searchTerm },
    };
    dispatch(fetchIdeabox(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      pageOptions: { take },
      filterOptions: { filterText: searchTerm },
    };
    dispatch(fetchIdeabox(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('add-ideabox') === 'true') {
      searchParams.delete('add-ideabox');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
    if (searchParams.get('edit-ideabox')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  async function deleteIdeaboxById(id: string | null) {
    if (!id) return;
    removeIdeabox(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('deletedIdeabox'));
          dispatch(
            fetchIdeabox({
              pageOptions: { page: pagination?.page, take },
            })
          );
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    const arrayOfPromises: Array<Promise<unknown>> = [];
    ids?.forEach(async (id) => {
      arrayOfPromises.push(deleteIdeaboxById(id?.toString()));
    });
    await Promise.all(arrayOfPromises);
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={data}
          paginationName="Ideabox"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <IdeaboxModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-ideabox');
              setSearchParams(searchParams);
              dispatch(clearFetchSingleIdeabox());
            }}
          />
        )}
      </>
    </Content>
  );
}
