import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useMemo, useState } from 'react';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTranslation } from 'react-i18next';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { createGoalFeedback } from 'api/goalService';
import { GoalFeedBackCreateDTO } from 'types/Goal';
import { toast } from 'react-toastify';

interface IProps {
  open: boolean;
  closeModal: () => void;
  goalId: string;
}

export default function AddFeedbackModal({ open, closeModal, goalId }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'content',
          label: t('Feedback'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('Feedback is required'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'rating',
          label: t('Rating'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('Rating is required'),
            },
          ],
        },
      ],
    ],
    []
  );

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues: any = form.getFieldsValue(true);

    const valuesToSend: GoalFeedBackCreateDTO = {
      content: formValues.content,
      goalId,
      feedbackProviderId: authUser?.employee?.id || '',
      feedbackDate: new Date(),
      rating: formValues.rating,
    };

    try {
      const response = await createGoalFeedback(valuesToSend);
      if (response.status === 200 && response.data) {
        toast.success('Feedback saved!');
        closeModal();
      }
    } catch (e) {
      toast.warning('Could not send feedback!');
    }
  };

  return (
    <GenericModal
      title="Leave Feedback"
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
