import { Dispatch } from 'redux';
import {
  getFeatureFlagsTenant,
  updateFeatureFlagsTenants,
} from 'api/featureFlagsTenant';
import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';
import { FeatureFlagsTenantAction } from './types';

export const fetchFeatureFlagsTenants =
  (tenantId: string) => (dispatch: Dispatch) => {
    dispatch({
      type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_START,
    });
    getFeatureFlagsTenant(tenantId)
      .then((res) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FeatureFlagsTenantAction.FETCH_FEATURE_FLAGS_TENANT_ERRORED,
          payload: e,
        });
      });
  };

export const updateFeatureFlagsTenant =
  (payload: UpdateFeatureFlagsTenantDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_START,
    });
    updateFeatureFlagsTenants(payload)
      .then((res) => {
        dispatch({
          type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_SUCCESS,
          payload: [...res.data],
        });
      })
      .catch((e) => {
        dispatch({
          type: FeatureFlagsTenantAction.UPDATE_FEATURE_FLAGS_TENANT_ERRORED,
          payload: e,
        });
      });
  };

export const clearFetchFeatureFlagsTenants = () => (dispatch: Dispatch) => {
  dispatch({
    type: FeatureFlagsTenantAction.CLEAR_FEATURE_FLAGS_TENANT,
  });
};
