import { Goal } from 'types/Goal';

export enum GoalAction {
  FETCH_GOAL_START = 'FETCH_GOAL_START',
  FETCH_GOAL_SUCCESS = 'FETCH_GOAL_SUCCESS',
  FETCH_GOAL_ERRORED = 'FETCH_GOAL_ERRORED',

  CLEAR_GOAL = 'CLEAR_GOAL',
}

export interface GoalState {
  data: Array<Goal>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
