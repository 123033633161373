import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)`
  width: 100%;
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const Label = styled(Typography.Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaecf0;
`;
