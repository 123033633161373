/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  HeartOutlined,
  CommentOutlined,
  PushpinOutlined,
  SendOutlined,
  HeartFilled,
} from '@ant-design/icons';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import { LikePost } from 'types/Post';
import { EmployeesSkim } from 'types/Employee';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import { RootState } from 'redux/store';
import {
  ActionButton,
  ActionButtons,
  CommentAvatar,
  Comment,
  CommentContent,
  CommentList,
  CommentText,
  CommentUser,
  CommentsSection,
  PostContainer,
  CommentInputWrapper,
  AddCommentButton,
  PostContent,
  NoCommentsMessage,
  PinIconWrapper,
  Count,
  TextWithPinWrapper,
  StyledMentions,
  ButtonLink,
  CommentTimeWraper,
  StyledViewMore,
} from '../FeedStyled';
import SharePost from '../SharePost/SharePost';
import { formatDate } from './helpers';

interface PostProps {
  post: any;
  onLike: (id: string) => void;
  onComment: (id: string, comment: string) => void;
  onPin: (id: string, currentPinned: boolean) => void;
  emp: EmployeesSkim[];
}

const FeedPost: React.FC<PostProps> = ({
  post,
  onLike,
  onComment,
  onPin,
  emp,
}) => {
  const [comment, setComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const navigate = useNavigate();

  useEffect(() => {
    const like = post.likes.some(
      (like: LikePost) => like.employee.id === authUser?.employee.id
    );
    setIsLiked(like);
  }, [post.likes, authUser]);

  const handleLike = () => {
    onLike(post.id);
    setIsLiked(!isLiked);
  };

  const handleAddComment = () => {
    if (comment.trim()) {
      onComment(post.id, comment);
      setComment('');
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const openShareModal = () => {
    setShowModal(true);
  };

  const closeShareModal = () => {
    setShowModal(false);
  };

  const handleContent = (content: string) => {
    if (!content.includes('@')) {
      return <span>{content}</span>;
    }

    const parts = content.split(' ').map((word) => {
      if (word.startsWith('@')) {
        const username = word.slice(1);
        return (
          <span key={word}>
            <ButtonLink
              type="button"
              onClick={() => navigate(`/profile/${username}`)}
              style={{
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {word}
            </ButtonLink>
            &nbsp;
          </span>
        );
      }
      return <span key={word}>{word}</span>;
    });

    return <>{parts}</>;
  };

  return (
    <PostContainer>
      <PostContent onClick={() => navigate(`feed-details?id=${post.id}`)}>
        <TextWithPinWrapper>
          {`@${post.creator.firstName} ${post.creator.lastName} · `}
          {formatDate(post.createdAt)}
          <PinIconWrapper
            onClick={(e) => {
              e.stopPropagation();
              onPin(post.id, post.pinned);
            }}
            title={post.pinned ? 'Unpin Post' : 'Pin Post'}
            style={{ fontSize: '18px' }}
          >
            <PushpinOutlined
              style={{ color: post.pinned ? 'orange' : 'gray' }}
            />
          </PinIconWrapper>
        </TextWithPinWrapper>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </PostContent>
      <ActionButtons>
        <ActionButton onClick={handleLike} hoverColor="red">
          {isLiked ? (
            <HeartFilled style={{ color: 'red' }} />
          ) : (
            <HeartOutlined style={{ color: 'red' }} />
          )}
          <Count>{post.likes.length}</Count>
        </ActionButton>

        <ActionButton onClick={toggleComments} hoverColor="#3ab23c">
          <CommentOutlined
            style={{
              color: '#3ab23c',
            }}
          />
          <Count>{post.comments.length}</Count>
        </ActionButton>

        {authUser?.roles[0].name !== 'employee' ? (
          <ActionButton onClick={openShareModal} hoverColor="#007bff">
            <SendOutlined
              style={{
                color: '#007bff',
                transform: 'rotate(-20deg)',
              }}
            />
          </ActionButton>
        ) : (
          <Tooltip title={t('noAccess')}>
            <SendOutlined
              style={{
                color: 'grey',
                transform: 'rotate(-20deg)',
              }}
            />
          </Tooltip>
        )}
      </ActionButtons>

      {showComments && (
        <CommentsSection>
          {post.comments.length === 0 ? (
            <NoCommentsMessage>No Comments Yet</NoCommentsMessage>
          ) : (
            <CommentList>
              {post.comments.slice(0, 3).map((comment: any) => (
                <Comment key={comment.id}>
                  <CommentAvatar>
                    {comment.employee.firstName[0].toUpperCase()}
                    {comment.employee.lastName[0].toUpperCase()}
                  </CommentAvatar>
                  <CommentContent>
                    <CommentTimeWraper>
                      <CommentUser>
                        {`${comment.employee.firstName} ${comment.employee.lastName}`}
                      </CommentUser>
                      {formatDate(comment.createdAt)}
                    </CommentTimeWraper>
                    <CommentText>{handleContent(comment.content)}</CommentText>
                  </CommentContent>
                </Comment>
              ))}
              {post.comments.length > 3 && (
                <StyledViewMore
                  onClick={() => navigate(`feed-details?id=${post.id}`)}
                >
                  View more
                </StyledViewMore>
              )}
            </CommentList>
          )}
        </CommentsSection>
      )}

      <CommentInputWrapper>
        <StyledMentions
          placeholder="Add a comment..."
          value={comment}
          onClick={() => {
            if (!showComments) setShowComments(true);
          }}
          onChange={(value) => setComment(value)}
        >
          {emp.map((employee) => (
            <Option
              value={`${employee.firstName}${employee.lastName}`}
              key={employee.id}
            >
              {`${employee.firstName} ${employee.lastName}`}
            </Option>
          ))}
        </StyledMentions>

        <AddCommentButton onClick={handleAddComment}>Post</AddCommentButton>
      </CommentInputWrapper>

      <SharePost
        isOpen={showModal}
        onClose={closeShareModal}
        postId={post.id}
      />
    </PostContainer>
  );
};

export default FeedPost;
