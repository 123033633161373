import { ExpensesReport } from 'types/ExpensesReport';

export enum ExpenseReportActions {
  FETCH_EXPENSEREPORT_START = 'FETCH_EXPENSEREPORT_START',
  FETCH_EXPENSEREPORT_SUCCESS = 'FETCH_EXPENSEREPORT_SUCCESS',
  FETCH_EXPENSEREPORT_ERRORED = 'FETCH_EXPENSEREPORT_ERRORED',

  FETCH_SINGLE_EXPENSEREPORT_START = 'FETCH_SINGLE_EXPENSEREPORT_START',
  FETCH_SINGLE_EXPENSEREPORT_SUCCESS = 'FETCH_SINGLE_EXPENSEREPORT_SUCCESS',
  FETCH_SINGLE_EXPENSEREPORT_ERRORED = 'FETCH_SINGLE_EXPENSEREPORT_ERRORED',

  CLEAR_SINGLE_EXPENSEREPORT = 'CLEAR_SINGLE_EXPENSEREPORT',
  CLEAR_ALL_EXPENSEREPORT = 'CLEAR_ALL_EXPENSEREPORT',
}

export interface ExpenseReportState {
  data: Array<ExpensesReport>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export interface ExpenseReportSingleState {
  data: ExpensesReport | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
