import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useHeader from 'ui-v2/hooks/useHeader';
import { HomeIcon } from 'Icons/HomeIcon';
import { ChevronIcon } from 'Icons/ChevronIcon';
import { getAppRoutes } from 'utils/utilFunctions';
import HrDutiesBanner from 'components/HRDutiesBanner/HRDutiesBanner';
import { RootState } from 'redux/store/reducers';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import backUrls from 'configs/routes.config';
import {
  StyledBreadcrumb,
  StyledBreadcrumbBack,
  StyledBreadcrumbText,
} from './StyledBreadCrumbs';

export default function BreadCrumbs() {
  const { pathname } = useLocation();
  const { hideDetails } = useHeader();
  const navigate = useNavigate();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { items: routes } = getAppRoutes(authUserState);
  const list = pathname?.split('/').filter((item) => item !== '');
  const location = useLocation();

  const { t } = useTranslation();

  const canViewHrDuties = [AUTH_ROLES.AMDIN, AUTH_ROLES.HR].includes(
    authUserState.authUserRole as AUTH_ROLES
  );

  if (hideDetails) return null;

  const defaultPath = routes.at(0)?.path ?? '/';

  const onBreadcrumbItemClick = (activeCrumb: boolean, navigateTo: string) => {
    if (!activeCrumb) {
      navigate(navigateTo);
    }
  };

  const navigateBack = (location: any) => {
    const match = backUrls.find(
      (element) => element.from === location.pathname
    );
    if (match) {
      navigate({
        pathname: match?.to,
        search: match?.query && location.search ? location.search : undefined,
      });
    } else {
      if (location?.pathname === '/employees/employee-profile') {
        navigate(-1);
      }
      navigate(-1);
    }
  };

  return (
    <>
      {canViewHrDuties && <HrDutiesBanner />}
      <StyledBreadcrumb separator={<ChevronIcon />}>
        <StyledBreadcrumb.Item onClick={() => navigate(defaultPath)}>
          <HomeIcon />
        </StyledBreadcrumb.Item>
        {list.map((item, index) => {
          const activeCrumb = index === list.length - 1;
          const path =
            item.charAt(0).toUpperCase() + item.slice(1).replaceAll('-', ' ');

          return (
            <StyledBreadcrumb.Item
              key={item}
              onClick={() => onBreadcrumbItemClick(activeCrumb, `/${item}`)}
            >
              <StyledBreadcrumbText active={`${activeCrumb}`}>
                {t(path)}
              </StyledBreadcrumbText>
            </StyledBreadcrumb.Item>
          );
        })}
        <StyledBreadcrumb.Item onClick={() => navigateBack(location)}>
          <StyledBreadcrumbBack>{t('back')}</StyledBreadcrumbBack>
        </StyledBreadcrumb.Item>
      </StyledBreadcrumb>
    </>
  );
}
