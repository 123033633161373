import React from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { Id } from 'types/Id';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { deleteVendorCategory } from 'api/vendorCategory';
import { toast } from 'react-toastify';
import useQuestionnairesData from 'ui-v2/hooks/useQuestionnairesData';
import useHeader from 'ui-v2/hooks/useHeader';
import { useDispatch } from 'react-redux';
import { fetchVendorCategories } from 'redux/vendorCategories/actions';

const VendorCategoryAction: React.FC<Id> = ({ id }) => {
  const { take } = useHeader();
  const dispatch = useDispatch();
  const { pagination } = useQuestionnairesData();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const onEdit = async () => {
    searchParams.append('edit-vendor-category', id);
    setSearchParams(searchParams);
  };

  const onViewVendorCategory = () => {
    navigate(`vendor-category-details?id=${id}`);
  };

  const onDeleteClick = async () => {
    try {
      const response = await deleteVendorCategory(id);

      if (response.status === 200) {
        toast.success(t('vendorCategoryDeletedSuccessfully'));
        dispatch(fetchVendorCategories({ page: pagination?.page || 1, take }));
        return;
      }

      toast.warning(t('couldNotArchieveThisVendorCategory'));
    } catch (error) {
      console.error(
        'An error occurred while deleting the vendor category:',
        error
      );
      toast.error(t('somethingWentWrong'));
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editVendorCategory')}>
                <StyledIcon onClick={onEdit}>
                  <EdititingIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('details')}>
                <StyledIcon onClick={onViewVendorCategory}>
                  <ViewProfileIcon />
                </StyledIcon>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWantToDeleteThisVendorCategory')}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <StyledIcon>
                    <DeleteIcon />
                  </StyledIcon>
                </Tooltip>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default VendorCategoryAction;
