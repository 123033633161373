import React, { useState, useEffect } from 'react';
import { Col, Spin, Typography, Popover } from 'antd';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';
import { StyledButton } from 'components/NewForms/FormStyled';
import { FilterByIcon } from 'Icons/FilterByIcon';
import { useDispatch } from 'react-redux';
import { fetchAttendance } from 'redux/employeeAttendance/actions';
import { useAttendance } from 'ui-v2/hooks/useAttendance';
import {
  AttendanceMatrixContainer,
  IconWrapper,
  StyledContainer,
  StyledNoDataText,
  StyledScroll,
} from './AttendanceMatrixStyles';
import Filters from './Filters';

const AttendanceMatrix: React.FC = () => {
  const { t } = useTranslation();
  const { loading, list, xLabels, yLabels } = useAttendance();

  const [selectedMonth] = useState<string>(
    new Date().toISOString().slice(5, 7)
  );

  const [selectedYear] = useState<number>(new Date().getFullYear());

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttendance({ month: selectedMonth, year: selectedYear }));
  }, [selectedMonth, selectedYear]);

  const renderContent = () => {
    if (loading) {
      return <Spin />;
    }

    if (!list.length || !Array.isArray(xLabels) || !Array.isArray(yLabels)) {
      return (
        <StyledNoDataText justify="center" align="middle">
          <Col>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              {t('noDataYet.')}
            </Typography.Text>
            <Typography.Link
              style={{ display: 'block', textAlign: 'center' }}
              href="employees/add-employee"
            >
              {t('addSomeEmployeesFirst!')}
            </Typography.Link>
          </Col>
        </StyledNoDataText>
      );
    }

    return (
      <StyledContainer>
        <StyledScroll>
          <HeatMapGrid
            data={list}
            xLabels={xLabels}
            yLabels={yLabels}
            cellRender={(x, y, value) => (
              <div
                title={`Date: ${xLabels[x]}, Employee: ${yLabels[y]} = ${
                  value === 1 ? 'Present' : 'Absent'
                }`}
              >
                {value === 1 ? '✔' : ''}
              </div>
            )}
            yLabelsStyle={() => ({
              fontSize: '0.9rem',
              textTransform: 'uppercase',
              textAlign: 'right',
              color: '#646464',
              marginRight: '10px',
              marginBottom: '1px',
              whiteSpace: 'nowrap',
            })}
            xLabelsStyle={() => ({
              color: '#333',
              fontSize: '0.8rem',
              textAlign: 'center',
              transformOrigin: 'top left',
              marginTop: '1rem',
              display: 'inline-block',
              marginRight: '1px',
              width: '1.8rem',
            })}
            cellStyle={(_x, _y, ratio) => ({
              background: ratio === 1 ? '#4caf50' : '#eae9e9',
              fontSize: '1rem',
              color: '#000',
              margin: '0.5px',
            })}
            cellHeight="1.8rem"
            xLabelsPos="top"
            yLabelsPos="left"
            square
          />
        </StyledScroll>
      </StyledContainer>
    );
  };

  return (
    <AttendanceMatrixContainer>
      <IconWrapper>
        <Popover placement="leftTop" content={<Filters />} trigger="click">
          <StyledButton id="filter-popover" icon={<FilterByIcon />}>
            {t('filterBy')}
          </StyledButton>
        </Popover>
      </IconWrapper>
      <CardItem title={t('Attendance Matrix')} minHeight={450}>
        {renderContent()}
      </CardItem>
    </AttendanceMatrixContainer>
  );
};

export default AttendanceMatrix;
