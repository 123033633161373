import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchOffboarding } from 'redux/offboarding/actions';
import SuccessorAssignent from './Components/SuccessorAssignment';
import Revocation from './Components/Revocation';
import ExitInterview from './Components/ExitInterview';
import KnowledgeTransfer from './Components/KnowledgeTransfer';
import SignNDA from './Components/SignNDA';
import Balance from './Components/Balance';
import Assets from './Components/Assets';

const { TabPane } = Tabs;

export default function Offboarding() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tab, setTab] = useState('1');
  const dispatch = useDispatch();

  if (!searchParams.get('offboardingId')) {
    navigate(-1);
  }

  useEffect(() => {
    if (searchParams.get('offboardingId')) {
      dispatch(fetchOffboarding(searchParams.get('offboardingId') as string));
    }
  }, [searchParams]);

  const onNext = () => {
    if (Number(tab) < 7) {
      setTab((tab) => String(Number(tab) + 1));
    }
    if (Number(tab) === 7) {
      navigate(-1);
    }
  };

  return (
    <Tabs activeKey={tab} onChange={setTab}>
      <TabPane tab={t('successor')} key="1">
        <SuccessorAssignent onNext={onNext} />
      </TabPane>
      <TabPane tab={t('signatureOff')} key="2">
        <SignNDA onNext={onNext} />
      </TabPane>
      <TabPane tab={t('knowledgeTransfer')} key="3">
        <KnowledgeTransfer onNext={onNext} />
      </TabPane>
      <TabPane tab={t('exitInterview')} key="4">
        <ExitInterview onNext={onNext} />
      </TabPane>
      <TabPane tab={t('balanceSettle')} key="5">
        <Balance onNext={onNext} />
      </TabPane>
      <TabPane tab={t('returnAssets')} key="6">
        <Assets onNext={onNext} />
      </TabPane>
      <TabPane tab={t('revocation')} key="7">
        <Revocation onNext={onNext} />
      </TabPane>
    </Tabs>
  );
}
