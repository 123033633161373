import styled from 'styled-components';

export const StyledCalendarDate = styled.span<{
  isholiday?: boolean;
  isselected?: boolean;
  iscurrentdate?: boolean;
}>(({ theme, isholiday, isselected, iscurrentdate }) => ({
  ...theme.typography.title.regular,
  padding: '.5rem',
  borderRadius: '.3rem',
  ...(isholiday && {
    backgroundColor: theme.colors.palette.primary.blue['300'],
  }),
  ...(isselected && {
    backgroundColor: isholiday
      ? theme.colors.palette.primary.blue['300']
      : theme.colors.palette.primary.gray['300'],
  }),
  ...(isselected && isholiday && { color: theme.colors.palette.base.white }),
  ':hover': {
    backgroundColor: isholiday
      ? theme.colors.palette.primary.blue['300']
      : theme.colors.palette.primary.gray['300'],
    ...(isholiday && { color: theme.colors.palette.base.white }),
  },
  ...(iscurrentdate && {
    border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  }),
}));
