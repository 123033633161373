import React from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';

import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';

type PublicSuperAdminActionProps = {
  id: string;
};

const PublicSuperAdminAction: React.FC<PublicSuperAdminActionProps> = ({
  id,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  function onEditClick() {
    searchParams.set('edit-public-super-admin', id);
    setSearchParams(searchParams);
  }

  function onDelete() {
    searchParams.set('delete-public-super-admin', id);
    setSearchParams(searchParams);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="space-around">
            <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
              <Col>
                <Tooltip title={t('editSuperadmin')}>
                  <StyledIcon onClick={onEditClick}>
                    <EdititingIcon />
                  </StyledIcon>
                </Tooltip>
              </Col>
            </RoleBasedButton>
            <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
              <Col>
                <Popconfirm
                  trigger="click"
                  placement="topLeft"
                  title={t(
                    'Are you sure you want to delete this super admin user'
                  )}
                  onConfirm={onDelete}
                >
                  <Tooltip title={t('deleteSuperAdmin')}>
                    <StyledIcon>
                      <DeleteIcon />
                    </StyledIcon>
                  </Tooltip>
                </Popconfirm>
              </Col>
            </RoleBasedButton>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PublicSuperAdminAction;
