import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

const StyledModal = styled(AntdModal)`
  & .ant-modal-content {
    border-radius: 12px;
    padding-top: 12px;
    width: 400px;
  }
  & .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-close {
    top: 24px;
    right: 12px;
    border-radius: 50%;
  }
  & .ant-btn {
    border-radius: 6px;
  }
  &.ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface PopUpPorps {
  title?: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const CalendarPopUp: React.FC<PopUpPorps> = ({
  children,
  title,
  visible,
  onOk,
  onCancel,
}) => (
  <StyledModal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
    {children}
  </StyledModal>
);

export default CalendarPopUp;
