import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import GenericForm from 'components/NewForms/Form';
import React, { useMemo, useState } from 'react';
import { Image } from 'types/Image';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { usePublicSuperAdminData } from 'ui-v2/hooks/usePublicSuperAdminData';
import { convertBase64, toastErrorMessages } from 'utils/utilFunctions';
import { Col, Row, Spin } from 'antd';
import { StyledButton } from 'components/NewForms/FormStyled';
import { updatePublicSuperAdmin } from 'api/publicSuperAdminServise';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getAuthUser } from 'redux/authUser/actions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { prefixSelector } from 'components/NewForms/EmployeeForm/components/utils';

interface SuperAdminEditModalProps {
  onClose: () => void;
}

const SuperAdminEditModal: React.FC<SuperAdminEditModalProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch();
  const { me } = usePublicSuperAdminData();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [fileToUpload, setFileToUpload] = useState<Image>();
  const { t } = useTranslation();

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64, type: file.type });
    return false;
  }

  const EditSuperAdminFormConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: 'input',
          defaultValue: me?.email,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'avatar',
          label: t('avatar'),
          type: 'upload',
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (file) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            defaultFileList: [
              {
                uid: '1',
                name: 'My Avatar',
                url: me?.avatar,
              },
            ] as any,
          },
        },

        {
          col: 24,
          offset: 0,
          name: 'firstName',
          label: 'First Name',
          type: 'input',
          defaultValue: me?.firstName,
          rules: [{ required: true, message: 'First name is required' }],
        },
        {
          col: 24,
          offset: 0,
          name: 'lastName',
          label: 'Last Name',
          type: 'input',
          defaultValue: me?.lastName,
          rules: [{ required: true, message: 'Last name is required' }],
        },
        {
          col: 24,
          offset: 0,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: 'selectPrefix',
          defaultValue: me?.phoneNumber,
          rules: [{ required: true, message: 'Phone number is required' }],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: me?.phoneNumberPrefix,
            placeholder: '',
          },
        },
        {
          col: 24,
          offset: 0,
          name: 'birthday',
          label: 'Birthday',
          type: 'datepicker',
          defaultValue: me?.birthday ? moment(me?.birthday) : null,
          rules: [{ required: true, message: 'Birthday is required' }],
        },
      ],
    ],
    [me]
  );

  const onSubmit = async () => {
    if (!me?.id) {
      return;
    }
    const values = form.getFieldsValue(true);
    const valuesToSend = {
      email: values?.email,
      avatar: fileToUpload?.content,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phoneNumberPrefix: values?.phoneNumberPrefix,
      birthday: values?.birthday ? values.birthday.format('YYYY-MM-DD') : null,
    };
    setLoading(true);
    updatePublicSuperAdmin(me?.id, valuesToSend)
      .then((res) => {
        if (res.status === 200) {
          onClose();
          toast.success('Profile updated successfully!');
          dispatch(getAuthUser());
        }
      })
      .catch(toastErrorMessages)
      .finally(() => setLoading(false));
  };

  return (
    <GenericModal open title={t('editProfile')} closeModal={onClose}>
      <Spin spinning={loading}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <GenericForm
              form={form}
              formConfiguration={EditSuperAdminFormConfig}
              onFinish={onSubmit}
            />
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col span={11}>
                <StyledButton type="ghost" onClick={onClose}>
                  {t('cancel')}
                </StyledButton>
              </Col>
              <Col span={11}>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  {t('submit')}
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </GenericModal>
  );
};

export default SuperAdminEditModal;
