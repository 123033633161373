import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CardItem from 'ui-v2/components/Card';
import { PALETE } from 'components/styledComponents/colors';
import { getDashboardBarExpenseStats } from 'api/dashboardStats';
import { Col, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BarChartExpense = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any>({
    labels,
    datasets: [
      {
        label: t('other'),
        data: [],
        backgroundColor: PALETE['blue-400'],
      },
      {
        label: t('meal'),
        data: [],
        backgroundColor: PALETE['rose-400'],
      },
      {
        label: t('supply'),
        data: [],
        backgroundColor: PALETE['green-400'],
      },
      {
        label: t('travel'),
        data: [],
        backgroundColor: PALETE['yellow-400'],
      },
    ],
  });

  const fetchData = async () => {
    setLoading(true);
    getDashboardBarExpenseStats()
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.other &&
          response.data.meal &&
          response.data.supply &&
          response.data.travel
        ) {
          const other = response.data.other.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`).startOf('month').format('MMMM'),
          }));
          const meal = response.data.meal.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`).startOf('month').format('MMMM'),
          }));

          const supply = response.data.supply.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`).startOf('month').format('MMMM'),
          }));

          const travel = response.data.travel.map((item: any) => ({
            ...item,
            month: moment(`${item.month}`).startOf('month').format('MMMM'),
          }));

          setChartData({
            labels,
            datasets: [
              {
                label: t('other'),
                data: labels.map(
                  (month: string) =>
                    other.find((item: any) => item.month === month)?.totalAmount
                ),
                backgroundColor: PALETE['blue-400'],
              },
              {
                label: t('meal'),
                data: labels.map(
                  (month: string) =>
                    meal.find((item: any) => item.month === month)?.totalAmount
                ),
                backgroundColor: PALETE['green-400'],
              },
              {
                label: t('supply'),
                data: labels.map(
                  (month: string) =>
                    supply.find((item: any) => item.month === month)
                      ?.totalAmount
                ),
                backgroundColor: PALETE['yellow-400'],
              },
              {
                label: t('travel'),
                data: labels.map(
                  (month: string) =>
                    travel.find((item: any) => item.month === month)
                      ?.totalAmount
                ),
                backgroundColor: PALETE['rose-400'],
              },
            ],
          });

          setData(
            response.data.other.length ||
              response.data.meal.length ||
              response.data.supply.length ||
              response.data.travel.length
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.log('error');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CardItem title={t('expenseCategoryByMonth')} minHeight={460}>
      <Spin spinning={loading}>
        {!loading && !data ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 400,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="expenses"
              >
                {t('thereAreNoExpensesToReport')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Bar
            data={chartData}
            options={{
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {},
              },
            }}
          />
        )}
      </Spin>
    </CardItem>
  );
};

export default BarChartExpense;
