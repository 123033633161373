import React, { useState } from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import {
  HeaderContainer,
  MenuButton,
  SiteName,
  UserName,
  UserSection,
} from './SideBarMobileStyles';
import MobileDrawer from './MobileDrawer';

interface MobileProps {
  tenantName: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
}

function MobileSidebar({
  tenantName,
  firstName,
  lastName,
  email,
}: MobileProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : t('superAdmin');

  return (
    <>
      <HeaderContainer>
        <SiteName>{tenantName}</SiteName>
        <UserSection>
          <UserName>{fullName}</UserName>
          <MenuButton onClick={toggleDrawer}>
            {isDrawerOpen ? <CloseOutlined /> : <MenuOutlined />}
          </MenuButton>
        </UserSection>
      </HeaderContainer>
      <MobileDrawer
        isDrawerOpen={isDrawerOpen}
        email={email}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
}
export default React.memo(MobileSidebar);
