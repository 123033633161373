/* eslint-disable indent */
import { Col, Row, Skeleton, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';

import { getGoalsByEmployeeId } from 'api/goalService';
import { Goal, GoalsResponse } from 'types/Goal';
import { StyledButton } from 'components/NewForms/FormStyled';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import AddGoalModal from './AddGoalModal';
import FeedbackModal from './FeedbackModal';

export default function EmployeeGoals({ id }: { id: string }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [feedbackGoalId, setFeedbackGoalId] = useState<Goal | null>(null);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getGoals = async (id: string) => {
    try {
      setLoading(true);
      const response: GoalsResponse = await getGoalsByEmployeeId(id);
      setGoals(response.data);
    } catch {
      console.log('Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getGoals(id);
  }, [id]);

  return (
    <CardItem title={t('Goals')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        {goals?.length > 0 ? (
          <Row gutter={[0, 16]}>
            {goals?.map((w: Goal, index) => (
              <Styled.RowItem
                key={w.id}
                justify="space-between"
                last={index === goals.length - 1}
                style={{ padding: '10px 0' }}
                onClick={() => {
                  setFeedbackGoalId(w);
                  setModal2Visible(true);
                }}
              >
                <Col span={24}>
                  {w.name}
                  <Progress percent={w.completed} />
                </Col>
              </Styled.RowItem>
            ))}
          </Row>
        ) : (
          <Row gutter={[0, 24]}>
            <Styled.RowItem justify="center" last>
              <Col>
                <Styled.NodataLabel>{t('No goals yet!')}</Styled.NodataLabel>
              </Col>
            </Styled.RowItem>
          </Row>
        )}
        {modalVisible && (
          <AddGoalModal
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              getGoals(id);
            }}
            employeeId={id}
          />
        )}

        <StyledButton onClick={() => setModalVisible(true)}>
          New Goal
        </StyledButton>
        {modal2Visible && (
          <FeedbackModal
            open={modal2Visible}
            closeModal={() => {
              setFeedbackGoalId(null);
              setModal2Visible(false);
            }}
            feedbackGoal={feedbackGoalId as Goal}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
