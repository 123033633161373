import React from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import EyeIcon from 'Icons/EyeIcon';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const CertificationAction: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const onViewClick = () => {
    navigate(`certification-details?id=${id}`);
  };

  const onEditClick = () => {
    searchParams.set('edit-certification', id);
    setSearchParams(searchParams);
  };

  const onDelete = () => {
    searchParams.set('delete-certification', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('details')}>
              <IconButton onClick={onViewClick}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('edit')}>
                <IconButton onClick={onEditClick}>
                  <EdititingIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWanttoDelete')}
                onConfirm={onDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default CertificationAction;
