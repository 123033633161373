import FeatureFlagActions from 'components/Actions/FeatureFlagActions';
import { FeatureFlagsTenantType } from 'types/FeatureFlagsTenant';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import React from 'react';
import { Row } from 'antd';
import i18next from '../i18n';

export const FeatureColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    default: true,
    order: 1,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <p>{`${row?.featureFlag.featureName}`}</p>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    default: true,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <Row justify="start">
        <Styled.FeatureEnabledTag status={row?.status ? row.status : false}>
          <p>{row?.status === true ? 'Enabled' : 'Disabled'}</p>
        </Styled.FeatureEnabledTag>
      </Row>
    ),
    order: 4,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: FeatureFlagsTenantType) => (
      <FeatureFlagActions
        featureId={row?.featureFlag.id}
        tenantId={row?.tenant?.id}
        status={row?.status ?? false}
      />
    ),
    default: false,
    order: 6,
  },
];
