import { Col, Popconfirm, Popover, Row, Spin, Typography } from 'antd';
import { updateVoteTimeOff } from 'api/timeOffService';
import { StyledButton } from 'components/NewForms/FormStyled';
import { TimeIcon } from 'Icons/TimeIcon';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  DateType,
  IApprover,
  IApproverUpdateDTO,
  TimeOffRequest,
  TimeOffStatus,
} from 'types/TimeOff';
import CardItem from 'ui-v2/components/Card';
import {
  getTimeOffStatusColor,
  getTotalTimeOffTimeHours,
} from 'ui-v2/routes/ProtectedRoutes/Employee/TimeOff/utils';

import { useTranslation } from 'react-i18next';
import { TimeOffDetails } from 'ui-v2/components/TimeOffCalendar/TimeOffCalendarStyles';
import { EmployeeType } from 'types/Employee';
import * as Styled from './TimeOffRequestDetailsStyles';
import { ProjectName, SubstituteName } from '../TimeOffRequestsStyles';

interface IProps {
  requests: TimeOffRequest[];
  selectedRequestId: string;
  fetchDataApprover: () => void;
}
export default function TimeOffRequestDetails({
  requests,
  selectedRequestId,
  fetchDataApprover,
}: IProps) {
  const authUserState = useSelector((state: any) => state.authUser);
  const [loading, setLoading] = useState(false);
  const selectedRequest: TimeOffRequest | undefined = requests.find(
    (item: TimeOffRequest) => item.id === selectedRequestId
  );
  const approverEmployeeId = authUserState?.authUser?.employee?.id;
  const { t } = useTranslation();

  if (!selectedRequest) return null;

  const approverId = requests
    ?.find((req) => req?.id === selectedRequestId)
    ?.approvers?.find(
      (approver) => approver?.employee?.id === approverEmployeeId
    )?.approverid;

  const substitutes = requests?.find(
    (req) => req?.id === selectedRequestId
  )?.substitutes;

  const preferedSubstitute = requests?.find(
    (req) => req?.id === selectedRequestId
  )?.preferedSubstitute;

  const updateVote = (vote: TimeOffStatus, reject = false) => {
    if (!approverEmployeeId || !approverId) return;
    setLoading(true);
    updateVoteTimeOff({
      employeeId: approverEmployeeId,
      timeOffId: selectedRequest.id,
      action: vote,
      approverId,
    } as IApproverUpdateDTO)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (reject) toast.success(t('succesfullyRejected'));
          else toast.success(t('succesfullyApproved!'));
          fetchDataApprover();
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('failedToApprove!'));
      });
  };

  const onCancelRequest = () => {
    updateVote(TimeOffStatus.REJECTED, true);
  };

  const onApproveRequest = () => {
    updateVote(TimeOffStatus.APPROVED);
  };

  const fullDayTimeOffs = useMemo(
    () =>
      JSON?.parse(selectedRequest?.dates)?.filter(
        (item: DateType) => !item?.timeOffStart
      ),
    [selectedRequest]
  );

  const approverMe = useMemo(
    () =>
      selectedRequest?.approvers?.find(
        (item: IApprover) => item.employee?.id === approverEmployeeId
      ),
    [selectedRequest]
  );

  const disabled = useMemo(
    () =>
      approverMe?.action === TimeOffStatus.APPROVED ||
      approverMe?.action === TimeOffStatus.REJECTED,
    [approverMe]
  );

  function Item({ label, value }: { label: string; value: any }) {
    return (
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Styled.ItemLabel>{label}</Styled.ItemLabel>
        </Col>
        <Col span={24}>
          <Styled.ItemValue>{value}</Styled.ItemValue>
        </Col>
      </Row>
    );
  }

  return (
    <TimeOffDetails>
      <CardItem
        title={`${selectedRequest.employee?.firstName} ${selectedRequest.employee?.lastName}`}
      >
        <>
          <Styled.OverFlowComponent disabled={disabled}>
            <Spin spinning={loading}>
              <Row gutter={[0, 32]}>
                <Styled.RowItem>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item label="" value={t(selectedRequest?.reason)} />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Row align="middle">
                      <TimeIcon />
                      <Item
                        label=""
                        value={`Total: ${getTotalTimeOffTimeHours(
                          selectedRequest
                        )}h`}
                      />
                    </Row>
                  </Col>
                </Styled.RowItem>

                {fullDayTimeOffs?.length > 0 && (
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('days')}
                        value={`${fullDayTimeOffs.length}d`}
                      />
                    </Col>
                    <Col span={20}>
                      {fullDayTimeOffs.map((item: DateType) => (
                        <Col span={25}>
                          <Row justify="start" gutter={[10, 0]}>
                            <Col>
                              <Styled.PrimaryText>
                                {moment(item.date).format('DD/MM/YYYY')}
                              </Styled.PrimaryText>
                            </Col>
                            <Col>
                              <Styled.SecondaryText>
                                {moment(item.date).format('dddd')}
                              </Styled.SecondaryText>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Col>
                  </Styled.RowItem>
                )}

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 20 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Approvers')}
                      value={selectedRequest.approvers?.map(
                        (item: IApprover) => (
                          <Popover
                            content={
                              <Styled.SecondaryText>
                                {moment(item.actionDate).format('DD/MM/YYYY')}
                              </Styled.SecondaryText>
                            }
                            title={
                              <Styled.Status
                                color={getTimeOffStatusColor(item.action)}
                              >
                                {t(item.action)}
                              </Styled.Status>
                            }
                          >
                            <Row
                              justify="start"
                              gutter={32}
                              align="bottom"
                              style={{ marginBottom: 8 }}
                            >
                              <Col span={14}>
                                <Typography.Text>{`${item.employee?.firstName} ${item.employee?.lastName}`}</Typography.Text>
                              </Col>
                              <Col>
                                <Styled.Status
                                  color={getTimeOffStatusColor(item.action)}
                                >
                                  {t(item.action)}
                                </Styled.Status>
                              </Col>
                            </Row>
                          </Popover>
                        )
                      )}
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 24 }} sm={{ span: 24 }}>
                    <Item
                      label={t('substituteBy')}
                      value={preferedSubstitute?.map((sub: EmployeeType) => (
                        <SubstituteName>
                          {`${sub?.firstName || ''} ${sub?.lastName || ''}`}
                        </SubstituteName>
                      ))}
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 24 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Substitutes')}
                      value={substitutes?.map((sub) =>
                        sub?.substitutes
                          ?.filter(
                            (innerSub) =>
                              innerSub?.id !== selectedRequest.employee?.id
                          )
                          .map((innerSub) => (
                            <SubstituteName key={innerSub.id}>
                              {`${innerSub?.firstName} ${innerSub?.lastName}`}
                            </SubstituteName>
                          ))
                      )}
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 24 }} sm={{ span: 24 }}>
                    <Item
                      label={t('project')}
                      value={
                        selectedRequest.employee?.projects?.length === 0 ? (
                          <Typography.Text>
                            {t('therearenoprojectsassigned')}
                          </Typography.Text>
                        ) : (
                          selectedRequest.employee?.projects?.map((project) => (
                            <ProjectName>{project.name}</ProjectName>
                          ))
                        )
                      }
                    />
                  </Col>
                </Styled.RowItem>

                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('workPosition')}
                      value={selectedRequest.employee.workPosition?.name}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('department')}
                      value={
                        selectedRequest.employee.workPosition?.department?.name
                      }
                    />
                  </Col>
                </Styled.RowItem>
              </Row>
            </Spin>
          </Styled.OverFlowComponent>

          <Row>
            {!disabled && (
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Popconfirm
                      title={t('rejectThisRequest')}
                      onConfirm={onCancelRequest}
                      okText={t('yes')}
                      cancelText={t('no')}
                    >
                      <StyledButton type="link">{t('reject')}</StyledButton>
                    </Popconfirm>
                  </Col>
                  <Col span={12}>
                    <Popconfirm
                      title={t('approveThisRequest')}
                      onConfirm={onApproveRequest}
                      okText={t('yes')}
                      cancelText={t('no')}
                    >
                      <StyledButton type="primary">{t('Approve')}</StyledButton>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </>
      </CardItem>
    </TimeOffDetails>
  );
}
