import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Form } from 'antd';

import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { sharePost } from 'api/feedService';
import { SendOutlined } from '@ant-design/icons';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { getEmployee } from 'api/employeeService';
import GenericForm from 'components/NewForms/Form';
import { InputTypes } from 'types/FormTypes';
import GenericModal from '../../GenericModal';
import {
  CommentAvatar,
  EmployeeItem,
  EmployeeListWrapper,
  EmployeeName,
  LoadingMessage,
  ShareIcon,
} from '../FeedStyled';

interface SharePostProps {
  isOpen: boolean;
  onClose: () => void;
  postId: string;
}

const SharePost: React.FC<SharePostProps> = ({ isOpen, onClose, postId }) => {
  const [employees, setEmployees] = useState<any[]>([]);
  const { data: filteredEmployeeSkimOptions } = useEmployeeSkimData();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchEmployees, setSearchEmployees] = useState<any[]>([]);
  const onFieldsChange = () => {
    const searchTerm = form.getFieldValue('employeeName')?.toLowerCase() || '';
    const filtered = employees.filter((employee) =>
      `${employee.firstName} ${employee.lastName}`
        .toLowerCase()
        .includes(searchTerm)
    );
    setSearchEmployees(filtered);
  };

  useEffect(() => {
    if (filteredEmployeeSkimOptions) {
      setEmployees(filteredEmployeeSkimOptions);
      setSearchEmployees(filteredEmployeeSkimOptions);
    }
  }, [filteredEmployeeSkimOptions]);

  const handleShare = async (employeeId: string) => {
    try {
      setLoading(true);
      await sharePost(postId, employeeId);
      getEmployee(employeeId).then((res) => {
        toast.success(
          `Post successfully shared with employee ${res.data.firstName} ${res.data.lastName}`
        );
      });

      form.setFieldsValue({ employeeName: '' });
      onClose();
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    form.setFieldsValue({ employeeName: '' });
    onClose();
  };

  const SharePostFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'employeeName',
          type: InputTypes.INPUT,
          inputProps: {
            type: 'text',
            placeHolder: 'Search employee',
          },
        },
      ],
    ],
    []
  );
  return (
    <GenericModal
      title={t('Share Post')}
      open={isOpen}
      closeModal={handleClose}
    >
      <GenericForm
        formConfiguration={SharePostFormConfiguration}
        form={form}
        onFieldsChange={onFieldsChange}
      />

      <EmployeeListWrapper>
        {loading ? (
          <LoadingMessage>{t('Loading employees...')}</LoadingMessage>
        ) : (
          (form.getFieldValue('employeeName')
            ? searchEmployees
            : employees
          ).map((employee) => (
            <EmployeeItem key={employee.id}>
              <CommentAvatar>
                {employee.firstName[0].toUpperCase()}
                {employee.lastName[0].toUpperCase()}
              </CommentAvatar>
              <EmployeeName>
                {`${employee.firstName} ${employee.lastName}`}
              </EmployeeName>
              <ShareIcon onClick={() => handleShare(employee.id)}>
                <SendOutlined />
              </ShareIcon>
            </EmployeeItem>
          ))
        )}
      </EmployeeListWrapper>
    </GenericModal>
  );
};

export default SharePost;
