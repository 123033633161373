import { Avatar, Rate, Row, Typography } from 'antd';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useEffect, useState } from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import { Goal, GoalFeedback } from 'types/Goal';
import { getAllFeedbackForGoal } from 'api/goalService';
import { NodataLabel } from 'ui-v2/components/WorkExperience/WorkExperienceStyles';

interface IProps {
  open: boolean;
  closeModal: () => void;
  feedbackGoal: Goal;
}

export default function FeedbackModal({
  open,
  closeModal,
  feedbackGoal,
}: IProps) {
  const [feedback, setFeedback] = useState<GoalFeedback[]>([]);

  const fetchFeedback = async (goalId: string) => {
    try {
      const response = await getAllFeedbackForGoal(goalId);
      if (response.status === 200 && response.data) {
        setFeedback(response.data);
      }
    } catch {
      console.log('Error');
    }
  };
  useEffect(() => {
    if (feedbackGoal?.id) {
      fetchFeedback(feedbackGoal.id);
    }
  }, [feedbackGoal]);

  return (
    <GenericModal
      title={`${feedbackGoal?.name} Feedback`}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      {feedback.length > 0 ? (
        <Row>
          {feedback.map((item) => (
            <div
              key={item.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '16px',
                marginBottom: '32px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: '16px',
                  width: '65%',
                }}
              >
                <Avatar style={{ verticalAlign: 'middle' }} size="large">
                  {item.feedbackProvider?.firstName?.charAt(0)}
                </Avatar>
                <div>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {`${item.feedbackProvider?.firstName} ${item.feedbackProvider?.lastName}`}
                  </Typography>
                  <Typography>{item.content}</Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '35%',
                }}
              >
                <Rate value={item.rating} />
              </div>
            </div>
          ))}
        </Row>
      ) : (
        <NodataLabel>There are no feedbacks yet!</NodataLabel>
      )}
    </GenericModal>
  );
}
