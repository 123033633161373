import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import RightSection from 'ui-v2/components/RightSection';
import { useSearchParams } from 'react-router-dom';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { TeanantLocation } from 'types/TenantLocation';
import {
  deleteTenantLocation,
  getAllTenantLocations,
} from 'api/tenantLocationService';
import { TenantLocationItem } from './TenantLocationItem';
import TenantLocationForm from './TenantLocationForm';
import { HeaderLocation, RowItemLocation } from './TenantLocationStyles';

export default function TenantLocationList() {
  const [xTenantId, setXTenantId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocation, setSelectedLocation] =
    useState<TeanantLocation | null>();
  const onToggleModal = () => {
    setModalVisible((state) => !state);
    setSelectedLocation(null);
  };
  const { t } = useTranslation();

  const onOpenModal = () => {
    onToggleModal();
  };
  const [locations, setLocations] = useState<any>([]);
  const fetchLocations = () => {
    getAllTenantLocations()
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data || []);
        }
      })
      .catch(() => {
        toast.error(
          t("Something went wrong! Can't get locations for this tenant")
        );
      });
  };

  useEffect(() => {
    const storedXTenantId = localStorage.getItem('x-tenant-id');
    setXTenantId(storedXTenantId as any);
    fetchLocations();
  }, []);

  const onEdit = (locations: any) => {
    setSelectedLocation(locations as any);
    setModalVisible(true);
  };
  const onDelete = async (locationId: string) => {
    try {
      const response = await deleteTenantLocation(locationId);
      if (response.status === 200) {
        toast.success(t('successfullyDeletedLocation!'));
        fetchLocations();
        searchParams.append('deleted-location', 'true');
        setSearchParams(searchParams);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  useEffect(() => {
    if (
      searchParams.get('deleted-location') === 'true' ||
      searchParams.get('added-location') === 'true' ||
      searchParams.get('edited-location') === 'true'
    ) {
      fetchLocations();
      searchParams.delete('deleted-location');
      searchParams.delete('added-location');
      searchParams.delete('edited-location');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <>
        <CardItem
          title={t('locations')}
          rightButton={
            <RightSection
              buttonCallBack={() => onOpenModal()}
              buttonText={t('addLocation')}
            />
          }
        >
          <Row gutter={[0, 24]} justify="center">
            <Styled.RowItem gutter={[0, 10]} last>
              <Col
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <>
                  {!locations?.length && (
                    <Row>{t('thereAreNoLocationsForThisTenant')}</Row>
                  )}
                </>
              </Col>
            </Styled.RowItem>

            {locations?.length && (
              <>
                <RowItemLocation>
                  <Col span={4}>
                    <HeaderLocation>{t('country')}</HeaderLocation>
                  </Col>
                  <Col span={4}>
                    <HeaderLocation>{t('city')}</HeaderLocation>
                  </Col>
                  <Col span={5}>
                    <HeaderLocation>{t('streetName')}</HeaderLocation>
                  </Col>
                  <Col span={4}>
                    <HeaderLocation>{t('postalCode')}</HeaderLocation>
                  </Col>
                  <Col span={4}>
                    <HeaderLocation>{t('defaultLocation')}</HeaderLocation>
                  </Col>
                </RowItemLocation>

                {locations?.map((locations: TeanantLocation) => (
                  <TenantLocationItem
                    key={locations?.id}
                    data={locations}
                    onEdit={() => onEdit(locations)}
                    onDelete={() => onDelete(locations?.id)}
                  />
                ))}
              </>
            )}
            {modalVisible && (
              <TenantLocationForm
                open={modalVisible}
                closeModal={onToggleModal}
                selectedLocation={selectedLocation}
                fetchLocations={fetchLocations}
                xTenantId={xTenantId}
              />
            )}
          </Row>
        </CardItem>
      </>
    </>
  );
}
