import { Dispatch } from 'redux';
import { ExpenseQueryParamsType } from 'types/QueryParams';
import { getExpenseReport, getSingleExpenseReport } from 'api/expensesService';
import { ExpenseReportActions } from './types';

export const fetchExpenses =
  (queryParams: ExpenseQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: ExpenseReportActions.FETCH_EXPENSEREPORT_START,
    });
    getExpenseReport(queryParams)
      .then((res) => {
        dispatch({
          type: ExpenseReportActions.FETCH_EXPENSEREPORT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ExpenseReportActions.FETCH_EXPENSEREPORT_ERRORED,
          payload: e,
        });
      });
  };

export const fetchSingleExpenseReport =
  (id: string) => (dispatch: Dispatch) => {
    dispatch({
      type: ExpenseReportActions.FETCH_SINGLE_EXPENSEREPORT_START,
    });
    getSingleExpenseReport(id)
      .then((res) => {
        dispatch({
          type: ExpenseReportActions.FETCH_SINGLE_EXPENSEREPORT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ExpenseReportActions.FETCH_SINGLE_EXPENSEREPORT_ERRORED,
          payload: e,
        });
      });
  };

export const clearFetchExpenseReport = () => (dispatch: Dispatch) => {
  dispatch({
    type: ExpenseReportActions.CLEAR_ALL_EXPENSEREPORT,
  });
};

export const clearFetchSingleExpenseReport = () => (dispatch: Dispatch) => {
  dispatch({
    type: ExpenseReportActions.CLEAR_SINGLE_EXPENSEREPORT,
  });
};
