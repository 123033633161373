import { AttendanceQueryParamsType } from 'types/QueryParams';
import agent from './agent';
import { apiURLs } from './constants';

export const getCurrentAttendance = async (employeeId: string) =>
  agent.get(`${apiURLs.employeeAttendance}/current/${employeeId}`);

export const getAttendanceMatrix = async (
  queryParams: AttendanceQueryParamsType
) => {
  try {
    const response = await agent.get(
      `${apiURLs.employeeAttendance}/attendance-matrix`,
      {
        params: queryParams,
      }
    );
    return response;
  } catch (error) {
    throw new Error(`Error fetching attendance matrix: ${error}`);
  }
};

export const checkIn = async (employeeId: string) =>
  agent.post(`${apiURLs.employeeAttendance}/check-in/${employeeId}`);

export const checkOut = async (employeeId: string) =>
  agent.post(`${apiURLs.employeeAttendance}/check-out/${employeeId}`);
