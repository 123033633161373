import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProps {
  isAllowed: boolean;
  isFeatureAllowed?: boolean;
  children?: React.ReactElement;
}

export default function ProtectedRoute({
  isAllowed,
  children,
  isFeatureAllowed,
}: IProps) {
  if (isAllowed && (isFeatureAllowed || isFeatureAllowed == null)) {
    return children || <Outlet />;
  }

  return <Navigate to="/login" replace />;
}
