import React, { useEffect } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { HeadcountColumns } from 'table-configuration/HeadcountTableConfig';
import { useHeadcountData } from 'ui-v2/hooks/useHeadcountData';
import { fetchHeadcount } from 'redux/headcount/actions';
import CardItem from 'ui-v2/components/Card';

const HeadcountReport = () => {
  const dispatch = useDispatch();
  const { list, loading, pagination } = useHeadcountData();
  const take = 5;
  const todaysDate = new Date()
    .toISOString()
    .replace('-', '/')
    .split('T')[0]
    .replace('-', '/');

  useEffect(() => {
    dispatch(fetchHeadcount({ page: 1, take }));
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
    };
    dispatch(fetchHeadcount(params));
  };

  return (
    <CardItem title={`Headcount ${todaysDate}`} minHeight={460}>
      <Content position="stretch">
        <div style={{ padding: '20px' }}>
          <DataGrid
            loading={loading}
            columns={HeadcountColumns}
            data={list}
            onPageChange={onPageChange}
            paginationName="headcount"
            totalItems={pagination.itemCount}
            currentPage={pagination.page}
            totalPages={pagination.pageCount}
            manualTake={take}
            multipleSelect={false}
          />
        </div>
      </Content>
    </CardItem>
  );
};

export default HeadcountReport;
