import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExpenseReportCategory } from 'types/ExpensesReport';

const categoryType = [
  { id: 1, value: ExpenseReportCategory.MEAL, label: 'Meal' },
  { id: 2, value: ExpenseReportCategory.SUPPLY, label: 'Supply' },
  { id: 3, value: ExpenseReportCategory.TRAVEL, label: 'Travel' },
  { id: 4, value: ExpenseReportCategory.OTHER, label: 'Other' },
];
const initialExpenseLines = [
  {
    date: null,
    category: null,
    amount: null,
    description: null,
  },
];

export default function ExpenseLineForm() {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  return (
    <Form.List name="expenseLines" initialValue={initialExpenseLines}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Collapse key={key} defaultActiveKey={[key]}>
              <Panel
                header={`${t('expenseLine')} ${index + 1}`}
                key={key}
                extra={
                  index > 0 && (
                    <MinusCircleOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        remove(name);
                      }}
                    />
                  )
                }
              >
                <Row gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label={t('date')}
                      name={[name, 'date']}
                      rules={[{ required: true, message: t('fieldRequired') }]}
                    >
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label={t('category')}
                      name={[name, 'category']}
                      rules={[{ required: true, message: t('fieldRequired') }]}
                    >
                      <Select placeholder={t('category')}>
                        {categoryType.map((category) => (
                          <Select.Option
                            key={category.id}
                            value={category.value}
                          >
                            {category.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label={t('amount')}
                      name={[name, 'amount']}
                      rules={[{ required: true, message: t('fieldRequired') }]}
                    >
                      <Input type="number" placeholder={t('amount')} min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      label={t('currency')}
                      name={[name, 'currencyId']}
                      rules={[{ required: true, message: t('fieldRequired') }]}
                    >
                      <Select
                        placeholder={t('currency')}
                        loading={currencyLoading}
                      >
                        {currencyOptions.map((option) => (
                          <Select.Option key={option.id} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      label={t('description')}
                      name={[name, 'description']}
                    >
                      <Input.TextArea placeholder={t('description')} rows={2} />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          ))}

          <Row justify="end">
            <Col>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ marginTop: '10px' }}
                >
                  {t('addExpenseLine')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  );
}
