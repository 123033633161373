/* eslint-disable react/no-danger */
import { commentPost, getPostById, likePost } from 'api/feedService';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Option } from 'antd/lib/mentions';
import {
  CommentOutlined,
  SendOutlined,
  HeartFilled,
  HeartOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { fetchFeed } from 'redux/feed/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toastErrorMessages } from 'utils/utilFunctions';
import { AuthUserState } from 'redux/authUser/types';
import { LikePost } from 'types/Post';
import { RootState } from 'redux/store';
import SharePost from '../SharePost/SharePost';
import {
  ActionButton,
  ActionButtons,
  AddCommentButton,
  ButtonLink,
  Comment,
  CommentAvatar,
  CommentContent,
  CommentInputWrapper,
  CommentList,
  CommentsSection,
  CommentText,
  CommentTimeWraper,
  CommentUser,
  Count,
  NoCommentsMessage,
  PostContainer,
  PostContent,
  StyledMentions,
  TextWithPinWrapper,
} from '../FeedStyled';
import { formatDate } from './helpers';

interface Comment {
  id: string;
  content: string;
  updatedAt: string;
  createdAt: string;
  employee: {
    firstName: string;
    lastName: string;
  };
}
interface Like {
  employee: {
    id: string;
  };
}
interface Post {
  id: string;
  employee: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  pinned: boolean;
  content: string;
  postLikes: Array<Like>;
  postComments: Array<Comment>;
}

const SinglePost = () => {
  const [searchParams] = useSearchParams();
  const [post, setPost] = useState<Post | null>(null);
  const [isLiked, setIsLiked] = useState(false);
  const [comment, setComment] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const id = searchParams.get('id');

  useEffect(() => {
    if (id) {
      getPostById(id).then((res) => setPost(res.data));
    }
  }, [isLiked]);

  useEffect(() => {
    const like = post?.postLikes?.some(
      (like: LikePost) => like.employee.id === authUser?.employee.id
    );
    setIsLiked(like || false);
  }, [post?.postLikes, authUser]);

  const handleLike = async (postId: string) => {
    try {
      await likePost(postId);

      setIsLiked(!isLiked);
      dispatch(fetchFeed());
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const { data: emp } = useEmployeeSkimData();

  const openShareModal = () => {
    setShowModal(true);
  };

  const closeShareModal = () => {
    setShowModal(false);
  };

  const handleComment = async () => {
    if (!post?.id || !comment.trim()) return;
    const valuesToSend = {
      postId: post.id,
      content: comment,
    };
    try {
      await commentPost(valuesToSend);

      const updatedPost = await getPostById(post.id);
      setPost(updatedPost.data);

      dispatch(fetchFeed());
      setComment('');
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const handleContent = (content: string) => {
    if (!content.includes('@')) {
      return <span>{content}</span>;
    }

    const parts = content.split(' ').map((word) => {
      if (word.startsWith('@')) {
        // const username = word.slice(1);
        return (
          <span key={word}>
            &nbsp;
            <ButtonLink
              type="button"
              //   onClick={() => navigate(`/profile/${username}`)}
              style={{
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {word}
            </ButtonLink>
            &nbsp;
          </span>
        );
      }
      return <span key={word}>{word}</span>;
    });

    return <>{parts}</>;
  };

  return (
    <PostContainer>
      <PostContent>
        <TextWithPinWrapper>
          <ArrowLeftOutlined
            style={{ fontSize: '19px' }}
            onClick={() => navigate(-1)}
          />

          {post && (
            <>
              {`@${post.employee.firstName} ${post.employee.lastName} · `}
              {formatDate(post.createdAt)}
            </>
          )}
        </TextWithPinWrapper>
        <div dangerouslySetInnerHTML={{ __html: post?.content || '' }} />
      </PostContent>
      <ActionButtons>
        <ActionButton
          onClick={() => handleLike(post?.id as string)}
          hoverColor="red"
        >
          {isLiked ? (
            <HeartFilled style={{ color: 'red' }} />
          ) : (
            <HeartOutlined style={{ color: 'red' }} />
          )}

          <Count>{post?.postLikes.length}</Count>
        </ActionButton>

        <ActionButton hoverColor="#3ab23c">
          <CommentOutlined
            style={{
              color: '#3ab23c',
            }}
          />
          <Count>{post?.postComments.length}</Count>
        </ActionButton>

        {authUser?.roles[0].name !== 'employee' ? (
          <ActionButton onClick={openShareModal} hoverColor="#007bff">
            <SendOutlined
              style={{
                color: '#007bff',
                transform: 'rotate(-20deg)',
              }}
            />
          </ActionButton>
        ) : (
          <SendOutlined
            style={{
              color: 'grey',
              transform: 'rotate(-20deg)',
            }}
          />
        )}
      </ActionButtons>
      <CommentInputWrapper>
        <StyledMentions
          placeholder="Add a comment..."
          value={comment}
          onChange={(value) => setComment(value)}
        >
          {emp.map((employee) => (
            <Option
              value={`${employee.firstName}${employee.lastName}`}
              key={employee.id}
            >
              {`${employee.firstName} ${employee.lastName}`}
            </Option>
          ))}
        </StyledMentions>

        <AddCommentButton onClick={handleComment}>Post</AddCommentButton>
      </CommentInputWrapper>
      <CommentsSection>
        {post?.postComments.length === 0 ? (
          <NoCommentsMessage>No Comments Yet</NoCommentsMessage>
        ) : (
          <CommentList>
            {post?.postComments.map((comment: Comment) => (
              <Comment key={comment.id}>
                <CommentAvatar>
                  {comment.employee.firstName[0].toUpperCase()}
                  {comment.employee.lastName[0].toUpperCase()}
                </CommentAvatar>
                <CommentContent>
                  <CommentTimeWraper>
                    <CommentUser>
                      {`${comment.employee.firstName} ${comment.employee.lastName}`}
                    </CommentUser>
                    {formatDate(comment.createdAt)}
                  </CommentTimeWraper>
                  <CommentText>{handleContent(comment.content)}</CommentText>
                </CommentContent>
              </Comment>
            ))}
          </CommentList>
        )}
      </CommentsSection>

      <SharePost
        isOpen={showModal}
        onClose={closeShareModal}
        postId={post?.id as string}
      />
    </PostContainer>
  );
};

export default SinglePost;
