import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import RightSection from 'ui-v2/components/RightSection';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useAssetCategoriesData } from 'ui-v2/hooks/useAssetCategoriesData';
import { useSearchParams } from 'react-router-dom';
import { deleteAssetSubCategory } from 'api/assetCategories';
import { toast } from 'react-toastify';
import { AssetSubCategory } from 'types/AssetCategories';
import { useDispatch } from 'react-redux';
import { fetchAssetSubCategories } from 'redux/assetCategories/actions';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import AssetSubCategoryForm from './AssetSubCategoryForm';
import { AssetSubCategoryItem } from './AssetSubCategoryItem';

export default function AssetCategoriesDetails() {
  const { subCategories: subCategoriesState } = useAssetCategoriesData();
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<AssetSubCategory>();
  const onToggleModal = () => setModalVisible((state) => !state);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onOpenModal = () => {
    onToggleModal();
    setSelectedSubCategory(undefined);
  };

  const id = searchParams.get('id') as string;

  useEffect(() => {
    dispatch(fetchAssetSubCategories(id));
  }, [id]);

  const onEdit = (subCategory: AssetSubCategory) => {
    setSelectedSubCategory(subCategory);
    setModalVisible(true);
  };

  const onDelete = async (subCategoryId: string) => {
    try {
      const response = await deleteAssetSubCategory(subCategoryId);
      if (response.status === 200) {
        toast.success('deleted sub category');
        searchParams.append('deleted-asset-sub-category', 'true');
        setSearchParams(searchParams);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  useEffect(() => {
    if (
      searchParams.get('deleted-asset-sub-category') === 'true' ||
      searchParams.get('added-asset-sub-category') === 'true' ||
      searchParams.get('edited-asset-sub-category') === 'true'
    ) {
      dispatch(fetchAssetSubCategories(id));
      searchParams.delete('deleted-asset-sub-category');
      searchParams.delete('added-asset-sub-category');
      searchParams.delete('edited-asset-sub-category');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <Content position="center">
      <>
        <>
          <CardItem
            title={searchParams.get('name')}
            rightButton={
              <RightSection
                buttonCallBack={() => onOpenModal()}
                buttonText={t('addAssetSubCategory')}
              />
            }
          >
            <Row gutter={[0, 24]} justify="center">
              <Styled.RowItem gutter={[0, 10]} last>
                <Col
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xxl={{ span: 24 }}
                  xl={{ span: 24 }}
                />
              </Styled.RowItem>
              <Col>
                {subCategoriesState?.loading && !subCategoriesState?.loaded && (
                  <Spin />
                )}
              </Col>
              {subCategoriesState?.data?.map(
                (subCategory: AssetSubCategory) => (
                  <AssetSubCategoryItem
                    name={subCategory?.subCategoryName}
                    onEdit={() => onEdit(subCategory)}
                    onDelete={() => onDelete(subCategory?.id)}
                  />
                )
              )}
              {modalVisible && (
                <AssetSubCategoryForm
                  open={modalVisible}
                  closeModal={onToggleModal}
                  selectedSubCategory={selectedSubCategory}
                  assetCategoryId={id}
                />
              )}
            </Row>
          </CardItem>
        </>
      </>
    </Content>
  );
}
