import { Reducer } from 'react';
import { Action } from 'redux';
import { SkillsetMatchingActions, SkillsetMatchingState } from './types';

const initialState: SkillsetMatchingState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const skillsetMatchingReducer: Reducer<SkillsetMatchingState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_START:
      state = { ...state, loading: true };
      break;
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case SkillsetMatchingActions.CLEAR_SKILLSETMATCHING:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { skillsetMatchingReducer as SkillsetMatchingReducer };
