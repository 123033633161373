import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { BackwardOutlined, CheckCircleOutlined } from '@ant-design/icons';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { convertDateToUTC } from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form';
import { prefixSelector } from 'utils/constants';
import { AUTH_ROLES } from 'types/Auth';
import {
  TenantOnboardinStepsLayout,
  TenantOnboardinStepsLayoutProps,
} from './TenantOnboardingSteps';
import {
  StyledOnboardingStepsArrow,
  StyledTenantOnboardingContent,
  StyledTenantOnboardingTitle,
} from './StyledTenantOnboardingSteps';
import i18next from '../../../i18n';

interface TenantOnboardingSetupProps extends TenantOnboardinStepsLayoutProps {
  onSubmit: (values: any) => void;
}

const TenantOnboardingSetup: React.FC<TenantOnboardingSetupProps> = ({
  onSubmit,
  ...otherProps
}) => {
  const [isSetAsHR, setIsSetAsHR] = useState(false);
  const [isHRSetup, setIsHRSetup] = useState(false);
  const [form] = Form.useForm();

  const onNextStep = () => {
    if (isHRSetup) {
      form.submit();
    } else {
      console.log('here');
      otherProps.onNextStep();
    }
  };

  const UserFormConfiguration: FormConfigurationType[][] = useMemo(() => {
    const userForm: FormConfigurationType[][] = [
      [
        {
          col: 11,
          offset: 0,
          name: 'firstName',
          label: i18next.t('firstName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: i18next.t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'lastName',
          label: i18next.t('lastName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: i18next.t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'birthDate',
          label: i18next.t('birthday'),
          type: InputTypes.DATEPICKER,
          birthday: true,
          rules: [
            {
              required: true,
              message: i18next.t('fieldRequired'),
            },
          ],
        },
      ],

      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: i18next.t('email'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: i18next.t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'phoneNumber',
          label: i18next.t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          rules: [
            {
              required: true,
              message: i18next.t('fieldRequired'),
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
          },
        },
      ],
    ];
    return userForm;
  }, []);

  const onFinish = async () => {
    const formValues = form.getFieldsValue(true);
    const payload = {
      ...formValues,
      birthDate: convertDateToUTC(formValues?.birthDate),
      roles: [AUTH_ROLES.HR],
      avatar: '',
      sendAccountEmail: true,
    };
    onSubmit(payload);
    otherProps.onNextStep();
  };

  return (
    <TenantOnboardinStepsLayout {...otherProps} onNextStep={onNextStep}>
      <Row justify="center" gutter={[0, 40]}>
        <Col span={isHRSetup ? 23 : 24}>
          {isHRSetup && (
            <Row>
              <Col>
                <StyledOnboardingStepsArrow
                  onClick={() => {
                    setIsHRSetup(false);
                    onSubmit(undefined);
                  }}
                >
                  <Row>
                    <Col>
                      <BackwardOutlined />
                    </Col>
                    <Col>{i18next.t('goBack')}</Col>
                  </Row>
                </StyledOnboardingStepsArrow>
              </Col>
            </Row>
          )}
        </Col>
        {!isHRSetup && (
          <Col span={24}>
            <Row justify="center">
              <Col>
                <StyledTenantOnboardingTitle>
                  {i18next.t('Human Resources')}
                </StyledTenantOnboardingTitle>
              </Col>
            </Row>
          </Col>
        )}
        {!isHRSetup && (
          <Col span={18}>
            <Row justify="center" gutter={[0, 20]}>
              <Col>
                <StyledTenantOnboardingContent>
                  {i18next.t('hrWelcome')}
                </StyledTenantOnboardingContent>
              </Col>
              <Col>
                <StyledTenantOnboardingContent>
                  {i18next.t('hrRepresentative')}
                </StyledTenantOnboardingContent>
              </Col>
              <Col md={24} xs={24} sm={24} lg={20} xl={18}>
                <Row gutter={[0, 30]}>
                  <Col span={24} />
                  <Col span={24}>
                    <Row justify="space-between">
                      <Col>
                        <StyledOnboardingStepsArrow
                          success={isSetAsHR}
                          onClick={() => {
                            setIsSetAsHR(true);
                            setIsHRSetup(false);
                          }}
                        >
                          <Col>
                            <CheckCircleOutlined />
                          </Col>
                          <Col>{i18next.t('hrForNow')}</Col>
                        </StyledOnboardingStepsArrow>
                      </Col>
                      <Col>
                        <StyledOnboardingStepsArrow
                          onClick={() => {
                            setIsHRSetup(true);
                            setIsSetAsHR(false);
                          }}
                        >
                          {i18next.t('createAccount')}
                        </StyledOnboardingStepsArrow>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {isHRSetup && (
          <Col span={18}>
            <GenericForm
              formConfiguration={UserFormConfiguration}
              form={form}
              onFinish={onFinish}
            />
          </Col>
        )}
      </Row>
    </TenantOnboardinStepsLayout>
  );
};

export default TenantOnboardingSetup;
