import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { GetTimeOffToApproveDTO } from 'types/TimeOff';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { useTranslation } from 'react-i18next';
import { getEmployeesByProjectOrDepartmentIds } from 'api/timeOffService';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { fetchProjectsList, fetchDepartmentsList } from './utils';

interface IProps {
  fetchDataApprover: (data: GetTimeOffToApproveDTO) => void;
}

export default function Filters({ fetchDataApprover }: IProps) {
  const [form] = Form.useForm();
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
  const [departments, setDep] = useState<string[]>([]);
  const [projects, setProject] = useState<string[]>([]);
  const { data } = useEmployeeSkimData();
  const { t } = useTranslation();

  const onFieldsChange = () => {
    const projectFieldValue = form?.getFieldValue('projects');
    setProject(
      projectFieldValue?.map((item: { value: string }) => item?.value)
    );

    const departmentsFieldValue = form?.getFieldValue('departments');
    setDep(
      departmentsFieldValue?.map((item: { value: string }) => item?.value)
    );

    fetchDataApprover({
      projects:
        form
          .getFieldValue('projects')
          ?.map((item: OptionType) => item?.value) ?? [],
      departments:
        form
          .getFieldValue('departments')
          ?.map((item: OptionType) => item?.value) ?? [],
      employees: [form.getFieldValue('employees')] ?? [],
    });
  };

  useEffect(() => {
    if (departments?.length || projects?.length) {
      getEmployeesByProjectOrDepartmentIds({ departments, projects }).then(
        (result) => {
          setEmployeeOptions(
            result?.data?.map((emp: any) => ({
              id: emp.id,
              label: `${emp.firstname} ${emp.lastname}`,
              value: emp.id,
            })) || []
          );
        }
      );
    } else {
      setEmployeeOptions(
        data?.map((emp: any) => ({
          id: emp.id,
          label: `${emp.firstName} ${emp.lastName}`,
          value: emp.id,
        })) || []
      );
    }
  }, [departments, projects, data]);

  const FilterFormConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 7,
          offset: 0,
          name: 'departments',
          label: t('departments'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchDepartmentsList,
          mode: 'multiple',
          disabled: !!projects?.length,
        },
        {
          col: 7,
          offset: 1,
          name: 'projects',
          label: t('projects'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchProjectsList,
          mode: 'multiple',
          disabled: !!departments?.length,
        },

        {
          col: 7,
          offset: 1,
          name: 'employees',
          label: t('employee'),
          node: 'tags',
          selectOptions: employeeOptions,
          type: InputTypes.SELECT,
          showSearch: true,
        },
      ],
    ],
    [employeeOptions]
  );

  return (
    <GenericForm
      formConfiguration={FilterFormConfig}
      form={form}
      onFieldsChange={onFieldsChange}
    />
  );
}
