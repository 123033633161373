import { Dispatch } from 'redux';
import { getHeadcount } from 'api/dashboardStats';
import { PageOptions } from 'types/QueryParams';
import { Headcount } from './types';

export const fetchHeadcount =
  (queryParams: PageOptions) => (dispatch: Dispatch) => {
    dispatch({
      type: Headcount.FETCH_HEADCOUNT_START,
    });

    getHeadcount(queryParams)
      .then((res) => {
        dispatch({
          type: Headcount.FETCH_HEADCOUNT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({ type: Headcount.FETCH_HEADCOUNT_ERRORED, payload: e });
      });
  };
