import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import TrackingCard from 'ui-v2/routes/tracking/trackingCard';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { TrackingHoursToApproveColumns } from 'table-configuration/trackingHoursConfigs/TrackingHoursToApproveConfig';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import OvertimeApprovalModal from './OvertimeApprovalModal';

const TrackingToApprove: React.FC = () => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const {
    fetch,
    trackedHoursToApprove: { data, sectionTotal, overallTotal, loading, meta },
    urlState: { tab },
    TabLabel,
  } = useTrackedHoursData();
  const [modalVisible, setModalVisible] = useState(false);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (tab === TabLabel.toApprove) {
      fetch({});
    }
  }, [tab]);

  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const onPageChange = useCallback((page: number) => {
    fetch({ pageOptions: { page } });
  }, []);

  useEffect(() => {
    if (tab === TabLabel.toApprove && searchParams.get('view-trackingHour')) {
      toogleModalVisibility();
    }
  }, [searchParams, tab]);

  const canOpen =
    authUserRole &&
    [
      AUTH_ROLES.AMDIN,
      AUTH_ROLES.HR,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
    ].includes(authUserRole);

  return (
    <>
      <Row>
        <Col span={24}>
          <TrackingCard
            overallTitle="Total approved hours:"
            perPageTitle={t('totalHoursToApprove')}
            pageTotal={sectionTotal}
            overallTotal={overallTotal}
            loading={loading}
            isEmpty={!data?.length}
          >
            <DataGrid
              columns={TrackingHoursToApproveColumns}
              data={data}
              loading={loading}
              paginationName="TrackingHours"
              multipleSelect={false}
              totalItems={meta?.itemCount}
              currentPage={meta?.page}
              totalPages={meta?.pageCount}
              onPageChange={onPageChange}
            />
          </TrackingCard>
        </Col>
      </Row>
      {modalVisible && canOpen && (
        <OvertimeApprovalModal
          open={modalVisible}
          closeModal={toogleModalVisibility}
        />
      )}
    </>
  );
};

export default TrackingToApprove;
