import React from 'react';

export const EmailIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6665 3.83301L8.4706 8.59588C9.02158 8.98156 9.29707 9.1744 9.59672 9.2491C9.86142 9.31508 10.1383 9.31508 10.403 9.2491C10.7026 9.1744 10.9781 8.98156 11.5291 8.59588L18.3332 3.83301M5.6665 14.6663H14.3332C15.7333 14.6663 16.4334 14.6663 16.9681 14.3939C17.4386 14.1542 17.821 13.7717 18.0607 13.3013C18.3332 12.7665 18.3332 12.0665 18.3332 10.6663V5.33301C18.3332 3.93288 18.3332 3.23281 18.0607 2.69803C17.821 2.22763 17.4386 1.84517 16.9681 1.60549C16.4334 1.33301 15.7333 1.33301 14.3332 1.33301H5.6665C4.26637 1.33301 3.56631 1.33301 3.03153 1.60549C2.56112 1.84517 2.17867 2.22763 1.93899 2.69803C1.6665 3.23281 1.6665 3.93288 1.6665 5.33301V10.6663C1.6665 12.0665 1.6665 12.7665 1.93899 13.3013C2.17867 13.7717 2.56112 14.1542 3.03153 14.3939C3.56631 14.6663 4.26637 14.6663 5.6665 14.6663Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
