export enum AUTH_ROLES {
  AMDIN = 'admin',
  EDITOR = 'editor',
  EMPLOYEE = 'employee',
  HR = 'hr',
  GUEST_TRACKING_HOURS = 'guest_tracking_hours',
  GUEST = 'guest',
  SUPER_ADMIN = 'super_admin',
  PAYROLL_MANAGER = 'payroll_manager',
}
