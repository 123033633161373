import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { AssetSubCategory, CreateSubAssetDto } from 'types/AssetCategories';
import {
  createAssetSubCategory,
  updateAssetSubCategory,
} from 'api/assetCategories';
import { toastErrorMessages } from 'utils/utilFunctions';
import { fetchAssetSubCategories } from 'redux/assetCategories/actions';
import { useDispatch } from 'react-redux';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedSubCategory: AssetSubCategory | undefined;
  assetCategoryId: any;
}

function AssetSubCategoryForm({
  open,
  closeModal,
  selectedSubCategory,
  assetCategoryId,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onFormFinish(values: CreateSubAssetDto) {
    const valuesToSend: CreateSubAssetDto = {
      ...values,
      subCategoryName: values.subCategoryName,
      assetCategoryId,
    };
    setLoading(true);

    if (selectedSubCategory?.id) {
      updateAssetSubCategory(selectedSubCategory?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('assetSubCategoryUpdatedSuccessfully'));
            searchParams.append('edited-sub-category', 'true');
            closeModal();
            dispatch(fetchAssetSubCategories(assetCategoryId));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createAssetSubCategory(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('assetSubCategoryCreatedSuccessfully'));
            searchParams.append('added-sub-category', 'true');
            closeModal();
            dispatch(fetchAssetSubCategories(assetCategoryId));
          }
        })
        .catch(() => {
          toast.error(t('assetSubCategoryNotCreated'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    dispatch(fetchAssetSubCategories(assetCategoryId));
  }, [assetCategoryId]);

  const AssetFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'subCategoryName',
          label: t('assetSubCategory'),
          type: InputTypes.INPUT,
          defaultValue: selectedSubCategory?.subCategoryName || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [selectedSubCategory]
  );

  return (
    <GenericModal
      title={
        selectedSubCategory?.id
          ? t('editAssetSubCategory')
          : t('addAssetSubCategory')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={AssetFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default AssetSubCategoryForm;
