/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Popover, Row, Skeleton } from 'antd';

import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import { EmployeeAssignmnetsType, EmployeeType, PayType } from 'types/Employee';
import { formatDate } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import * as Styled from '../../PersonalInfo/PersonalInfoStyles';
import { getEmployeeSalary } from './utils';

interface IProps {
  employeeDetail: EmployeeType;
  employeeAssignments: EmployeeAssignmnetsType;
  loading: boolean;
}
export default function EmployeeWorkInformation({
  employeeDetail,
  employeeAssignments,
  loading,
}: IProps) {
  if (!employeeDetail || !employeeAssignments) {
    return null;
  }
  const { workPosition } = employeeAssignments;
  const { hireDate, workEmail } = employeeDetail;
  const { t } = useTranslation();

  return (
    <>
      <CardItem title={t('workInformation')}>
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 6, width: '100%' }}
        >
          <Row gutter={[0, 16]}>
            <Styled.RowItem gutter={[0, 10]}>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('position')}
                  value={workPosition?.name || ''}
                  children={undefined}
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('companyhireDate')}
                  value={formatDate(hireDate)}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            <Styled.RowItem gutter={[0, 10]} last>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('workEmail')}
                  children={
                    <Styled.ItemValue ellipsis style={{ width: '80%' }}>
                      <Popover content={workEmail} placement="topLeft">
                        {workEmail || ''}
                      </Popover>
                    </Styled.ItemValue>
                  }
                />
              </Col>
              <Col md={{ span: 12 }} sm={{ span: 24 }}>
                <Item
                  label={t('department')}
                  value={workPosition?.department?.name || ''}
                  children={undefined}
                />
              </Col>
            </Styled.RowItem>
            {employeeDetail?.salary && (
              <Styled.RowItem gutter={[0, 10]} last>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={
                      employeeDetail?.payType === PayType.HOURLY
                        ? t('hourlyRate')
                        : t('grossSalary')
                    }
                    value={getEmployeeSalary(
                      employeeDetail,
                      employeeAssignments
                    )}
                    children={undefined}
                  />
                </Col>
              </Styled.RowItem>
            )}
          </Row>
        </Skeleton>
      </CardItem>
    </>
  );
}
