import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';

const FeatureFlagsList: React.FC = () => {
  const { columns } = useHeader();
  // const [queryParams] = useState<TenantsQueryParamsType>({
  //   pageOptions: { take },
  // });
  const { data, getTenantFeatureList } = useTenantFeatureData();
  const [searchParams] = useSearchParams();
  console.log(111, data);
  useEffect(() => {
    if (searchParams.get('id')) {
      getTenantFeatureList(searchParams.get('id') || '');
    }
  }, [searchParams]);

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={false}
          columns={columns}
          data={data}
          paginationName="ExpensesReport"
          totalItems={30}
          currentPage={1}
          totalPages={1}
          onPageChange={() => console.log('Ababa')}
        />
      </>
    </Content>
  );
};

export default FeatureFlagsList;
