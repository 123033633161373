import { Slider } from 'antd';
import styled from 'styled-components';

export const StyledSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;
`;

export const StyledLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 8px;
  align-self: flex-start;
`;

export const StyledCenteredSliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledCenteredSlider = styled(Slider)`
  flex: 1;
  margin-right: 10px;
`;

export const StyledPercentage = styled.span`
  font-size: 0.9rem;
  min-width: 40px;
  text-align: right;
`;
