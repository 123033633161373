import { Form, Row, Col, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormConfigurationType } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AuthUserCredentials, AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginFormLayout from './LoginFormLayout';

export const RegisterLink = styled(Typography.Link)``;

interface LoginFormProps {
  onSubmit: (credentials: AuthUserCredentials) => void;
}
const ForgotPasswordLink = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 40px;
`;

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const authUserState: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const { loading, accountBlocked: isAccountBlocked } = authUserState;

  const onFinish = () => {
    onSubmit({
      ...form.getFieldsValue(true),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    form.submit();
  };

  const LoginFormConfiguration: FormConfigurationType[][] = [
    [
      {
        col: 24,
        offset: 0,
        name: 'email',
        label: t('email'),
        type: 'input',
        onPressEnter: () => onFinish(),
        rules: [
          {
            required: true,
            message: t('requiredEmail'),
          },
        ],
      },
      {
        col: 24,
        offset: 0,
        name: 'password',
        label: t('password'),
        type: 'input',
        onPressEnter: () => onFinish(),
        inputProps: {
          type: 'password',
        },
        rules: [
          {
            required: true,
            message: t('Password is required!'),
          },
        ],
      },
    ],
  ];

  return (
    <LoginFormLayout>
      <GenericForm
        formConfiguration={LoginFormConfiguration}
        form={form}
        loading={loading}
      >
        <ForgotPasswordLink>
          <Link to="/forgot-password">{t('forgotPassword?')}</Link>
        </ForgotPasswordLink>
        <FormItemStyled>
          <Row justify="end" align="middle" wrap gutter={[0, 10]}>
            <Col xs={24} sm={24} md={10} lg={11}>
              <StyledButton
                type="primary"
                disabled={isAccountBlocked}
                onClick={onFinish}
              >
                {t('signIn')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </LoginFormLayout>
  );
};

export default LoginForm;
