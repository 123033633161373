import Styled from 'styled-components';

export const StyledWarning = Styled('div')`
    margin-top:30px;
    margin-bottom:45px;
    display: flex;
    align-items: center;
    background-color: lightyellow;
    padding: 12px;
  `;
export const StyledIcon = Styled('div')`
    color: orange;
    margin-right: 8px;
  `;
export const StyledTitle = Styled('div')`
   fontWeight: '700',
   fontSize: '20px',
   marginBottom: '0px',
`;
export const StyledText = Styled('div')`
   marginTop: '30px', 
   marginBottom: '20px'
`;
export const StyledBoldText = Styled('div')`
   marginTop: '30px';
   marginBottom: '20px';
   font-weight: bold;
   font-size: '20px';
`;
