import styled from 'styled-components';
import { Col, Row } from 'antd';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
`;

export const StyledNoDataText = styled(Row)`
  min-height: 350px;
`;

export const StyledSelectWrapper = styled(Col)`
  margin-top: 1rem;
  width: 100%;
`;
