import React from 'react';
import { RulesType } from 'types/FormTypes';
import styled from 'styled-components';
import { FormItemStyled, StyledInput } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  inputProps?: {
    type: 'number' | 'email' | 'text' | 'password' | 'decimal';
    min?: number;
    max?: number;
    maxLength?: number;
  };
  validateStatus?: 'error' | 'validating';
  disabledInput?: boolean;
  disabledButton?: boolean;
  help?: string;
  hideLabel?: boolean | undefined;
  hint?: React.ReactNode | string;
  buttonText?: string | undefined;
  onButtonClick?: () => void;
  defaultValue: string | undefined;
}

const StyledButton = styled.div`
  border-radius: 8px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  background: #155eef;
  color: #ffffff;
  width: 80px;
  margin-left: 8px;
  cursor: pointer;
`;

const InputWithButton: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  inputProps,
  validateStatus,
  disabledInput = false,
  help,
  hideLabel,
  hint,
  buttonText,
  defaultValue,
  onButtonClick,
}) => (
  <FormItemStyled
    name={name}
    label={!hideLabel && placeholder}
    rules={rules}
    validateStatus={validateStatus}
    help={help}
    {...(hint && { tooltip: hint })}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledInput
        {...inputProps}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabledInput}
        style={{ flexGrow: 1 }}
      />
      <StyledButton onClick={onButtonClick}>{buttonText}</StyledButton>
    </div>
  </FormItemStyled>
);

export default InputWithButton;
