import { MetaPagination } from 'types/GridTable';
import { HeadcountType } from 'types/Headcount';

export enum Headcount {
  FETCH_HEADCOUNT_START = 'FETCH_HEADCOUNT_START',
  FETCH_HEADCOUNT_SUCCESS = 'FETCH_HEADCOUNT_SUCCESS',
  FETCH_HEADCOUNT_ERRORED = 'FETCH_HEADCOUNT_ERRORED',
}

export interface HeadcountState {
  data: {
    data: Array<HeadcountType>;
    meta: Array<MetaPagination>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
