import { useCallback, useEffect } from 'react';
import { RootState } from 'redux/store';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ChartData } from 'chart.js';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  fetchTrackedHourDetails,
  fetchTrackedHoursSummary,
  fetchTrackedHoursBarChart,
  fetchTrackedHoursPieChart,
  fetchTrackedHoursToApprove,
} from 'redux/trackings/actions';
import { TrackingsState } from 'redux/trackings/types';
import {
  TrackedHoursSearchOptionDTO,
  TrackedHoursTabLabel,
} from 'types/tracking';
import { PageOptions } from 'types/QueryParams';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { PALETE } from 'components/styledComponents/colors';
import { getTenantAvatarStyle } from 'ui-v2/routes/superAdmin/TenantAccountsList/utils';
import { useTranslation } from 'react-i18next';

type UrlFilters = {
  tab?: string;
  endDate?: string;
  startDate?: string;
  filterText?: string;
  projectIds?: string[];
  employeeIds?: string[];
};

enum TabLabel {
  summary = 'Summary',
  detailed = 'Detailed',
  toApprove = 'To Approve',
  timeTracker = 'Time Tracker',
}

const useUrlFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getUrlState = useCallback(() => {
    const tab =
      Object.values(TabLabel)[
        Object.keys(TabLabel).indexOf(searchParams?.get('tab') as any)
      ];
    const startDate = searchParams?.get('startDate') || undefined;
    const endDate = searchParams?.get('endDate') || undefined;
    const projectIds: string[] = JSON.parse(
      searchParams?.get('projectIds') || JSON.stringify([])
    );
    const employeeIds: string[] = JSON.parse(
      searchParams?.get('employeeIds') || JSON.stringify([])
    );
    const filterText = searchParams.get('filterText') || undefined;

    return {
      tab,
      projectIds,
      employeeIds,
      startDate,
      endDate,
      filterText,
    };
  }, [searchParams]);

  const setUrlState = useCallback(
    ({ ...params }: UrlFilters) => {
      if (params?.tab) {
        params.tab =
          Object.keys(TrackedHoursTabLabel)[
            Object.values(TrackedHoursTabLabel).indexOf(
              params?.tab as TrackedHoursTabLabel
            )
          ];
        searchParams.set('tab', params.tab);
      }
      if (params?.startDate) {
        searchParams.set('startDate', params.startDate);
      }
      if (params?.endDate) {
        searchParams.set('endDate', params.endDate);
      }
      if (params?.filterText === '') {
        searchParams.delete('filterText');
      } else {
        searchParams.set(
          'filterText',
          params?.filterText || searchParams?.get('filterText') || ''
        );
      }
      if (params?.projectIds) {
        if (params?.projectIds?.length) {
          searchParams.set('projectIds', JSON.stringify(params.projectIds));
        } else {
          searchParams.delete('projectIds');
        }
      }
      if (params?.employeeIds) {
        if (params.employeeIds?.length) {
          searchParams.set('employeeIds', JSON.stringify(params.employeeIds));
        } else {
          searchParams.delete('employeeIds');
        }
      }
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  return {
    getUrlState,
    setUrlState,
  };
};

const useTab = () => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const navigate = useNavigate();
  const { getUrlState } = useUrlFilters();
  const { tab, startDate, endDate } = getUrlState();

  const protectedTabs = useCallback(() => {
    if (authUserRole === AUTH_ROLES.EMPLOYEE && tab === TabLabel.summary) {
      navigate(
        `/employee-details/tracker?tab=detailed&startDate=${startDate}&endDate=${endDate}`
      );
    }
    if (
      (authUserRole === AUTH_ROLES.GUEST ||
        authUserRole === AUTH_ROLES.GUEST_TRACKING_HOURS) &&
      (tab === TabLabel.summary || tab === TabLabel.toApprove)
    ) {
      navigate(
        `/tracked?tab=detailed&startDate=${startDate}&endDate=${endDate}`
      );
    }
  }, [authUserRole, tab, startDate, endDate]);

  useEffect(() => {
    protectedTabs();
  }, [tab, authUserRole]);

  const getTabLabels = useCallback(() => {
    if (authUserRole === AUTH_ROLES.AMDIN || authUserRole === AUTH_ROLES.HR) {
      return Object.values(TabLabel);
    }
    if (
      authUserRole === AUTH_ROLES.EMPLOYEE ||
      authUserRole === AUTH_ROLES.PAYROLL_MANAGER
    ) {
      return [TabLabel.detailed, TabLabel.toApprove, TabLabel.timeTracker];
    }
    return [TabLabel.detailed];
  }, [authUserRole]);

  return { tabLabels: getTabLabels() };
};

const useRequest = () => {
  const dispatch = useDispatch();
  const { getUrlState } = useUrlFilters();

  const urlState = getUrlState();

  const transformedPayload = ({
    searchOptions,
    pageOptions,
    urlFilters,
  }: {
    searchOptions?: TrackedHoursSearchOptionDTO;
    pageOptions?: PageOptions;
    urlFilters?: UrlFilters;
  }) => {
    let urlSearchOptions: UrlFilters = {};
    if (urlFilters?.startDate && urlFilters?.endDate) {
      urlSearchOptions = {
        ...urlSearchOptions,
        startDate: urlFilters.startDate,
        endDate: urlFilters.endDate,
      };
    } else {
      urlSearchOptions = {
        ...urlSearchOptions,
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      };
    }
    if (urlFilters?.employeeIds) {
      urlSearchOptions = {
        ...urlSearchOptions,
        employeeIds: urlFilters.employeeIds,
      };
    }
    if (urlFilters?.projectIds) {
      urlSearchOptions = {
        ...urlSearchOptions,
        projectIds: urlFilters.projectIds,
      };
    }
    return {
      ...(pageOptions ? { pageOptions } : { pageOptions: {} }),
      ...(searchOptions
        ? { searchOptions }
        : { searchOptions: urlSearchOptions }),
      ...(urlFilters?.filterText
        ? {
            filterOptions: {
              filterFields: ['description'],
              filterText: urlFilters.filterText,
            },
          }
        : { filterOptions: {} }),
    };
  };

  const fetch = useCallback(
    ({
      searchOptions,
      pageOptions,
    }: {
      searchOptions?: TrackedHoursSearchOptionDTO;
      pageOptions?: PageOptions;
    }) => {
      const { tab } = urlState;
      const payload = transformedPayload({
        searchOptions,
        pageOptions,
        urlFilters: urlState,
      });
      switch (tab) {
        case TabLabel.summary: {
          batch(() => {
            dispatch(fetchTrackedHoursBarChart(payload));
            dispatch(fetchTrackedHoursPieChart(payload));
            dispatch(fetchTrackedHoursSummary(payload));
          });
          break;
        }
        case TabLabel.detailed: {
          dispatch(fetchTrackedHourDetails(payload));
          break;
        }
        case TabLabel.toApprove: {
          dispatch(fetchTrackedHoursToApprove(payload));
          break;
        }
        default:
          break;
      }
    },
    [urlState]
  );
  return { fetch };
};

const useResponse = () => {
  const { t } = useTranslation();
  const {
    trackedHoursBarChart: {
      data: barChartResponseData,
      loaded: barChartResponseDataLoaded,
      ...otherBarChartState
    },
    trackedHoursPieChart: {
      data: pieChartResponseData,
      loaded: pieChartResponseDataLoaded,
      ...otherPieChartState
    },
    ...otherState
  }: TrackingsState = useSelector((state: RootState) => state.trackedHours);

  const formatDateLabel = (days: number | undefined, months: number | null) => {
    const date = moment()
      .dayOfYear(days || 0)
      .month(months || 0);
    const dayOfWeek = t(date.format('ddd'));
    const month = t(date.format('MMM'));
    const dayOfMonth = t(date.format('DD'));

    return `${dayOfWeek}, ${month} ${dayOfMonth}`;
  };

  const getBarChartData = useCallback((): ChartData<'bar'> => {
    if (barChartResponseDataLoaded && barChartResponseData?.length) {
      const isMonthsMode = !!barChartResponseData.find((el) => !el?.days);
      const sortedData = isMonthsMode
        ? barChartResponseData.sort((a, b) => a.months - b.months)
        : barChartResponseData;
      const datasets = [
        {
          data: sortedData.map((item) => item.sum),
          backgroundColor: PALETE['blue-400'],
        },
      ];
      if (isMonthsMode) {
        return {
          labels: sortedData.map((item) =>
            moment().month(item.months).format('MMMM')
          ),
          datasets,
        };
      }
      return {
        labels: sortedData.map((item) =>
          formatDateLabel(item?.days, item?.months)
        ),
        datasets,
      };
    }
    return {
      labels: [],
      datasets: [],
    };
  }, [barChartResponseDataLoaded, barChartResponseData]);

  const getPieChartData = useCallback((): ChartData<'doughnut'> => {
    if (pieChartResponseDataLoaded && pieChartResponseData?.length) {
      const datasets = [
        {
          data: pieChartResponseData.map((item) => item.totalCount),
          backgroundColor:
            pieChartResponseData?.map(
              (item) =>
                getTenantAvatarStyle({
                  initials: item?.projectName,
                  saturation: 80,
                  lightness: 40,
                }).backgroundColor
            ) || PALETE['blue-400'],
        },
      ];
      return {
        labels: pieChartResponseData.map((item) => item.projectName),
        datasets,
      };
    }
    return {
      labels: [],
      datasets: [],
    };
  }, [pieChartResponseData, pieChartResponseDataLoaded]);

  return {
    barChart: {
      chartData: getBarChartData(),
      loaded: barChartResponseDataLoaded,
      ...otherBarChartState,
    },
    pieChart: {
      chartData: getPieChartData(),
      loaded: pieChartResponseDataLoaded,
      ...otherPieChartState,
    },
    ...otherState,
  };
};

export const useTrackedHoursData = () => {
  const { fetch } = useRequest();
  const { setUrlState, getUrlState } = useUrlFilters();
  const { tabLabels } = useTab();
  const trackingHoursState = useResponse();
  const urlState = getUrlState();

  return {
    ...trackingHoursState,
    TabLabel,
    tabLabels,
    urlState,
    setUrlState,
    fetch,
  };
};
