const routesConfig = {
  tenants: {
    visibleHeaderRoutes: ['tenants'],
    hideDetailsRoutes: ['tenants'],
  },
  'vendor-questionnaire': {
    visibleHeaderRoutes: [
      'vendor-questionnaire',
      'vendor-questionnaire-details',
    ],
    hideDetailsRoutes: ['vendor-questionnaire'],
  },
  'super-admin-profile': {
    visibleHeaderRoutes: ['super-admin-profile'],
    hideDetailsRoutes: ['super-admin-profile'],
  },
  'tenant-accounts': {
    visibleHeaderRoutes: ['tenant-accounts'],
    hideDetailsRoutes: ['tenant-accounts'],
  },
  'ip-whitelisting': {
    visibleHeaderRoutes: ['ip-whitelisting'],
    hideDetailsRoutes: ['ip-whitelisting'],
  },
  'public-super-admins': {
    visibleHeaderRoutes: ['public-super-admins'],
    hideDetailsRoutes: ['public-super-admins'],
  },
  'feature-flags': {
    visibleHeaderRoutes: ['feature-flags'],
    hideDetailsRoutes: ['feature-flags'],
  },
  vendors: {
    visibleHeaderRoutes: ['vendors', 'vendor-details'],
    hideDetailsRoutes: ['vendors'],
  },
  candidates: {
    visibleHeaderRoutes: [
      'candidates',
      'profile',
      'add-candidate',
      'edit-profile',
      'hire-candidate',
    ],
    hideDetailsRoutes: ['candidates'],
  },
  employees: {
    visibleHeaderRoutes: [
      'employees',
      'employee-profile',
      'add-employee',
      'edit-employee',
    ],
    hideDetailsRoutes: ['employees'],
  },
  'work-positions': {
    visibleHeaderRoutes: ['work-positions', 'work-position-details'],
    hideDetailsRoutes: ['work-positions'],
  },
  'purchase-requests': {
    visibleHeaderRoutes: ['purchase-requests'],
    hideDetailsRoutes: ['purchase-requests'],
  },
  projects: {
    visibleHeaderRoutes: ['projects', 'project-details'],
    hideDetailsRoutes: ['projects'],
  },
  questionnaires: {
    visibleHeaderRoutes: ['questionnaires', 'questionnaire-details'],
    hideDetailsRoutes: ['questionnaires'],
  },
  ideabox: {
    visibleHeaderRoutes: ['ideabox', 'ideabox-details'],
    hideDetailsRoutes: ['ideabox'],
  },
  assets: {
    visibleHeaderRoutes: [
      'assets',
      'add-asset',
      'edit-asset',
      'asset-details',
      'asset-logs',
    ],
    hideDetailsRoutes: ['assets'],
  },
  departments: {
    visibleHeaderRoutes: ['departments', 'department-details'],
    hideDetailsRoutes: ['departments'],
  },
  trainings: {
    visibleHeaderRoutes: [
      'trainings',
      'training-details',
      'add-training',
      'edit-training',
    ],
    hideDetailsRoutes: ['trainings'],
  },
  users: {
    visibleHeaderRoutes: ['users'],
    hideDetailsRoutes: ['users'],
  },
  skills: {
    visibleHeaderRoutes: ['skills', 'category-details'],
    hideDetailsRoutes: ['skills'],
  },
  tracking: {
    visibleHeaderRoutes: [''],
    hideDetailsRoutes: ['tracking'],
  },
  evaluations: {
    visibleHeaderRoutes: ['evaluations', 'view-evaluation'],
    hideDetailsRoutes: ['evaluations'],
  },
  'candidate-registration': {
    visibleHeaderRoutes: ['candidate-registration'],
    hideDetailsRoutes: ['candidate-registration'],
  },
  'open-positions': {
    visibleHeaderRoutes: [
      'open-positions',
      'add-open-position',
      'edit-open-position',
      'open-position-details',
      'skillset-matching',
    ],
    hideDetailsRoutes: [
      'open-positions',
      'add-open-position',
      'edit-open-position',
      'open-position-details',
      'skillset-matching',
    ],
  },
  interviews: {
    visibleHeaderRoutes: ['interviews'],
    hideDetailsRoutes: ['interviews'],
  },
  certifications: {
    visibleHeaderRoutes: ['certifications', 'certification-details'],
    hideDetailsRoutes: ['certifications'],
  },
  dashboard: {
    visibleHeaderRoutes: ['dashboard'],
    hideDetailsRoutes: ['dashboard'],
  },
  'evaluation-periods': {
    visibleHeaderRoutes: ['evaluation-periods'],
    hideDetailsRoutes: ['evaluation-periods'],
  },
  'time-off': {
    visibleHeaderRoutes: [],
    hideDetailsRoutes: [],
  },
  onboardings: {
    visibleHeaderRoutes: ['onboardings', 'onboarding-details'],
    hideDetailsRoutes: ['onboardings'],
  },
  'employee-details': {
    visibleHeaderRoutes: ['employee-details', 'calendar'],
    hideDetailsRoutes: ['employee-details', 'calendar'],
  },
  employee: {
    visibleHeaderRoutes: ['employee', 'profile'],
    hideDetailsRoutes: ['employee', 'profile'],
  },
  settings: {
    visibleHeaderRoutes: ['settings'],
    hideDetailsRoutes: ['settings'],
  },

  'my-profile': {
    visibleHeaderRoutes: ['my-profile', 'edit-user-profile', 'change-password'],
    hideDetailsRoutes: [],
  },
  requests: {
    visibleHeaderRoutes: ['requests'],
    hideDetailsRoutes: ['requests'],
  },
  benefits: {
    visibleHeaderRoutes: ['benefits'],
    hideDetailsRoutes: ['benefits'],
  },
  files: {
    visibleHeaderRoutes: ['files'],
    hideDetailsRoutes: ['files'],
  },
  'reports-analytics': {
    visibleHeaderRoutes: ['reports-analytics'],
    hideDetailsRoutes: ['reports-analytics'],
  },
  'payroll-rules': {
    visibleHeaderRoutes: ['payroll-rules', 'payroll-rule-details', 'edit-rule'],
    hideDetailsRoutes: ['payroll-rules'],
  },
  'payroll-report': {
    visibleHeaderRoutes: ['payroll-report', 'generate-report'],
    hideDetailsRoutes: ['payroll-report'],
  },
  'skillset-matching': {
    visibleHeaderRoutes: ['skillset-matching'],
    hideDetailsRoutes: [],
  },
  'data-entry': {
    visibleHeaderRoutes: ['data-entry', 'data-entry'],
    hideDetailsRoutes: ['data-entry'],
  },
  'asset-categories': {
    visibleHeaderRoutes: ['asset-categories', 'asset-category-details'],
    hideDetailsRoutes: ['asset-categories'],
  },
  'vendor-categories': {
    visibleHeaderRoutes: ['vendor-categories', 'vendor-category-details'],
    hideDetailsRoutes: ['vendor-categories'],
  },
  'purchase-orders': {
    visibleHeaderRoutes: ['purchase-orders', 'purchase-order-details'],
    hideDetailsRoutes: ['purchase-orders'],
  },
  invoicing: {
    visibleHeaderRoutes: ['invoicing', 'invoice-details'],
    hideDetailsRoutes: ['invoicing'],
  },
  expenses: {
    visibleHeaderRoutes: ['expenses', 'expense-details'],
    hideDetailsRoutes: ['expenses'],
  },
};

export type ConfigItem = keyof typeof routesConfig;
export default routesConfig;
