import { Col, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputTypes } from 'types/FormTypes';
import { ExpenseReportStatus } from 'types/ExpensesReport';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { fetchExpenses } from 'redux/expenses/action';
import { useDispatch } from 'react-redux';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

const statusType = [
  {
    id: ExpenseReportStatus.APPROVED,
    value: ExpenseReportStatus.APPROVED,
    label: 'Approved',
  },
  {
    id: ExpenseReportStatus.PENDING,
    value: ExpenseReportStatus.PENDING,
    label: 'Pending',
  },
  {
    id: ExpenseReportStatus.REJECTED,
    value: ExpenseReportStatus.REJECTED,
    label: 'Rejected',
  },
];

export default function ExpenseFilter() {
  const [form] = useForm();
  const [filters, setFilters] = useState<any>({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const applyFilters = useCallback(() => {
    dispatch(
      fetchExpenses({
        searchOptions: {
          status: filters.status,
        },
      })
    );
  }, [filters]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  async function onFormChange() {
    setFilters({
      status: form.getFieldValue('status') ?? [],
    });
  }
  const ExpenseConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'status',
          label: t('status'),
          type: InputTypes.SELECT,
          selectOptions: statusType,
        },
      ],
    ],
    []
  );

  return (
    <>
      <GenericForm
        formConfiguration={ExpenseConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              setFilters({});
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
