import React, { useCallback } from 'react';
import { useSidebarItems } from 'ui-v2/hooks/useSidebarItems';
import { LogOutIcon } from 'Icons/LogOutIcon';
import { clearAuthState } from 'redux/authUser/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_ROLES } from 'types/Auth';
import useTenantStore from 'redux/persisted-state/tenant-store';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { IconButton } from 'components/Actions/AssetAction';
import DrawerItem from './DrawerItem';
import { Drawer, DrawerContent, DrawerFooter } from './SideBarMobileStyles';

interface DrawerProps {
  isDrawerOpen: boolean;
  email: string;
  toggleDrawer: () => void;
}

export default function MobileDrawer({
  isDrawerOpen,
  email,
  toggleDrawer,
}: DrawerProps) {
  const { items, isActivePath } = useSidebarItems();
  const dispatch = useDispatch();
  const { clearTenant } = useTenantStore();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const onLogoutClicked = useCallback(() => {
    dispatch(clearAuthState(authUserRole === AUTH_ROLES.SUPER_ADMIN));
    clearTenant();
  }, [authUserRole]);

  return (
    <Drawer isOpen={isDrawerOpen}>
      <DrawerContent>
        {items.map((item) => (
          <DrawerItem
            key={item.path}
            item={item}
            active={isActivePath({ items: [item] })}
            toggleDrawer={toggleDrawer}
          />
        ))}
      </DrawerContent>
      <DrawerFooter>
        <span>{email}</span>
        <IconButton onClick={onLogoutClicked}>
          <LogOutIcon />
        </IconButton>
      </DrawerFooter>
    </Drawer>
  );
}
