import { Tag, Typography, Row, List } from 'antd';
import styled from 'styled-components';

export const Reason = styled(Typography.Text)`
  color: #000000;
  font-weight: 500;
  margin-left: 2px;
`;
export const Content = styled(Tag)`
  color: #000000;
  font-weight: 500;
  margin-left: 2px;
`;

export const Wrapper = styled.div<{ disabled?: boolean }>``;

export const Description = styled(Typography.Text)`
  color: #475467;
  text-transform: capitalize;
  font-style: italic;
  font-weight: 400;
`;

export const Bold = styled(Typography.Text)`
  font-weight: 600;
`;

export const PrimaryText = styled(Typography.Text)`
  font-weight: 500;
`;

export const SecondaryText = styled(Typography.Text)`
  font-weight: 500;
  color: gray;
  font-style: italic;
`;

export const StatusPopover = styled(Typography.Text)<{
  status?: string;
}>`
  ${({ status }) => (status === 'pending' ? 'color: gray' : '')};
  ${({ status }) => (status === 'approved' ? 'color: green' : '')};
  ${({ status }) => (status === 'rejected' ? 'color: red' : '')};
  text-transform: capitalize;
  font-weight: 500;
`;

export const Status = styled(Tag)`
  border-radius: 8px;
`;

export const ItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
`;

export const OverFlowComponent = styled.div<{ disabled?: boolean }>`
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
  ${({ disabled }) => disabled && 'max-height: 590px;'}
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

export const ItemValue = styled(Typography.Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
`;

export const RowItem = styled(Row)<{ last?: boolean }>`
  border-bottom: ${(props) => (props.last ? '' : '1px solid #eaecf0')};
  padding-bottom: ${(props) => (props.last ? '' : '16px')};
  width: 100%;
`;

export const Item = styled(List.Item)`
  &:hover {
    cursor: pointer;
    background: #f8f8f9;
  }
  .ant-list-item-meta {
    align-items: center !important;
  }
`;
