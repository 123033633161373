import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';

import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import SettingsActionIcon from 'Icons/SettingsActionIcon';
import InfoIcon from 'Icons/InfoIcon';
import { t } from 'i18next';

type TProps = {
  id: string;
};

const TenantAccountAction: React.FC<TProps> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function onDetailsClick() {
    searchParams.set('viewtenantaccountid', id);
    setSearchParams(searchParams);
  }

  function onSettingsClick() {
    searchParams.set('tenantaccountsettings', id);
    setSearchParams(searchParams);
  }

  return (
    <Row justify="center" align="middle" gutter={[30, 0]}>
      <Col>
        <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
          <Tooltip title={t('tenantDetails')}>
            <StyledIcon onClick={onDetailsClick}>
              <InfoIcon />
            </StyledIcon>
          </Tooltip>
        </RoleBasedButton>
      </Col>
      <Col>
        <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
          <Tooltip title={t('settings')}>
            <StyledIcon onClick={onSettingsClick}>
              <SettingsActionIcon />
            </StyledIcon>
          </Tooltip>
        </RoleBasedButton>
      </Col>
    </Row>
  );
};

export default TenantAccountAction;
