import React, { useEffect } from 'react';

import { Col } from 'antd';
import SelectComponent from 'components/NewForms/Select';
import { useTranslation } from 'react-i18next';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { TenantStatus } from 'ui-v2/components/TableFilterPopover/Filters/TenantsFilter';
import { useSearchParams } from 'react-router-dom';
import * as Styles from './TenantComponentStyles';

export default function TenantComponent() {
  const { tenants, getTenants } = useTenantsData();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getTenants({
      searchOptions: {
        status: TenantStatus.APPROVED,
      },
    });
    console.log(tenants);
  }, []);

  const onTenantChange = (value: string) => {
    searchParams.set('id', value);
    setSearchParams(searchParams);
  };

  const { t } = useTranslation();
  return (
    <Styles.Container gutter={[0, 10]}>
      <Styles.Container align="middle">
        <Col span={24}>
          <SelectComponent
            placeholder={t('records')}
            name="records"
            showSearch
            onSelect={onTenantChange}
            defaultValue={tenants.data?.at(0)?.name}
            selectOptions={
              tenants
                ? tenants.data?.map((tenant) => ({
                    label: tenant.name,
                    value: tenant.id,
                    id: tenant.id,
                  }))
                : []
            }
          />
        </Col>
      </Styles.Container>
    </Styles.Container>
  );
}
