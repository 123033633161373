import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Spin, Typography } from 'antd';

import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { InputTypes } from 'types/FormTypes';
import {
  convertDateToUTC,
  getErrorMessageString,
  isDuplicateEmailError,
  isOfUrlValid,
  toastErrorMessages,
} from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form';
import { registerEmployeeFromScratch } from 'api/employeeService';
import { Gender } from 'types/Candidates';
import { useForm } from 'antd/es/form/Form';
import { PayType } from 'types/Employee';
import { useTranslation } from 'react-i18next';
import {
  fetchTenantLocationsOptions,
  fetchWorkPositionsOptions,
} from 'components/NewForms/SelectWithLoad/utils';
import Content from 'ui-v2/components/Content';
import CardItem from 'ui-v2/components/Card';
import { StyledButton } from 'components/NewForms/FormStyled';
import { PlusIcon } from 'Icons/PlusIcon';
import { phoneNumberValidator, prefixSelector } from '../utils';

const StyledError = styled(Typography)<{
  display: boolean;
}>`
  color: red;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  visibility: ${({ display }) => (display ? 'visible' : 'hidden')};
`;

const StyledFormWrapper = styled.div<{
  border: boolean;
}>`
  border-bottom: ${({ border }) => (border ? '1px solid #dedede' : 'none')};
  margin-bottom: 16px;
`;

interface BulkUploadCreateEmployeeFormProps {
  formKey: string;
  onRemoveForm: (formKey: string) => void;
  onLoading: (formKey: string, loading: boolean) => void;
}

function BulkUploadCreateEmployeeForm({
  formKey,
  onRemoveForm,
  onLoading,
}: BulkUploadCreateEmployeeFormProps) {
  const [form] = useForm();
  const [isHourlyRated, setIsHourlyRated] = useState(false);
  const [loading, isLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const { optionsType: employmentTypes } = useGetSelectOptions({
    type: 'employement-type',
  });

  const { optionsType: workPositions } = useGetSelectOptions({
    type: 'work-position',
  });

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  const { optionsType: certifications } = useGetSelectOptions({
    type: 'certifications',
  });

  useEffect(() => {
    if (searchParams.get('save-employee') === 'true') {
      searchParams.delete('save-employee');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    onLoading(formKey, loading);
  }, [formKey, loading]);

  const onPayTypeSelect = useCallback(
    (selectedPayType: PayType) => {
      if (selectedPayType === PayType.HOURLY) {
        setIsHourlyRated(true);
      } else {
        setIsHourlyRated(false);
      }
    },
    [form]
  );

  const FormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 3,
          offset: 0,
          name: 'firstName',
          label: t('firstName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 3,
          offset: 1,
          name: 'lastName',
          label: t('lastName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 3,
          offset: 1,
          name: 'employeeIdentifier',
          label: t('employeeCode'),
          type: InputTypes.INPUT,
        },
        {
          col: 3,
          offset: 1,
          name: 'gender',
          label: t('gender'),
          type: 'select',
          selectOptions: Object.values(Gender).map((item: Gender) => ({
            id: item,
            value: item,
            label: item,
          })),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 3,
          offset: 1,
          name: 'birthDate',
          label: t('birthday'),
          type: 'datepicker',
          birthday: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 3,
          offset: 1,
          name: 'ssn',
          label: t('ssn'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 6,
          offset: 0,
          name: 'email',
          label: t('emailAddress'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 4,
          offset: 1,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT_LOAD,
          showSearch: true,
          fetchOptions: fetchWorkPositionsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 4,
          offset: 1,
          name: 'payType',
          label: t('payType'),
          type: InputTypes.SELECT,
          selectOptions: Object.values(PayType).map((value) => ({
            id: value,
            value,
            label: t(value),
          })),
          onSelect: onPayTypeSelect,
          showSearch: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },

        {
          col: 6,
          offset: 1,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: 'selectPrefix',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
          },
        },

        isHourlyRated
          ? {
              col: 6,
              offset: 0,
              name: 'hourlyRate',
              label: t('Hourly Rate'),
              type: 'selectPrefix',
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
              inputProps: {
                type: 'number',
                rows: 1,
                min: 0,
              },
              prefix: {
                name: 'currencyId',
                selectOptions: currencyOptions,
                placeholder: '',
                defaultValue: currencyOptions[1]?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
            }
          : {
              col: 6,
              offset: 0,
              name: 'salary',
              label: t('grossSalary'),
              type: 'selectPrefix',
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
              inputProps: {
                type: 'number',
                rows: 1,
                min: 0,
              },
              prefix: {
                name: 'currencyId',
                selectOptions: currencyOptions,
                placeholder: '',
                defaultValue: currencyOptions[1]?.id,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
              loading: currencyLoading,
            },
        {
          col: 6,
          offset: 1,
          name: 'tenantLocation',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [
      employmentTypes,
      workPositions,
      currencyOptions,
      certifications,
      isHourlyRated,
    ]
  );

  function onFormFinish() {
    if (loading) return;
    isLoading(true);
    const values = form.getFieldsValue(true);
    if (values?.salary < 0 || values?.hourlyRate < 0) {
      toast.warning(t('pleaseEnterAValidSalary!'));
      setError(getErrorMessageString(t('pleaseEnterAValidSalary!')));
      return;
    }
    if (values?.hourlyRate) {
      delete values?.salary;
      delete values?.salaryNeto;
    }
    let formValues = values;
    delete formValues?.currency;
    formValues = {
      ...formValues,
      workEmail: formValues.email,
      hireDate: convertDateToUTC(new Date()),
      employmentTypeId: employmentTypes[2]?.id,
      onBoardingStatus: employmentTypes[2]?.label,
      onBoardingStartDate: convertDateToUTC(new Date()),
      onBoardingEndDate: convertDateToUTC(new Date()),
      startDate: convertDateToUTC(new Date()),
      endDate: convertDateToUTC(new Date()),
      tenantLocationId: formValues.tenantLocation.value,
      ...(formValues?.hourlyRate
        ? {
            hourlyRate: Number(values.hourlyRate),
          }
        : {
            salary: Number(values.salary),
          }),
      maxWorkHoursPerMonth: 176,
      sendingAccountDetails: values.email,
      martialStatus: 'Single',
      employeeIdentifier: values.employeeIdentifier,
      workPositionId: formValues.workPositionId.value,
      resume: {
        name: 'employee-cv',
        content: '',
      },
      sendAccountEmail: false,
    };

    delete formValues.candidate;

    if (
      !formValues.linkedIn ||
      String(formValues.linkedIn).replace(/\s/g, '').length === 0
    ) {
      delete formValues.linkedIn;
    } else if (!isOfUrlValid(formValues.linkedIn)) {
      toast.warning(t('LinkedIn URL is not valid!'));
      setError(t('LinkedIn URL is not valid!'));
      return;
    } else if (isDuplicateEmailError(formValues.email)) {
      toast.warning(t('Email Already Exists'));
      setError(t('Email Already Exists'));
      return;
    }

    try {
      delete formValues.phoneNumberPrefix;
      formValues = {
        ...formValues,
        currencyId: currencyOptions.find((item: any) => item.label === 'Lek')
          ?.id,
        birthDate: convertDateToUTC(values.birthDate),
        resume: {
          name: 'employee-cv',
          content: '',
        },
        onBoardingStatus: 'Full Time',
        employmentTypeId: employmentTypes.find(
          (item) => item.label === 'Full Time'
        )?.value,
        gender: formValues.gender.charAt(0),
        startDate: formValues.onBoardingStartDate,
        endDate: formValues.onBoardingEndDate,
      };

      console.log(111, { formValues });

      registerEmployeeFromScratch({ ...formValues })
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success(t('Employee registered successfully!'));
            form.resetFields();
            onRemoveForm(formKey);
          } else {
            toast.error(response.data.error);
            setError(getErrorMessageString(response.data.error));
          }
        })
        .catch((error) => {
          setError(getErrorMessageString(error));
          toastErrorMessages(error);
        })
        .finally(() => isLoading(false));
    } catch (error) {
      toast.error(t('errorDuringThisAction'));
      setError(t('errorDuringThisAction'));
      isLoading(false);
    }
  }

  return (
    <>
      <GenericForm
        formConfiguration={FormConfiguration}
        onFinish={onFormFinish}
        form={form}
      />
      <StyledError display={!!error}>{error}</StyledError>
    </>
  );
}

export default function CreateEmployeeFormLoader() {
  const [formValues, setFormValues] = useState<{ [key: string]: boolean }>({
    form_0: false,
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(Object.values(formValues).some((item) => item === true));
  }, [formValues]);

  const onAdd = () => {
    setFormValues((prev) => ({ ...prev, [`form_${Math.random()}`]: false }));
  };

  const onLoading = (formKey: string, loading: boolean) => {
    setFormValues((prev) => ({ ...prev, [formKey]: loading }));
  };

  const onRemoveForm = (formKey: string) => {
    const form = { ...formValues };
    delete form[formKey];

    if (Object.keys(form).length === 0) {
      form.form_0 = false;
    }
    setFormValues(form);
  };

  return (
    <Content position="stretch">
      <CardItem title="">
        <Spin spinning={loading}>
          {Object.keys(formValues).map((formKey, index) => (
            <StyledFormWrapper
              border={index <= Object.keys(formValues).length - 1}
            >
              <BulkUploadCreateEmployeeForm
                formKey={formKey}
                key={formKey}
                onLoading={onLoading}
                onRemoveForm={onRemoveForm}
              />
            </StyledFormWrapper>
          ))}
          <StyledButton
            style={{ width: 'fit-content' }}
            type="link"
            icon={<PlusIcon />}
            onClick={onAdd}
          >
            {t('addMore')}
          </StyledButton>
        </Spin>
      </CardItem>
    </Content>
  );
}
