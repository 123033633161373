import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { t } from 'i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const WorkPositionActions: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onViewClick = () => {
    navigate(`work-position-details?id=${id}`);
  };

  const onEditClick = () => {
    searchParams.append('edit-work-position', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('details')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editWorkPosition')}>
                <IconButton onClick={onEditClick}>
                  <EdititingIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default WorkPositionActions;
