import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { StyledButton } from 'components/NewForms/FormStyled';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { useTranslation } from 'react-i18next';
import { TrackingFilterPanelLabel } from './utils';
import { StyledTrackingFilterPanelRow } from './StyledTrackingFilterPanel';
import TrackingFilterPanelItem from './trackingFilterPanelItem';

const TrackingFilterPanel: React.FC = () => {
  const { fetch } = useTrackedHoursData();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const [labels, setLabels] = useState<TrackingFilterPanelLabel[]>([]);
  const [hiddenLabels, setHiddenLabels] = useState<TrackingFilterPanelLabel[]>(
    []
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (authUserRole === AUTH_ROLES.AMDIN || authUserRole === AUTH_ROLES.HR) {
      setLabels(
        Object.values(TrackingFilterPanelLabel).filter(
          (label) => label !== TrackingFilterPanelLabel.FILTERS
        )
      );
    } else {
      setLabels([
        TrackingFilterPanelLabel.TEAM,
        TrackingFilterPanelLabel.DESCRIPTION,
      ]);
    }
  }, [authUserRole]);

  const onFilterVisibilityChange = (
    hiddenFilterLabels: TrackingFilterPanelLabel[]
  ) => {
    setHiddenLabels(hiddenFilterLabels);
  };

  const hasBorderRight = (index: number) => {
    if (hiddenLabels.length) {
      const currentVisibleFilterLabels = labels.filter(
        (el) => el !== hiddenLabels[hiddenLabels.indexOf(el)]
      );
      return index < currentVisibleFilterLabels.length - 1;
    }
    return index < labels.length - 1;
  };

  if (!labels.length) {
    return null;
  }

  const onClick = () => {
    fetch({});
  };

  return (
    <StyledTrackingFilterPanelRow
      justify="space-between"
      align="middle"
      gutter={[0, 20]}
    >
      <Col xs={24} md={20}>
        <Row>
          <TrackingFilterPanelItem
            label={TrackingFilterPanelLabel.FILTERS}
            borderRight
            onFilterVisibilityChange={onFilterVisibilityChange}
            labels={labels}
          />
          {labels.map((label, index) => (
            <span key={label}>
              {hiddenLabels.find((el) => el === label) ? null : (
                <TrackingFilterPanelItem
                  label={label}
                  borderRight={hasBorderRight(index)}
                />
              )}
            </span>
          ))}
        </Row>
      </Col>
      <Col>
        <StyledButton type="default" onClick={onClick}>
          {t('applyFilters')}
        </StyledButton>
      </Col>
    </StyledTrackingFilterPanelRow>
  );
};

export default TrackingFilterPanel;
