import agent from './agent';
import { apiURLs } from './constants';

export const getStats = (employeeId: string) =>
  agent.get(`${apiURLs.employeeDashboard}/stats/${employeeId}`);

export const getAttendanceMatrixForEmployee = async (
  month: string,
  employeeId: string
) => {
  try {
    const response = await agent.get(
      `${apiURLs.employeeDashboard}/attendance/${employeeId}`,
      {
        params: {
          month,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(`Error fetching attendance matrix: ${error}`);
  }
};
