import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';

import { createAssignment, updateAssignment } from 'api/assignmentService';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { updateProject } from 'api/projectService';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import { fetchSingleProject } from 'redux/projects/actions';
import { AssignmentCreate, AssignmentUpdate } from 'types/Assigment';
import { EmployeeType } from 'types/Employee';
import { InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { convertDateToUTC, getUserDateFormat } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { Assignment } from 'types/Assignment';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';

interface IProps {
  open: boolean;
  closeModal: () => void;
  assignment?: Assignment;
}

export default function AssignEmployeeModal({
  open,
  closeModal,
  assignment,
}: IProps) {
  const [form] = Form.useForm();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const [loading, setLoading] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const {
    project: { data, projectId },
  } = useProjectsData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const { optionsType: rolesAssignmentOptions } = useGetSelectOptions({
    type: 'roles-assignment',
    transform: (item: any) => ({
      id: item.id,
      value: item.id,
      label: item.name,
    }),
  });

  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });

  const onSelect = useCallback(() => {
    setIsManager(!isManager);
  }, [isManager]);

  const AddWorkExperienceFormConfiguration: any[][] = useMemo(
    () => [
      assignment
        ? [
            {
              col: 24,
              offset: 0,
              name: 'rolesAssignment',
              label: t('role'),
              type: InputTypes.SELECT,
              selectOptions: rolesAssignmentOptions,
              defaultValue:
                assignment?.rolesAssignment?.id &&
                assignment?.rolesAssignment?.id,
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
          ]
        : [
            {
              col: 11,
              offset: 0,
              name: 'employeeId',
              label: t('employee'),
              type: InputTypes.SELECT_LOAD,
              fetchOptions: getEmployeeSkimSelectWithLoadOptions,
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
            {
              col: 11,
              offset: 2,
              name: 'rolesAssignment',
              label: t('role'),
              type: InputTypes.SELECT,
              selectOptions: rolesAssignmentOptions,
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
          ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: assignment?.startDate && moment(assignment?.startDate),
          rules: [
            {
              required: true,
              message: t('Start Date is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('endDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: assignment?.endDate && moment(assignment?.endDate),

          ...(!assignment?.startDate && {
            dateProps: {
              format: userDateFormat,
              showTime: false,
              disabledDate: (current: moment.Moment) =>
                moment(current).isSameOrBefore(form.getFieldValue('startDate')),
            },
          }),
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'employeeRate',
          label: t('employeeRate'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: assignment?.employeeRate && assignment?.employeeRate,
          inputProps: {
            type: 'number',
            rows: 1,
            min: 1,
          },
          prefix: {
            name: 'currecyEmployeeId',
            selectOptions: currencyOptions,
            defaultValue:
              assignment?.currecyEmployee?.id || currencyOptions[1]?.id,
            placeholder: '',
          },
          rules: [
            {
              required: true,
              message: t('employeeRateIsRequired!'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'clientRate',
          label: t('clientRate'),
          type: 'selectPrefix',
          defaultValue: assignment?.clientRate && assignment?.clientRate,
          inputProps: {
            type: 'number',
            rows: 1,
            min: 1,
          },
          prefix: {
            name: 'currecyClientId',
            selectOptions: currencyOptions,
            defaultValue:
              assignment?.currecyClient?.id || currencyOptions[1]?.id,
            placeholder: '',
          },
          rules: [
            {
              required: true,
              message: t('clientRateisRequired!'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'isManager',
          label: t('responsibilities'),
          type: InputTypes.CHECKBOX_GROUP,
          checkboxOptions: [
            {
              id: 'isManager',
              label: t('managerRole'),
              value: 'isManager',
            },
          ],
          checkedOptions: assignment?.isManager && [
            {
              id: 'isManager',
              label: t('managerRole'),
              value: 'isManager',
            },
          ],
          onSelect,
        },
      ],
    ],
    [rolesAssignmentOptions, currencyOptions, assignment, isManager]
  );

  const onSubmit = useCallback(() => {
    const formValues = form.getFieldsValue(true);
    const payload = {
      ...formValues,
      projectId,
      startDate: convertDateToUTC(formValues?.startDate),
      ...(formValues?.endDate && {
        endDate: convertDateToUTC(formValues?.endDate),
      }),
      clientRate: Number(formValues?.clientRate),
      employeeRate: Number(formValues?.employeeRate),
      isManager,
    };

    if (!assignment?.id) {
      if (!payload?.endDate) {
        delete payload?.endDate;
      }
      const createPayload: AssignmentCreate = {
        ...payload,
        employeeId: formValues.employeeId.key,
      };

      if (loading) return;

      const currentEmp: string[] =
        data?.employees?.map((el: EmployeeType) => el?.id) || [];

      const updateProjectPayload = {
        employees: [...currentEmp, createPayload.employeeId],
      };
      setLoading(true);
      createAssignment(createPayload)
        .then((res) => {
          if (res.status === 200) {
            updateProject(updateProjectPayload, projectId)
              .then((res) => {
                if (res.status === 200) {
                  toast.success(t('Employee successfully assigned'));
                }
              })
              .catch(() => {
                toast.error(t('Failed to assign employee to project'));
              });
          }
        })
        .catch(() => {
          toast.error(t('Failed to assign employee to project'));
        })
        .finally(() => {
          setLoading(false);
          dispatch(fetchSingleProject(projectId));
          closeModal();
        });
    } else {
      const updatePayload: AssignmentUpdate = {
        ...payload,
        employeeId: assignment?.employee?.id,
      };
      updateAssignment(assignment.id, updatePayload)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('Assignment successfully updated!'));
          }
        })
        .catch(() => {
          toast.error(t('Failed to update employee assignment'));
        })
        .finally(() => {
          setLoading(false);
          dispatch(fetchSingleProject(projectId));
          closeModal();
        });
    }
  }, [form, isManager]);

  return (
    <GenericModal
      title={assignment ? 'Edit Assignment' : t('assignEmployee')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
