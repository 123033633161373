import agent from 'api/agent';
import {
  FeatureFlagsTenantType,
  UpdateFeatureFlagsTenantDTO,
} from 'types/FeatureFlagsTenant';
import { apiURLs } from './constants';

export const getFeatureFlagsTenant = (id: string) =>
  agent.get(`${apiURLs.featureFlagsTenant}/${id}`);

export const createFeatureFlagsTenant = (payload: FeatureFlagsTenantType) =>
  agent.post(apiURLs.featureFlagsTenant, payload);

export const deleteFeatureFlagsTenant = (tenantId: string, featureId: string) =>
  agent.delete(`${apiURLs.featureFlagsTenant}/${tenantId}/${featureId}`);

export const updateFeatureFlagsTenants = (
  payload: UpdateFeatureFlagsTenantDTO
) => agent.patch(`${apiURLs.featureFlagsTenant}`, payload);
