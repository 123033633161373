import { Col, Row, Spin, Typography } from 'antd';
import { getHeatMap } from 'api/dashboardStats';
import React, { useState, useEffect } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';
import Select from 'components/NewForms/Select';
import { OptionType } from 'types/OptionTypes';
import { toastErrorMessages } from 'utils/utilFunctions';

interface HeatMapData {
  projectId: string;
  projectName: string;
  fullName: string;
  date: string;
  hoursSpent: string;
  status: string;
  count: string;
}

export enum SelectedData {
  CANDIDATES_STATUS = 'candidatesStatus',
  PROJECT_TRACKING_HOURS = 'projectTrackingHours',
  TRACKING_HOURS = 'trackingHours',
}

const HeatMapComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [heatMapData, setHeatMapData] = useState<HeatMapData[]>([]);
  const [selectedData, setSelectedData] = useState(SelectedData.TRACKING_HOURS);
  const selectOptions: OptionType[] = [
    {
      label: 'Candidates Status',
      id: 'candidatesStatus',
      value: 'candidatesStatus',
    },
    {
      label: 'Project Tracking Hours',
      id: 'projectTrackingHours',
      value: 'projectTrackingHours',
    },
    {
      label: 'Employee Tracking Hours',
      id: 'trackingHours',
      value: 'trackingHours',
    },
  ];

  async function fetchHeatMap() {
    setLoading(true);
    try {
      const { data } = await getHeatMap(selectedData);
      setHeatMapData(data);
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchHeatMap();
  }, [selectedData]);

  const processHeatMapData = (data: HeatMapData[]) => {
    const yAxisData = Array.from(
      new Set(data.map((d) => d.projectName || d.fullName || d.status))
    );
    const dates = Array.from(
      new Set(data.map((d) => new Date(d.date).toISOString().split('T')[0]))
    );

    const xLabels = dates;
    const yLabels = yAxisData;

    let heatmapData;
    if (selectedData === SelectedData.TRACKING_HOURS) {
      heatmapData = yLabels.map((employee) =>
        xLabels.map((date) => {
          const entry = data.find(
            (d) =>
              d.fullName === employee &&
              new Date(d.date).toISOString().split('T')[0] === date
          );
          return entry ? Number(entry.hoursSpent) : 0;
        })
      );
    } else if (selectedData === SelectedData.PROJECT_TRACKING_HOURS) {
      heatmapData = yLabels.map((project) =>
        xLabels.map((date) => {
          const entry = data.find(
            (d) =>
              d.projectName === project &&
              new Date(d.date).toISOString().split('T')[0] === date
          );
          return entry ? Number(entry.hoursSpent) : 0;
        })
      );
    } else {
      heatmapData = yLabels.map((candidate) =>
        xLabels.map((date) => {
          const entry = data.find(
            (d) =>
              d.status === candidate &&
              new Date(d.date).toISOString().split('T')[0] === date
          );
          return entry ? Number(entry.count) : 0;
        })
      );
    }

    return { xLabels, yLabels, data: heatmapData };
  };

  const { xLabels, yLabels, data } = processHeatMapData(heatMapData);

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (data.length === 0) {
      return (
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: 350,
          }}
        >
          <Col>
            <Typography.Text
              style={{
                display: 'block',
                textAlign: 'center',
              }}
            >
              {t('noDataYet.')}
            </Typography.Text>
            <Typography.Link
              style={{
                display: 'block',
                textAlign: 'center',
              }}
              href="employees/add-employee"
            >
              {t('addSomeEmployeesFirst!')}
            </Typography.Link>
          </Col>
        </Row>
      );
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <HeatMapGrid
          data={data}
          xLabels={xLabels.map((date) => {
            const d = new Date(date);
            return `${d.getMonth() + 1}/${d.getDate()}`;
          })}
          yLabels={yLabels}
          cellRender={(x, y, value) => (
            <div
              title={`Project: ${yLabels[y]}, Date: ${xLabels[x]} = ${value}`}
            >
              {value}
            </div>
          )}
          xLabelsStyle={() => ({
            color: '#777',
            fontSize: '.8rem',
            textAlign: 'center',
            transform: 'rotate(-45deg)',
            transformOrigin: 'top left',
            whiteSpace: 'wrap',
            marginTop: '1rem',
          })}
          yLabelsStyle={() => ({
            fontSize: '.7rem',
            textTransform: 'uppercase',
            color: '#777',
            whiteSpace: 'nowrap',
          })}
          cellStyle={(_x, _y, ratio) => ({
            background: `rgb(12, 160, 44, ${ratio})`,
            fontSize: '.8rem',
            color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
          })}
          cellHeight="2rem"
          xLabelsPos="bottom"
          yLabelsPos="left"
          square
        />
      </div>
    );
  };

  return (
    <CardItem title={t('heatMapAnalysis')} minHeight={450}>
      <>
        {selectedData && (
          <Col span={24}>
            <Row justify="center">
              <Col span={8}>
                <Select
                  placeholder={t('selectData')}
                  name="skill"
                  selectOptions={selectOptions}
                  hidePlaceholder
                  onSelect={setSelectedData}
                  showSearch
                  defaultValue={selectOptions[2].id}
                />
              </Col>
            </Row>
          </Col>
        )}

        <Spin spinning={loading}>{renderContent()}</Spin>
      </>
    </CardItem>
  );
};

export default HeatMapComponent;
