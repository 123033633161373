import { useSelector } from 'react-redux';

export function useAttendance() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.attendance
  );

  return {
    list: data?.data ?? [],
    xLabels: data?.xLabels,
    yLabels: data?.yLabels,
    loading,
    loaded,
    errored,
    error,
  };
}
