import { EmployeeType } from 'types/Employee';
import { getAllProjects, getEmployeeOwnProjects } from 'api/projectService';
import { ProjectType } from 'types/Project';
import { CandidateType } from 'types/Candidates';
import { OpenPositionType } from 'interfaces/OpenPosition';
import { getAllOpenPositionsforAdmin } from 'api/openPositions';
import { getAllCandidates } from 'api/candidateService';
import { getAllWorkPositions } from 'api/workPositionService';
import {
  getDepartmentEmployees,
  getDepartmentSelectOptions,
} from 'api/departmentService';
import { getAllEmploymentTypes } from 'api/employeeTypeService';
import { EmploymentType } from 'types/EmploymentType';
import { TrainingType } from 'types/Training';
import { getAllTrainings } from 'api/trainingService';
import { WorkPositiontModelType } from 'types/WorkPosition';
import { getAllAssets } from 'api/assetService';
import { AssetType } from 'types/Asset';
import { getAllOnBoardingTasks } from 'api/onBoardingTaskService';

import { getSkills, getSkillsForCategories } from 'api/skillService';
import { SkillSubCategory } from 'types/Skills';
import { findAllSkillSubCategorySelectOptions } from 'api/jobCategoryService';
import { getEmployeeSelectOptions, getApprovers } from 'api/employeeService';
import { getAssetCategoriesSelectOptions } from 'api/assetCategories';
import { getAllTenantLocations } from 'api/tenantLocationService';
import { TeanantLocation } from 'types/TenantLocation';
import { getAllCertifications } from 'api/certificationService';
import { SelectLoadPayload } from './SelectWithLoad';

export async function fetchEmployeeOptions(
  filterText: string,
  page?: number
): Promise<SelectLoadPayload> {
  return getEmployeeSelectOptions({
    filterOptions: {
      filterText,
      filterFields: ['firstName', 'lastName'],
    },
    pageOptions: { page: page || 1 },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchApproves(
  filterText: string,
  page?: number
): Promise<SelectLoadPayload> {
  return getApprovers({
    filterOptions: {
      filterText,
      filterFields: ['firstName', 'lastName'],
    },
    pageOptions: { page: page || 1 },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchTrainingSkillsOptions(
  filterText: string,
  page?: number
): Promise<SelectLoadPayload> {
  return findAllSkillSubCategorySelectOptions({
    filterOptions: {
      filterText,
      filterFields: ['subCategoryName'],
    },
    pageOptions: { page: page || 1 },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchEmployeeOptionsForDepartmend(
  dep: string
): Promise<SelectLoadPayload> {
  return getDepartmentEmployees(dep).then((response) => ({
    data:
      response.data?.data?.map((item: EmployeeType) => ({
        label: `${item?.firstName} ${item?.lastName}`,
        value: item?.id,
      })) ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchSubCategoriesForSkillCategory(
  dep: string
): Promise<SelectLoadPayload> {
  return getSkillsForCategories(dep).then((response) => ({
    data:
      response.data?.data?.map((item: SkillSubCategory) => ({
        label: item.subCategoryName,
        value: item?.id,
      })) ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchProjectOptions(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllProjects({ filterText, page }).then((response) => ({
    data: response.data?.data?.map((item: ProjectType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchEmployeeProjects() {
  return getEmployeeOwnProjects().then((response) => ({
    data: response.data?.map((item: ProjectType) => ({
      label: item?.name,
      value: item?.id,
    })),
  }));
}
export async function fetchOpenPositionOptions(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllOpenPositionsforAdmin({
    filterFields: ['name'],
    filterText,
    page,
  }).then((response: any) => ({
    data: response.data?.data?.map((item: OpenPositionType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}
export async function fetchCertifications(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllCertifications({
    filterFields: ['name'],
    filterText,
    page,
  }).then((response: any) => ({
    data: response.data?.data?.map((item: OpenPositionType) => ({
      key: item.id,
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchCandidateOptions(
  string: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllCandidates({ string, page }).then((response: any) => ({
    data: response.data?.data?.map((item: CandidateType) => ({
      label: `${item?.firstName} ${item.lastName}`,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchDepartmentsOptions(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getDepartmentSelectOptions({
    filterOptions: { filterText, filterFields: ['name'] },
    pageOptions: { page },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchWorkPositionsOptions(
  filterText: string
): Promise<SelectLoadPayload> {
  return getAllWorkPositions({
    filterOptions: {
      filterFields: ['name'],
      filterText,
    },
    pageOptions: { page: 1, take: 10 },
  }).then((response) => ({
    data: response.data?.data?.map((item: WorkPositiontModelType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchEmploymentTypeOptions(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllEmploymentTypes({
    filterFields: ['name'],
    filterText,
    page,
  }).then((response) => ({
    data: response.data?.data?.map((item: EmploymentType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchTrainingsOptions(
  string: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllTrainings({ string, page }).then((response) => ({
    data: response.data?.data?.map((item: TrainingType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchAssetOptions(
  string: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllAssets({ string, page }).then((response) => ({
    data: response.data?.data?.map((item: AssetType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchTenantLocationsOptions(): Promise<SelectLoadPayload> {
  return getAllTenantLocations().then((response) => ({
    data: response?.data?.map((item: TeanantLocation) => ({
      label: `${item?.city},${item?.country}`,
      value: item?.id,
      id: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchOnboardingTasks(
  string: string,
  page: number
): Promise<SelectLoadPayload> {
  return getAllOnBoardingTasks({ string, page }).then((response) => ({
    data: response.data?.data?.map((item: AssetType) => ({
      label: item?.name,
      value: item?.id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchSubCategorySkills(
  filterText: string,
  page?: number
): Promise<SelectLoadPayload> {
  return findAllSkillSubCategorySelectOptions({
    filterOptions: {
      filterText,
      filterFields: ['subCategoryName'],
    },
    pageOptions: { page: page || 1 },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchCategory(
  filterText: string,
  page: number
): Promise<SelectLoadPayload> {
  return getSkills({
    filterOptions: {
      filterText,
      filterFields: ['skills_category_categoryName'],
    },
    pageOptions: { page },
  }).then((response) => ({
    data: response.data?.data?.map((item: any) => ({
      label: item?.skills_category_categoryName,
      value: item?.skills_category_id,
    })),
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}

export async function fetchAssetCategoriesOptions(
  filterText: string
): Promise<SelectLoadPayload> {
  return getAssetCategoriesSelectOptions({
    filterOptions: { filterText, filterFields: ['categoryName'] },
  }).then((response) => ({
    data: response.data?.data ?? [],
    pageCount: response.data?.meta?.pageCount ?? 1,
  }));
}
