import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import { t } from 'i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const PurchaseRequestActions: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onEditClick = () => {
    searchParams.append('edit-purchase-request', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('edit')}>
                <IconButton onClick={onEditClick}>
                  <EdititingIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default PurchaseRequestActions;
