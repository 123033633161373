/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { Row, Space, Typography } from 'antd';

export const Container = styled(Space)<{
  disabled?: boolean;
  wrapContentHeight?: boolean;
}>`
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  ${({ disabled }) =>
    disabled && 'pointer-events: none; background: #89898a08;'};
  &.popover-card-container {
    box-shadow: none;
    text-transform: capitalize;
  }
  height: ${({ wrapContentHeight }) => (wrapContentHeight ? '' : '100%')};
`;
export const Title = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 600;
`;
export const SubTitle = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
`;
export const RowCardItem = styled(Row)`
  display: flex;
  align-items: center;
`;
export const Children = styled.div`
  min-height: ${({ minHeight }: { minHeight: number }) =>
    minHeight ? `${minHeight}px` : ''};
`;
