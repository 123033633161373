import React from 'react';
import { Link } from 'react-router-dom';
import { StyledDrawerItem } from './SideBarMobileStyles';

interface ItemProp {
  item: any;
  active: any;
  toggleDrawer: () => void;
}

export default function DrawerItem({ item, active, toggleDrawer }: ItemProp) {
  return (
    <StyledDrawerItem active={active}>
      <span>{item?.icon}</span>
      <Link to={item.path} style={{ color: 'white' }} onClick={toggleDrawer}>
        {item?.name}
      </Link>
    </StyledDrawerItem>
  );
}
