import { Dispatch } from 'redux';
import { AttendanceQueryParamsType } from 'types/QueryParams';
import { getAttendanceMatrix } from 'api/employeeAttendanceService';
import { Attendance } from './types';

export const fetchAttendance =
  (queryParams: AttendanceQueryParamsType) => (dispatch: Dispatch) => {
    dispatch({
      type: Attendance.FETCH_ATTENDANCE_START,
    });
    getAttendanceMatrix(queryParams)
      .then((res) => {
        dispatch({
          type: Attendance.FETCH_ATTENDANCE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Attendance.FETCH_ATTENDANCE_ERRORED,
          payload: e,
        });
      });
  };
