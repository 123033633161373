import GenericForm from 'components/NewForms/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType } from 'types/FormTypes';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { BankDetailsCreate } from 'types/BankDetails';
import { useForm } from 'antd/lib/form/Form';
import CardItem from 'ui-v2/components/Card';
import { createBankDetails } from 'api/bankDetails';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';

type BankDetailsProps = {
  employeeId: string;
};

export default function BankDetails({ employeeId }: BankDetailsProps) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { optionsType: currencyOptions } = useGetSelectOptions({
    type: 'currency',
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (searchParams.get('bank-details-save') === 'true') {
      form.submit();
      searchParams.delete('bank-details-save');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues: BankDetailsCreate = form.getFieldsValue(true);

    const valuesToSend = {
      ...formValues,
      employeeId,
    };
    await createBankDetails(valuesToSend)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setLoading(false);
          toast.success('Bank details added successfully');
          searchParams.append('bank-detail-completed', 'true');
          setSearchParams(searchParams);
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => setLoading(false));
  };
  const AddBankDetailsFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 8,
          offset: 0,
          name: 'bankName',
          label: t('bankName'),
          type: 'input',
          rules: [
            {
              required: true,
              message: 'Bank name is required',
            },
          ],
        },
        {
          col: 8,
          offset: 2,
          name: 'bankAddress',
          label: t('bankAddress'),
          type: 'input',
          rules: [
            {
              required: true,
              message: 'Bank address is required',
            },
          ],
        },
        {
          col: 4,
          offset: 2,
          name: 'currencyId',
          label: t('currency'),
          type: 'select',
          selectOptions: currencyOptions,
          defaultValue: currencyOptions[1]?.id,
          rules: [
            {
              required: true,
              message: 'Currency is required',
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'iban',
          label: 'IBAN',
          type: 'input',
          rules: [
            {
              required: true,
              message: 'IBAN is required',
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'swift',
          label: 'SWIFT',
          type: 'input',
          rules: [
            {
              required: true,
              message: 'SWIFT is required',
            },
          ],
        },
      ],
    ],
    [currencyOptions]
  );

  return (
    <CardItem title="Here you can enter your bank details.">
      <GenericForm
        formConfiguration={AddBankDetailsFormConfiguration}
        onFinish={onSubmit}
        form={form}
      />
    </CardItem>
  );
}
