import React, { useEffect, useState } from 'react';
import { Row, Col, Calendar, TimePicker, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store';
import SelectComponents from 'components/NewForms/Select/Select';
import { InterviewNames, InterviewType } from 'types/Interview';
import { getInterviewTypeOrder } from 'api/interviewTypeService';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import DebounceSelect from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { OptionType } from 'types/OptionTypes';
import CustomCalendarHeader from 'ui-v2/components/TimeOffCalendarAdmin/CustomCalendarHeader';
import { StyledCalendarHeader } from 'ui-v2/components/TimeOffCalendarAdmin/CellRender/CellRenderStyles';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import * as Styled from '../CalendarPopoverStyles';

interface Props {
  offboarding?: boolean;
  onTimeVisible: () => void;
  onCancel: () => void;
  onSchedule: ({
    date,
    employee,
    interviewType,
  }: {
    date: Moment;
    employee: string[];
    interviewType: string;
  }) => void;
}

export default function CustomCalendar({
  onTimeVisible,
  onCancel,
  onSchedule,
  offboarding,
}: Props) {
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const { selectedCandidateOpenPosition } = useCandidateData();
  const { data: offboardingData } = useOffboardingData();
  const [interviewTypes, setInterviewTypes] = useState<Array<OptionType>>([]);
  const [interviewType, setInterviewType] = useState<string | undefined>(
    undefined
  );
  const [interviewDate, setInterviewDate] = useState<any>();
  const [interviewTime, setInterviewTime] = useState<any>();
  const [employee, setEmployee] = useState<Array<string>>([]);
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());
  const { t } = useTranslation();

  const { loading } = useSelector(
    (state: RootState) => state.scheduleInterview
  );
  useEffect(() => {
    if (!selectedCandidateOpenPosition.data?.id && !offboardingData?.id) return;

    getInterviewTypeOrder({
      candidateOpenPositionId: selectedCandidateOpenPosition?.data?.id,
    })
      .then((res) => {
        setInterviewTypes(
          res.data.map((item: InterviewType) => ({
            id: item.id,
            value: item.id,
            label: item.name ? t(item.name) : '',
          }))
        );

        if (offboardingData?.id) {
          setInterviewType(
            offboarding
              ? (res?.data?.find(
                  (el: { name: string }) => el.name === 'Exit interview'
                )?.id as string)
              : undefined
          );
        }
      })
      .catch(() => {
        toast.warning('Failed to get Interview types!');
      });
  }, [selectedCandidateOpenPosition.data?.id, offboardingData?.id]);

  useEffect(() => {
    if (interviewDate) onTimeVisible();
  }, [interviewDate]);

  const onCancelClick = () => {
    setInterviewDate('');
    onCancel();
  };

  useEffect(() => {
    const footer = Array.from(
      document.getElementsByClassName(
        'ant-picker-footer'
      ) as HTMLCollectionOf<HTMLElement>
    )[0];
    if (footer) {
      footer.style.display = 'none';
    }
    const popup = Array.from(
      document.getElementsByClassName(
        'ant-picker-panel-container'
      ) as HTMLCollectionOf<HTMLElement>
    )[0];
    if (popup) {
      popup.style.boxShadow = 'none';
    }
  }, [interviewDate]);

  const onScheduleClick = () => {
    setInterviewDate('');

    if (employee.length && interviewType) {
      onSchedule({
        date: moment(interviewDate)
          .local()
          .set({
            hour: moment(interviewTime, 'HH:MM:ss').get('hour'),
            minute: moment(interviewTime, 'HH:MM:ss').get('minute'),
            second: moment(interviewTime, 'HH:MM:ss').get('second'),
          }),
        employee,
        interviewType,
      });
    }
  };

  const setEmployeeForInterview = (newEmployee: OptionType) => {
    setEmployee((employee: string[]) => [...employee, newEmployee?.value]);
  };

  return (
    <Styled.Container>
      <Styled.RowItem height={270}>
        <Col span={interviewDate ? 15 : 24}>
          <Calendar
            fullscreen={false}
            value={interviewDate || moment().month(selectedMonth)}
            onSelect={setInterviewDate}
            disabledDate={(current) =>
              moment().add(-1, 'days') >= current ||
              moment().add(12, 'M') <= current
            }
            headerRender={() => (
              <StyledCalendarHeader>
                <CustomCalendarHeader
                  value={moment().year(selectedYear).month(selectedMonth)}
                  onSelectMonth={setSelectedMonth}
                  onSelectYear={setSelectedYear}
                />
              </StyledCalendarHeader>
            )}
          />
        </Col>
        {interviewDate && (
          <Styled.FullHeight span={9}>
            <Styled.TimeWrapper>
              <Col span={24}>
                <Row justify="center">
                  <TimePicker
                    open
                    value={interviewTime}
                    onSelect={setInterviewTime}
                    showNow={false}
                  />
                </Row>
              </Col>
            </Styled.TimeWrapper>
          </Styled.FullHeight>
        )}
      </Styled.RowItem>

      <Styled.RowItem
        height={60}
        style={{
          zIndex: 1000,
        }}
      >
        <Col span={offboarding ? 24 : 11}>
          <DebounceSelect
            placeholder={t('employees')}
            hidePlaceholder
            fetchOptions={getEmployeeSkimSelectWithLoadOptions}
            name="employees"
            disabled={!interviewDate || !interviewTime}
            onSelect={setEmployeeForInterview}
            mode="multiple"
          />
        </Col>

        {!offboarding && (
          <Col span={11} offset={2}>
            <SelectComponents
              placeholder={t('interviewType')}
              hidePlaceholder
              name="interviewType"
              selectOptions={interviewTypes.filter(
                (el) => el?.label !== InterviewNames.EXIT_INTERVIEW
              )}
              defaultValue={interviewType}
              disabled={!interviewDate || !interviewTime || !employee}
              onSelect={setInterviewType}
            />
          </Col>
        )}
      </Styled.RowItem>

      {loading ? (
        <Styled.Bottom>
          <Spin />
        </Styled.Bottom>
      ) : (
        <Styled.Bottom>
          <Col span={11}>
            <Styled.Button onClick={onCancelClick} type="default">
              {t('cancel')}
            </Styled.Button>
          </Col>
          <Col span={11} offset={2}>
            <Styled.Button
              onClick={onScheduleClick}
              disabled={
                !interviewDate || !interviewTime || !employee || !interviewType
              }
              type="primary"
            >
              {t('schedule')}
            </Styled.Button>
          </Col>
        </Styled.Bottom>
      )}
    </Styled.Container>
  );
}
