import { useSelector } from 'react-redux';

export function useGoalData() {
  const {
    data = {},
    loading,
    loaded,
    errored,
    error,
  } = useSelector((state: any) => state.goal);

  return {
    list: data?.data || [],
    pagination: data?.meta || {},
    loading,
    loaded,
    errored,
    error,
  };
}
