/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row, Tag } from 'antd';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import * as Styled from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import { TimeOff, TimeOffStatus } from 'types/TimeOff';
import moment from 'moment';

export default function ProjectTimeOffs() {
  const { t } = useTranslation();
  const {
    project: { data },
  } = useProjectsData();

  const getStatusColor = (status: TimeOffStatus) => {
    switch (status) {
      case TimeOffStatus.APPROVED:
        return 'success';
      case TimeOffStatus.REJECTED:
        return 'error';
      case TimeOffStatus.PENDING:
        return 'warning';
      default:
        return 'default';
    }
  };

  const formatDateRange = (dates: string) => {
    if (!dates) return 'N/A';
    try {
      const parsedDates = JSON.parse(dates);
      if (!parsedDates || !parsedDates.length) return 'N/A';

      const startDate = moment(parsedDates[0].date);
      const endDate = moment(parsedDates[parsedDates.length - 1].date);
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
    } catch (error) {
      return 'N/A';
    }
  };

  if (!data?.id) return null;

  return (
    <CardItem title={t('timeOffSchedule')}>
      <>
        <Row gutter={[0, 24]}>
          {!data?.employeesWithTimeOff?.length && (
            <>
              <Styled.RowItem justify="center" last>
                <Col>
                  <Styled.NodataLabel>
                    {t('timeOffNotFound')}
                  </Styled.NodataLabel>
                </Col>
              </Styled.RowItem>
            </>
          )}
          {data?.employeesWithTimeOff?.map((timeOff: TimeOff) => (
            <Styled.RowItem
              key={timeOff.employeeId}
              last
              gutter={[0, 10]}
              align="middle"
            >
              <Col span={6}>
                <span>{timeOff?.employee?.firstName}</span>
                <span style={{ marginLeft: '4px' }}>
                  {timeOff?.employee?.lastName}
                </span>
              </Col>
              <Col span={10} style={{ textAlign: 'center' }}>
                {formatDateRange(timeOff.dates)}
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Tag
                  color={getStatusColor(timeOff.aprovalStatus)}
                  style={{ borderRadius: '8px' }}
                >
                  {timeOff.aprovalStatus}
                </Tag>
              </Col>
            </Styled.RowItem>
          ))}
        </Row>
      </>
    </CardItem>
  );
}
