import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import EyeIcon from 'Icons/EyeIcon';

export const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
const AssetActions: React.FC<Id> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onViewClick = () => {
    navigate(`asset-details?id=${id}`);
  };

  const onViewAssetLogs = () => {
    navigate(`asset-logs?id=${id}`);
  };

  const onEditClick = () => {
    navigate(`edit-asset?id=${id}`);
  };

  const deleteAssetById = () => {
    searchParams.append('delete-asset', id);
    setSearchParams(searchParams);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.VIEW_ASSET_LOG}>
            <Col>
              <Tooltip title={t('view')}>
                <IconButton onClick={onViewAssetLogs}>
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <Col>
            <Tooltip title={t('details')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editAsset')}>
                <IconButton onClick={onEditClick}>
                  <EdititingIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Popconfirm
              placement="topLeft"
              title={t('areYouSureYouWanttoDelete')}
              onConfirm={deleteAssetById}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default AssetActions;
