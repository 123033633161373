export const prefixSelector = [
  {
    id: '+1',
    value: '+1',
    label: '+1 US',
    country: 'US',
    flag: 'https://flagcdn.com/us.svg',
  },
  {
    id: '+7',
    value: '+7',
    label: '+7 RU',
    country: 'RU',
    flag: 'https://flagcdn.com/ru.svg',
  },
  {
    id: '+20',
    value: '+20',
    label: '+20 EG',
    country: 'EG',
    flag: 'https://flagcdn.com/eg.svg',
  },
  {
    id: '+30',
    value: '+30',
    label: '+30 GR',
    country: 'GR',
    flag: 'https://flagcdn.com/gr.svg',
  },
  {
    id: '+31',
    value: '+31',
    label: '+31 NL',
    country: 'NL',
    flag: 'https://flagcdn.com/nl.svg',
  },
  {
    id: '+32',
    value: '+32',
    label: '+32 BE',
    country: 'BE',
    flag: 'https://flagcdn.com/be.svg',
  },
  {
    id: '+33',
    value: '+33',
    label: '+33 FR',
    country: 'FR',
    flag: 'https://flagcdn.com/fr.svg',
  },
  {
    id: '+34',
    value: '+34',
    label: '+34 ES',
    country: 'ES',
    flag: 'https://flagcdn.com/es.svg',
  },
  {
    id: '+36',
    value: '+36',
    label: '+36 HU',
    country: 'HU',
    flag: 'https://flagcdn.com/hu.svg',
  },
  {
    id: '+39',
    value: '+39',
    label: '+39 IT',
    country: 'IT',
    flag: 'https://flagcdn.com/it.svg',
  },
  {
    id: '+40',
    value: '+40',
    label: '+40 RO',
    country: 'RO',
    flag: 'https://flagcdn.com/ro.svg',
  },
  {
    id: '+41',
    value: '+41',
    label: '+41 CH',
    country: 'CH',
    flag: 'https://flagcdn.com/ch.svg',
  },
  {
    id: '+44',
    value: '+44',
    label: '+44 GB',
    country: 'GB',
    flag: 'https://flagcdn.com/gb.svg',
  },
  {
    id: '+45',
    value: '+45',
    label: '+45 DK',
    country: 'DK',
    flag: 'https://flagcdn.com/dk.svg',
  },
  {
    id: '+46',
    value: '+46',
    label: '+46 SE',
    country: 'SE',
    flag: 'https://flagcdn.com/se.svg',
  },
  {
    id: '+47',
    value: '+47',
    label: '+47 NO',
    country: 'NO',
    flag: 'https://flagcdn.com/no.svg',
  },
  {
    id: '+48',
    value: '+48',
    label: '+48 PL',
    country: 'PL',
    flag: 'https://flagcdn.com/pl.svg',
  },
  {
    id: '+49',
    value: '+49',
    label: '+49 DE',
    country: 'DE',
    flag: 'https://flagcdn.com/de.svg',
  },
  {
    id: '+51',
    value: '+51',
    label: '+51 PE',
    country: 'PE',
    flag: 'https://flagcdn.com/pe.svg',
  },
  {
    id: '+52',
    value: '+52',
    label: '+52 MX',
    country: 'MX',
    flag: 'https://flagcdn.com/mx.svg',
  },
  {
    id: '+53',
    value: '+53',
    label: '+53 CU',
    country: 'CU',
    flag: 'https://flagcdn.com/cu.svg',
  },
  {
    id: '+54',
    value: '+54',
    label: '+54 AR',
    country: 'AR',
    flag: 'https://flagcdn.com/ar.svg',
  },
  {
    id: '+55',
    value: '+55',
    label: '+55 BR',
    country: 'BR',
    flag: 'https://flagcdn.com/br.svg',
  },
  {
    id: '+56',
    value: '+56',
    label: '+56 CL',
    country: 'CL',
    flag: 'https://flagcdn.com/cl.svg',
  },
  {
    id: '+57',
    value: '+57',
    label: '+57 CO',
    country: 'CO',
    flag: 'https://flagcdn.com/co.svg',
  },
  {
    id: '+58',
    value: '+58',
    label: '+58 VE',
    country: 'VE',
    flag: 'https://flagcdn.com/ve.svg',
  },
  {
    id: '+60',
    value: '+60',
    label: '+60 MY',
    country: 'MY',
    flag: 'https://flagcdn.com/my.svg',
  },
  {
    id: '+61',
    value: '+61',
    label: '+61 AU',
    country: 'AU',
    flag: 'https://flagcdn.com/au.svg',
  },
  {
    id: '+62',
    value: '+62',
    label: '+62 ID',
    country: 'ID',
    flag: 'https://flagcdn.com/id.svg',
  },
  {
    id: '+63',
    value: '+63',
    label: '+63 PH',
    country: 'PH',
    flag: 'https://flagcdn.com/ph.svg',
  },
  {
    id: '+64',
    value: '+64',
    label: '+64 NZ',
    country: 'NZ',
    flag: 'https://flagcdn.com/nz.svg',
  },
  {
    id: '+65',
    value: '+65',
    label: '+65 SG',
    country: 'SG',
    flag: 'https://flagcdn.com/sg.svg',
  },
  {
    id: '+66',
    value: '+66',
    label: '+66 TH',
    country: 'TH',
    flag: 'https://flagcdn.com/th.svg',
  },
  {
    id: '+81',
    value: '+81',
    label: '+81 JP',
    country: 'JP',
    flag: 'https://flagcdn.com/jp.svg',
  },
  {
    id: '+82',
    value: '+82',
    label: '+82 KR',
    country: 'KR',
    flag: 'https://flagcdn.com/kr.svg',
  },
  {
    id: '+84',
    value: '+84',
    label: '+84 VN',
    country: 'VN',
    flag: 'https://flagcdn.com/vn.svg',
  },
  {
    id: '+86',
    value: '+86',
    label: '+86 CN',
    country: 'CN',
    flag: 'https://flagcdn.com/cn.svg',
  },
  {
    id: '+90',
    value: '+90',
    label: '+90 TR',
    country: 'TR',
    flag: 'https://flagcdn.com/tr.svg',
  },
  {
    id: '+91',
    value: '+91',
    label: '+91 IN',
    country: 'IN',
    flag: 'https://flagcdn.com/in.svg',
  },
  {
    id: '+92',
    value: '+92',
    label: '+92 PK',
    country: 'PK',
    flag: 'https://flagcdn.com/pk.svg',
  },
  {
    id: '+93',
    value: '+93',
    label: '+93 AF',
    country: 'AF',
    flag: 'https://flagcdn.com/af.svg',
  },
  {
    id: '+94',
    value: '+94',
    label: '+94 LK',
    country: 'LK',
    flag: 'https://flagcdn.com/lk.svg',
  },
  {
    id: '+95',
    value: '+95',
    label: '+95 MM',
    country: 'MM',
    flag: 'https://flagcdn.com/mm.svg',
  },
  {
    id: '+98',
    value: '+98',
    label: '+98 IR',
    country: 'IR',
    flag: 'https://flagcdn.com/ir.svg',
  },
  {
    id: '+351',
    value: '+351',
    label: '+351 PT',
    country: 'PT',
    flag: 'https://flagcdn.com/pt.svg',
  },
  {
    id: '+355',
    value: '+355',
    label: '+355 AL',
    country: 'AL',
    flag: 'https://flagcdn.com/al.svg',
  },
  {
    id: '+356',
    value: '+356',
    label: '+356 MT',
    country: 'MT',
    flag: 'https://flagcdn.com/mt.svg',
  },
  {
    id: '+357',
    value: '+357',
    label: '+357 CY',
    country: 'CY',
    flag: 'https://flagcdn.com/cy.svg',
  },
  {
    id: '+358',
    value: '+358',
    label: '+358 FI',
    country: 'FI',
    flag: 'https://flagcdn.com/fi.svg',
  },
  {
    id: '+359',
    value: '+359',
    label: '+359 BG',
    country: 'BG',
    flag: 'https://flagcdn.com/bg.svg',
  },
  {
    id: '+370',
    value: '+370',
    label: '+370 LT',
    country: 'LT',
    flag: 'https://flagcdn.com/lt.svg',
  },
  {
    id: '+371',
    value: '+371',
    label: '+371 LV',
    country: 'LV',
    flag: 'https://flagcdn.com/lv.svg',
  },
  {
    id: '+372',
    value: '+372',
    label: '+372 EE',
    country: 'EE',
    flag: 'https://flagcdn.com/ee.svg',
  },
  {
    id: '+373',
    value: '+373',
    label: '+373 MD',
    country: 'MD',
    flag: 'https://flagcdn.com/md.svg',
  },
  {
    id: '+374',
    value: '+374',
    label: '+374 AM',
    country: 'AM',
    flag: 'https://flagcdn.com/am.svg',
  },
  {
    id: '+375',
    value: '+375',
    label: '+375 BY',
    country: 'BY',
    flag: 'https://flagcdn.com/by.svg',
  },
  {
    id: '+380',
    value: '+380',
    label: '+380 UA',
    country: 'UA',
    flag: 'https://flagcdn.com/ua.svg',
  },
  {
    id: '+381',
    value: '+381',
    label: '+381 RS',
    country: 'RS',
    flag: 'https://flagcdn.com/rs.svg',
  },
  {
    id: '+382',
    value: '+382',
    label: '+382 ME',
    country: 'ME',
    flag: 'https://flagcdn.com/me.svg',
  },
  {
    id: '+385',
    value: '+385',
    label: '+385 HR',
    country: 'HR',
    flag: 'https://flagcdn.com/hr.svg',
  },
  {
    id: '+386',
    value: '+386',
    label: '+386 SI',
    country: 'SI',
    flag: 'https://flagcdn.com/si.svg',
  },
  {
    id: '+387',
    value: '+387',
    label: '+387 BA',
    country: 'BA',
    flag: 'https://flagcdn.com/ba.svg',
  },
  {
    id: '+389',
    value: '+389',
    label: '+389 MK',
    country: 'MK',
    flag: 'https://flagcdn.com/mk.svg',
  },
  {
    id: '+420',
    value: '+420',
    label: '+420 CZ',
    country: 'CZ',
    flag: 'https://flagcdn.com/cz.svg',
  },
  {
    id: '+421',
    value: '+421',
    label: '+421 SK',
    country: 'SK',
    flag: 'https://flagcdn.com/sk.svg',
  },
  {
    id: '+423',
    value: '+423',
    label: '+423 LI',
    country: 'LI',
    flag: 'https://flagcdn.com/li.svg',
  },
  {
    id: '+500',
    value: '+500',
    label: '+500 FK',
    country: 'FK',
    flag: 'https://flagcdn.com/fk.svg',
  },
  {
    id: '+501',
    value: '+501',
    label: '+501 BZ',
    country: 'BZ',
    flag: 'https://flagcdn.com/bz.svg',
  },
  {
    id: '+502',
    value: '+502',
    label: '+502 GT',
    country: 'GT',
    flag: 'https://flagcdn.com/gt.svg',
  },
  {
    id: '+503',
    value: '+503',
    label: '+503 SV',
    country: 'SV',
    flag: 'https://flagcdn.com/sv.svg',
  },
  {
    id: '+504',
    value: '+504',
    label: '+504 HN',
    country: 'HN',
    flag: 'https://flagcdn.com/hn.svg',
  },
  {
    id: '+505',
    value: '+505',
    label: '+505 NI',
    country: 'NI',
    flag: 'https://flagcdn.com/ni.svg',
  },
  {
    id: '+506',
    value: '+506',
    label: '+506 CR',
    country: 'CR',
    flag: 'https://flagcdn.com/cr.svg',
  },
  {
    id: '+507',
    value: '+507',
    label: '+507 PA',
    country: 'PA',
    flag: 'https://flagcdn.com/pa.svg',
  },
  {
    id: '+508',
    value: '+508',
    label: '+508 PM',
    country: 'PM',
    flag: 'https://flagcdn.com/pm.svg',
  },
  {
    id: '+509',
    value: '+509',
    label: '+509 HT',
    country: 'HT',
    flag: 'https://flagcdn.com/ht.svg',
  },
  {
    id: '+590',
    value: '+590',
    label: '+590 GP',
    country: 'GP',
    flag: 'https://flagcdn.com/gp.svg',
  }, // Guadeloupe
  {
    id: '+591',
    value: '+591',
    label: '+591 BO',
    country: 'BO',
    flag: 'https://flagcdn.com/bo.svg',
  }, // Bolivia
  {
    id: '+592',
    value: '+592',
    label: '+592 GY',
    country: 'GY',
    flag: 'https://flagcdn.com/gy.svg',
  }, // Guyana
  {
    id: '+593',
    value: '+593',
    label: '+593 EC',
    country: 'EC',
    flag: 'https://flagcdn.com/ec.svg',
  }, // Ecuador
  {
    id: '+595',
    value: '+595',
    label: '+595 PY',
    country: 'PY',
    flag: 'https://flagcdn.com/py.svg',
  }, // Paraguay
  {
    id: '+597',
    value: '+597',
    label: '+597 SR',
    country: 'SR',
    flag: 'https://flagcdn.com/sr.svg',
  }, // Suriname
  {
    id: '+598',
    value: '+598',
    label: '+598 UY',
    country: 'UY',
    flag: 'https://flagcdn.com/uy.svg',
  }, // Uruguay
  {
    id: '+670',
    value: '+670',
    label: '+670 TL',
    country: 'TL',
    flag: 'https://flagcdn.com/tl.svg',
  }, // East Timor
  {
    id: '+672',
    value: '+672',
    label: '+672 AQ',
    country: 'AQ',
    flag: 'https://flagcdn.com/aq.svg',
  }, // Antarctica
  {
    id: '+673',
    value: '+673',
    label: '+673 BN',
    country: 'BN',
    flag: 'https://flagcdn.com/bn.svg',
  }, // Brunei
  {
    id: '+674',
    value: '+674',
    label: '+674  NR',
    country: 'NR',
    flag: 'https://flagcdn.com/nr.svg',
  }, // Nauru
  {
    id: '+675',
    value: '+675',
    label: '+675 PG',
    country: 'PG',
    flag: 'https://flagcdn.com/pg.svg',
  }, // Papua New Guinea
  {
    id: '+676',
    value: '+676',
    label: '+676 TO',
    country: 'TO',
    flag: 'https://flagcdn.com/to.svg',
  }, // Tonga
  {
    id: '+677',
    value: '+677',
    label: '+677 SB',
    country: 'SB',
    flag: 'https://flagcdn.com/sb.svg',
  }, // Solomon Islands
  {
    id: '+678',
    value: '+678',
    label: '+678 VU',
    country: 'VU',
    flag: 'https://flagcdn.com/vu.svg',
  }, // Vanuatu
  {
    id: '+679',
    value: '+679',
    label: '+679 FJ',
    country: 'FJ',
    flag: 'https://flagcdn.com/fj.svg',
  }, // Fiji
  {
    id: '+680',
    value: '+680',
    label: '+680 PW',
    country: 'PW',
    flag: 'https://flagcdn.com/pw.svg',
  }, // Palau
  {
    id: '+681',
    value: '+681',
    label: '+681 WF',
    country: 'WF',
    flag: 'https://flagcdn.com/wf.svg',
  }, // Wallis and Futuna
  {
    id: '+682',
    value: '+682',
    label: '+682 CK',
    country: 'CK',
    flag: 'https://flagcdn.com/ck.svg',
  }, // Cook Islands
  {
    id: '+683',
    value: '+683',
    label: '+683 NU',
    country: 'NU',
    flag: 'https://flagcdn.com/nu.svg',
  }, // Niue
  {
    id: '+685',
    value: '+685',
    label: '+685  WS',
    country: 'WS',
    flag: 'https://flagcdn.com/ws.svg',
  }, // Samoa
  {
    id: '+686',
    value: '+686',
    label: '+686 KI',
    country: 'KI',
    flag: 'https://flagcdn.com/ki.svg',
  }, // Kiribati
  {
    id: '+687',
    value: '+687',
    label: '+687 NC',
    country: 'NC',
    flag: 'https://flagcdn.com/nc.svg',
  }, // New Caledonia
  {
    id: '+688',
    value: '+688',
    label: '+688 TV',
    country: 'TV',
    flag: 'https://flagcdn.com/tv.svg',
  }, // Tuvalu
  {
    id: '+689',
    value: '+689',
    label: '+689 PF',
    country: 'PF',
    flag: 'https://flagcdn.com/pf.svg',
  }, // French Polynesia
  {
    id: '+690',
    value: '+690',
    label: '+690  TK',
    country: 'TK',
    flag: 'https://flagcdn.com/tk.svg',
  }, // Tokelau
  {
    id: '+691',
    value: '+691',
    label: '+691  FM',
    country: 'FM',
    flag: 'https://flagcdn.com/fm.svg',
  }, // Micronesia
  {
    id: '+692',
    value: '+692',
    label: '+692  MH',
    country: 'MH',
    flag: 'https://flagcdn.com/mh.svg',
  }, // Marshall Islands
  {
    id: '+850',
    value: '+850',
    label: '+850  KP',
    country: 'KP',
    flag: 'https://flagcdn.com/kp.svg',
  }, // North Korea
  {
    id: '+852',
    value: '+852',
    label: '+852 HK',
    country: 'HK',
    flag: 'https://flagcdn.com/hk.svg',
  }, // Hong Kong
  {
    id: '+853',
    value: '+853',
    label: '+853 MO',
    country: 'MO',
    flag: 'https://flagcdn.com/mo.svg',
  }, // Macau
  {
    id: '+855',
    value: '+855',
    label: '+855 KH',
    country: 'KH',
    flag: 'https://flagcdn.com/kh.svg',
  }, // Cambodia
  {
    id: '+856',
    value: '+856',
    label: '+856 LA',
    country: 'LA',
    flag: 'https://flagcdn.com/la.svg',
  }, // Laos
  {
    id: '+880',
    value: '+880',
    label: '+880 BD',
    country: 'BD',
    flag: 'https://flagcdn.com/bd.svg',
  }, // Bangladesh
  {
    id: '+960',
    value: '+960',
    label: '+960 MV',
    country: 'MV',
    flag: 'https://flagcdn.com/mv.svg',
  }, // Maldives
  {
    id: '+961',
    value: '+961',
    label: '+961 LB',
    country: 'LB',
    flag: 'https://flagcdn.com/lb.svg',
  }, // Lebanon
  {
    id: '+962',
    value: '+962',
    label: '+962 JO',
    country: 'JO',
    flag: 'https://flagcdn.com/jo.svg',
  }, // Jordan
  {
    id: '+963',
    value: '+963',
    label: '+963 SY',
    country: 'SY',
    flag: 'https://flagcdn.com/sy.svg',
  }, // Syria
  {
    id: '+964',
    value: '+964',
    label: '+964  IQ',
    country: 'IQ',
    flag: 'https://flagcdn.com/iq.svg',
  }, // Iraq
  {
    id: '+965',
    value: '+965',
    label: '+965  KW',
    country: 'KW',
    flag: 'https://flagcdn.com/kw.svg',
  }, // Kuwait
  {
    id: '+966',
    value: '+966',
    label: '+966  SA',
    country: 'SA',
    flag: 'https://flagcdn.com/sa.svg',
  }, // Saudi Arabia
  {
    id: '+967',
    value: '+967',
    label: '+967  YE',
    country: 'YE',
    flag: 'https://flagcdn.com/ye.svg',
  }, // Yemen
  {
    id: '+968',
    value: '+968',
    label: '+968 OM',
    country: 'OM',
    flag: 'https://flagcdn.com/om.svg',
  }, // Oman
  {
    id: '+971',
    value: '+971',
    label: '+971 AE',
    country: 'AE',
    flag: 'https://flagcdn.com/ae.svg',
  }, // United Arab Emirates
  {
    id: '+972',
    value: '+972',
    label: '+972 IL',
    country: 'IL',
    flag: 'https://flagcdn.com/il.svg',
  }, // Israel
  {
    id: '+973',
    value: '+973',
    label: '+973 BH',
    country: 'BH',
    flag: 'https://flagcdn.com/bh.svg',
  }, // Bahrain
  {
    id: '+974',
    value: '+974',
    label: '+974 QA',
    country: 'QA',
    flag: 'https://flagcdn.com/qa.svg',
  }, // Qatar
  {
    id: '+975',
    value: '+975',
    label: '+975 BT',
    country: 'BT',
    flag: 'https://flagcdn.com/bt.svg',
  }, // Bhutan
  {
    id: '+976',
    value: '+976',
    label: '+976 MN',
    country: 'MN',
    flag: 'https://flagcdn.com/mn.svg',
  }, // Mongolia
  {
    id: '+977',
    value: '+977',
    label: '+977 NP',
    country: 'NP',
    flag: 'https://flagcdn.com/np.svg',
  }, // Nepal
  {
    id: '+992',
    value: '+992',
    label: '+992 TJ',
    country: 'TJ',
    flag: 'https://flagcdn.com/tj.svg',
  }, // Tajikistan
  {
    id: '+993',
    value: '+993',
    label: '+993 TM',
    country: 'TM',
    flag: 'https://flagcdn.com/tm.svg',
  }, // Turkmenistan
  {
    id: '+994',
    value: '+994',
    label: '+994 AZ',
    country: 'AZ',
    flag: 'https://flagcdn.com/az.svg',
  }, // Azerbaijan
  {
    id: '+995',
    value: '+995',
    label: '+995 GE',
    country: 'GE',
    flag: 'https://flagcdn.com/ge.svg',
  }, // Georgia
  {
    id: '+996',
    value: '+996',
    label: '+996 KG',
    country: 'KG',
    flag: 'https://flagcdn.com/kg.svg',
  }, // Kyrgyzstan
  {
    id: '+998',
    value: '+998',
    label: '+998 UZ',
    country: 'UZ',
    flag: 'https://flagcdn.com/uz.svg',
  }, // Uzbekistan
];
export const phoneNumberValidator = (_: any, value: string) => {
  if (value && !/^[0-9]+$/.test(value)) {
    return Promise.reject(new Error('Please enter only numbers'));
  }
  return Promise.resolve();
};
