import { Post } from 'types/Post';

export enum FeedAction {
  FETCH_FEED_START = 'FETCH_FEED_START',
  FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS',
  FETCH_FEED_ERRORED = 'FETCH_FEED_ERRORED',

  CLEAR_FEED = 'CLEAR_FEED',
}

export interface FeedState {
  data: Post | null;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
