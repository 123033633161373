import styled from 'styled-components';
import { Row, Typography, Button, Col, List } from 'antd';
import { ExpenseReportStatus } from 'types/ExpensesReport';

export const Item = styled(List.Item)`
  &:hover {
    cursor: pointer;
    background: #f8f8f9;
  }
  .ant-list-item-meta {
    align-items: center !important;
  }
`;

export const RowItem = styled(Row)<{ last?: boolean; disabled?: boolean }>`
  border-bottom: ${(props) => (props.last ? '' : '1px solid #eaecf0')};
  pointer-events: ${(props) => (!props.disabled ? 'auto' : '')};
  padding-bottom: ${(props) => (props.last ? '' : '16px')};
  width: 100%;
`;

export const CopyButton = styled(Button)`
  margin-left: 5px;
  border: none;
`;

export const ItemLabel = styled(Typography.Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 0;
`;

export const ItemValue = styled(Typography.Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
`;

export const FileItemContainer = styled(Row)<{
  noBorder?: boolean;
}>`
  width: 100%;
  background: #ffffff;
  border: ${({ noBorder }) => (noBorder ? '' : '1px solid #eaecf0')};
  border-radius: 12px;
  padding: ${({ noBorder }) => (noBorder ? '' : '14px')};
`;

export const FileItemName = styled(Typography.Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  display: block;
`;

export const FileItemSize = styled(Typography.Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: block;
`;

export const IconButton = styled(Button)`
  border: none;
  box-shadow: none;
  &:hover {
    cursor: pointer;
  }
`;

export const Actions = styled(Col)<{
  noActions?: boolean;
}>`
  ${({ noActions }) => noActions && 'display: none'};
`;

export const TrainingStatusTag = styled.div<{
  status: string;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === 'active' && 'background-color:#ECFDF3; color:#027A48'};
  ${({ status }) =>
    status === 'inactive' && 'background-color:#FEF3F2; color: #B42318 '};

  p {
    margin-bottom: 0;
    margin-left: 1px;
    text-transform: capitalize;
  }
`;

export const ExpenseStatusTag = styled.div<{
  status: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 140px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === ExpenseReportStatus.APPROVED &&
    'background-color:#ECFDF3; color:#027A48'};
  ${({ status }) =>
    status === ExpenseReportStatus.PENDING &&
    'background-color:#D8D8D8; color:#7A7A7A'};
  ${({ status }) =>
    status === ExpenseReportStatus.REJECTED &&
    'background-color:#FEF3F2; color: #B42318 '};

  p {
    margin-bottom: 0;
    margin-left: 1px;
    text-transform: capitalize;
  }
`;

export const FeatureEnabledTag = styled.div<{
  status: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;

  ${({ status }) =>
    status === true && 'background-color:#ECFDF3; color:#027A48'};
  ${({ status }) =>
    status === false && 'background-color:#FEF3F2; color: #B42318 '};

  p {
    margin-bottom: 0;
    margin-left: 1px;
    text-transform: capitalize;
  }
`;

export const Linkedin = styled(Typography.Text)`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
