import styled from 'styled-components';
import { Button } from 'antd';

export const StyledContainer = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.1);
`;

export const StyledCheckInInfo = styled.div`
  font-size: 16px;
  color: #0056b3;
`;

export const StyledTimeTrackerButton = styled(Button)`
  background-color: #1e90ff;
  color: white;
  height: 80%;
  border: none;
  &:hover {
    background-color: #4682b4;
  }
  &:focus {
    outline: none;
  }
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;
