import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { fetchSkillsetMatchingByOpenPositionId } from 'redux/skillsetMatching/action';
import { useSearchParams } from 'react-router-dom';
import i18next from '../../../../i18n';

export default function SkillsetMatchingFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id: string = searchParams.get('id') as string;

  async function onFormChange() {
    const yearsOfExperience: boolean = form.getFieldValue('yearsOfExperience');
    const applied: boolean = form.getFieldValue('applied');

    dispatch(
      fetchSkillsetMatchingByOpenPositionId(
        id,
        {
          pageOptions: {
            page: 1,
            take: 10,
          },
        },
        yearsOfExperience,
        applied
      )
    );
  }

  const SkillsetMatchingFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'yearsOfExperience',
          label: i18next.t('yearsOfExperience'),
          type: InputTypes.SWITCH,
          direction: 'vertical',
          switchProps: {
            checkedChildren: true,
            unCheckedChildren: false,
          },
        },
        {
          col: 24,
          offset: 0,
          name: 'applied',
          label: i18next.t('applicants'),
          type: InputTypes.SWITCH,
          direction: 'vertical',
          switchProps: {
            checkedChildren: true,
            unCheckedChildren: false,
          },
        },
      ],
    ],
    [form]
  );
  return (
    <GenericForm
      formConfiguration={SkillsetMatchingFilterConfig}
      onFieldsChange={onFormChange}
      form={form}
    />
  );
}
