import React from 'react';
import { Col, Popconfirm, Row, Tooltip } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { EdititingIcon } from 'Icons/EditIcon';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type Props = {
  id: string;
};

const TrackingHoursActions: React.FC<Props> = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  function onTHEdit() {
    searchParams.append('edit-hour', id);
    setSearchParams(searchParams);
  }

  function onTHDelete() {
    searchParams.append('delete-hour', id);
    setSearchParams(searchParams);
  }

  return (
    <>
      {authUserRole && authUserRole !== AUTH_ROLES.GUEST_TRACKING_HOURS && (
        <Row>
          <Col span={24}>
            <Row justify="space-around">
              <Col>
                <Tooltip title={t('editTrackingHours')}>
                  <StyledIcon onClick={onTHEdit}>
                    <EdititingIcon />
                  </StyledIcon>
                </Tooltip>
              </Col>
              <Col>
                <Popconfirm
                  placement="topLeft"
                  title={t('doYouWantToDeleteThisWorkLog?')}
                  onConfirm={onTHDelete}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Tooltip title={t('delete')}>
                    <StyledIcon>
                      <DeleteIcon />
                    </StyledIcon>
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TrackingHoursActions;
