import agent from 'api/agent';
import { Holidays } from 'types/Holidays';
import { apiURLs } from './constants';

export const createHolidays = (values: Holidays) =>
  agent.post(`${apiURLs.holidays}`, values);

export const getHoliday = (tenantLocationId: string) =>
  agent.get(`${apiURLs.holidays}/${tenantLocationId}`);

export const updateHolidays = (id: string, values: Holidays) =>
  agent.put(`${apiURLs.holidays}/${id}`, values);
