import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'antd/lib/typography';
import Divider from 'antd/lib/divider';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';

import { DashboardStatsType } from 'types/DashboardStats';
import { getStats } from 'api/employeeDashobard';

import { DashboardOpenPositionsIcon } from 'Icons/DashboardOpenPositionsIcon';
import { DashboardEmployeesIcon } from 'Icons/DashboardEmployeesIcon';
import { DashboardProjectsIcon } from 'Icons/DashboardProjectsIcon';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';

const { Text } = Typography;

const Stats = () => {
  const [dashboardStats, setDashboardStats] = useState<DashboardStatsType>();
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const { t } = useTranslation();

  async function fetchDashboardStats() {
    setLoadingStats(true);

    try {
      const employeeId = localStorage.getItem('EMPLOYEE_ID');
      const { data } = await getStats(employeeId as string);

      setDashboardStats(data);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }

    setLoadingStats(false);
  }

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <Row gutter={[24, 24]}>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardEmployeesIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.assets ?? 0}
                  </Text>
                  <Row>{t('asset')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>
            <Row justify="end">
              <Link to="/employee-details?tab=3">{t('viewAssets')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardOpenPositionsIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.trainings ?? 0}
                  </Text>
                  <Row>{t('trainings')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>
            <Row justify="end">
              <Link to="/employee-details?tab=4">{t('viewTrainings')}</Link>
            </Row>
          </>
        </CardItem>
      </Col>
      <Col xl={6} md={12}>
        <CardItem minHeight={60}>
          <>
            <Spin spinning={loadingStats}>
              <Row>
                <Col xl={12} md={12}>
                  <DashboardProjectsIcon />
                </Col>
                <Col span={12}>
                  <Text strong style={{ fontSize: '20px' }}>
                    {dashboardStats?.projects ?? 0}
                  </Text>
                  <Row>{t('projects')}</Row>
                </Col>
                <Divider />
              </Row>
            </Spin>
            <Row justify="end">
              <Link to="/employee-details?tab=1#projects">
                {t('viewProjects')}
              </Link>
            </Row>
          </>
        </CardItem>
      </Col>
    </Row>
  );
};

export default Stats;
