import React, { useEffect, useState } from 'react';
import { OffboardingTaskType } from 'redux/offboarding/types';
import { useOffboardingData } from 'ui-v2/hooks/useOffboardingData';
import {
  FormItemStyled,
  RequirementText,
} from 'components/NewForms/FormStyled';
import { Button, Col, Collapse, Form, Input, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlined,
  FileTextOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useKnowledgeData } from 'ui-v2/hooks/useKnowledgeData';
import { createKnowledge, updateKnowledge } from 'api/knowledgeService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import { useSearchParams } from 'react-router-dom';
import { updateTaskStatus } from 'api/offboarding';
import { OffboardingTaskStatus } from 'types/Offboarding';
import { Knowledge } from 'types/Knowledge';
import { useDispatch } from 'react-redux';
import { fetchKnowledge } from 'redux/knowledge/actions';
import TabWrapper from './TabWrapper';

type Item = {
  key: number;
  item: string | null;
};

export default function KnowledgeTransfer({ onNext }: { onNext: () => void }) {
  const [searchParams] = useSearchParams();
  const { getTabStatus, data } = useOffboardingData();
  const offboardingId = searchParams.get('offboardingId');
  const [, setLoading] = useState<boolean>(false);
  const { list } = useKnowledgeData();
  const { t } = useTranslation();
  const [items, setItems] = useState<Item[]>([]);
  const dispatch = useDispatch();

  const status = getTabStatus(OffboardingTaskType.KNOWLEDGE);

  useEffect(() => {
    if (offboardingId && data && data.employee && data.employee.id) {
      dispatch(fetchKnowledge(data.employee.id));
    }
  }, [searchParams, data, offboardingId]);

  useEffect(() => {
    if (list && Array.isArray(list) && list.length > 0) {
      const loadedItems = list[0]?.employeeKnowledge
        ?.split('; ')
        .map((req: string, index: number) => ({
          key: index,
          item: req,
        }));
      setItems(loadedItems.length === 0 ? [{ key: 0, item: '' }] : loadedItems);
    } else {
      setItems([{ key: 0, item: '' }]);
    }
  }, [list]);

  const onAddItem = () => {
    const newItemKey = items.length;
    const newItem: Item = {
      key: newItemKey,
      item: '',
    };

    setItems([...items, newItem]);
  };

  const onRemoveItem = (itemKey: number) => {
    const filteredItems = items.filter((req) => req.key !== itemKey);
    setItems(
      filteredItems.length === 0 ? [{ key: 0, item: '' }] : filteredItems
    );
  };

  const onItemtChanged = (currentItem: Item, value: string) => {
    const updatedItems = items.map((req) => {
      if (req.key === currentItem.key) {
        return {
          ...req,
          item: value,
        };
      }
      return req;
    });
    setItems(updatedItems);
  };

  async function onSubmit() {
    const knowledgeList = items.map((item) => item.item).join('; ');
    const valuesToSend: Knowledge = {
      employeeKnowledge: knowledgeList,
      employeeId: data?.employee?.id,
    };

    setLoading(true);

    if (list && Array.isArray(list) && list.length > 0 && list[0]?.id) {
      updateKnowledge(valuesToSend, list[0]?.id)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('Task updated successfully!'));
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    createKnowledge(valuesToSend)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('Task created successfully!'));
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function onCompleted() {
    if (status === OffboardingTaskStatus.COMPLETED) {
      onNext();
      return;
    }

    if (
      offboardingId &&
      data &&
      data.offBoardingTasks &&
      data.offBoardingTasks[2]
    ) {
      updateTaskStatus({
        taskId: data.offBoardingTasks[2].id,
        status: OffboardingTaskStatus.COMPLETED,
        offboardingId,
      })
        .then((updateRes) => {
          if (updateRes.status === 200) {
            toast.success(t('Task completed successfully!'));
            onNext();
          }
        })
        .catch((updateError) => {
          toastErrorMessages(updateError);
        });
    }
  }

  return (
    <TabWrapper onNext={onCompleted} type={OffboardingTaskType.KNOWLEDGE}>
      <>
        {status === OffboardingTaskStatus.COMPLETED && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <FileTextOutlined
                style={{ fontSize: '64px', color: '#faad14' }}
              />
              <h2>Knowledge Transfer Completed</h2>
            </Space>
          </div>
        )}
        {[
          OffboardingTaskStatus.IN_PROGRESS,
          OffboardingTaskStatus.PENDING,
        ].includes(status) && (
          <div
            style={{
              maxWidth: '600px',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical" size="large">
              <CheckCircleOutlined
                style={{ fontSize: '64px', color: '#52c41a' }}
              />
              <h2>Knowledge Transfer Pending</h2>
              <p>
                The knowledge transfer has been successfully completed. The
                leaving employee has provided comprehensive notes and
                documentation for the new employee.
              </p>
            </Space>
          </div>
        )}
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <FormItemStyled style={{ marginTop: 0, width: '50%', paddingTop: 0 }}>
            <Row gutter={[0, 0]}>
              <RequirementText>{t('Knowledge List')}</RequirementText>
              <Col span={24}>
                <Collapse>
                  {items.map((item, index) => (
                    <Collapse.Panel
                      header={`${t('item')} ${index + 1}`}
                      key={item.key}
                      extra={
                        [
                          OffboardingTaskStatus.IN_PROGRESS,
                          OffboardingTaskStatus.PENDING,
                        ].includes(status) && (
                          <MinusCircleOutlined
                            onClick={() => onRemoveItem(item.key)}
                          />
                        )
                      }
                    >
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label={t('item')}
                            name={`item${item.key}`}
                            initialValue={item.item}
                            rules={[
                              {
                                required: true,
                                message: t('pleaseEnterItem'),
                              },
                            ]}
                          >
                            <Input
                              defaultValue={item.item || ''}
                              placeholder={t('addItem')}
                              onChange={(e) =>
                                onItemtChanged(item, e.target.value)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Col>
              {[
                OffboardingTaskStatus.IN_PROGRESS,
                OffboardingTaskStatus.PENDING,
              ].includes(status) && (
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={onAddItem}
                      icon={<PlusOutlined />}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={onSubmit}>
                      {t('submit')}
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </FormItemStyled>
        </div>
      </>
    </TabWrapper>
  );
}
