export const USER = 'USER';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const IS_LOGGEDIN = 'IS_LOGGEDIN';
export const TENANT_ID = 'x-tenant-id';
export const EMPLOYEE_ID = 'EMPLOYEE_ID';
export const USER_ROLE = 'USER_ROLE';
export const USER_DATE_FORMAT = 'USER_DATE_FORMAT';
export const USER_LANGUAGE = 'USER_LANGUAGE';
export const DEFAULT_LANGUAGE = 'en-gb';

export const UNAVAILABLE_MESSAGE = 'No Feedback';

export const DEFAULT_USER_DATE_FORMAT = 'DD/MM/YYYY';

export const PHONE_SCREEN_BREAKPOINT = 768;

export const dateFormatOptions = [
  {
    id: 'DD/MM/YYYY',
    label: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    id: 'MM/DD/YYYY',
    label: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  {
    id: 'YYYY/MM/DD',
    label: 'YYYY/MM/DD',
    value: 'YYYY/MM/DD',
  },
];

export const configRecordsSelectOptions = [
  { id: '5', label: '5', value: '5' },
  { id: '10', label: '10', value: '10' },
  { id: '15', label: '15', value: '15' },
  { id: '20', label: '20', value: '20' },
  { id: '25', label: '25', value: '25' },
  { id: '30', label: '30', value: '30' },
  { id: '35', label: '35', value: '35' },
  { id: '40', label: '40', value: '40' },
  { id: '45', label: '45', value: '45' },
  { id: '50', label: '50', value: '50' },
];

export const prefixSelector = [
  {
    id: '+351',
    value: '+351',
    label: '+351',
  },
  {
    id: '+30',
    value: '+30',
    label: '+30',
  },
  {
    id: '+39',
    value: '+39',
    label: '+39',
  },
  {
    id: '+355',
    value: '+355',
    label: '+355',
  },
  {
    id: '+382',
    value: '+382',
    label: '+382',
  },
  {
    id: '+383',
    value: '+383',
    label: '+383',
  },
  {
    id: '+389',
    value: '+389',
    label: '+389',
  },
];

export const companySectorSelector = [
  {
    id: 'Software Development',
    label: 'Software Development',
    value: 'Software Development',
  },
  {
    id: 'Marketing',
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    id: 'Manufacturing',
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    id: 'Retail',
    label: 'Retail',
    value: 'Retail',
  },
];

export const colorsList = [
  'red',
  'green',
  'blue',
  'yellow',
  'orange',
  'purple',
  'pink',
  'brown',
  'black',
  'gray',
  'maroon',
  'navy',
  'teal',
  'olive',
  'lime',
  'aqua',
  'fuchsia',
  'silver',
  'gold',
];
