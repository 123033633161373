import styled from 'styled-components';

export const StyledParagraph = styled.p`
  margin: 0;
  font-weight: 300;
`;

export const Button = styled.button<{ variant: string }>`
  background-color: ${(props) => {
    if (props.variant === 'approve') {
      return '#2783e5';
    }
    return '#e14646';
  }};
  color: white;

  min-width: 100px;
  min-height: 40px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;
