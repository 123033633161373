import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import { createSubSkill, updateSubSkill } from 'api/skillService';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { SkillsSubCategory } from 'types/Employee';
import { CreateSubSkillDto } from 'types/Skills';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedSubCategory: SkillsSubCategory | undefined;
  skillCategoryId: any;
  fetchSubCategories: any;
}

function SkillSubCategoryForm({
  open,
  closeModal,
  selectedSubCategory,
  skillCategoryId,
  fetchSubCategories,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  function onFormFinish(values: CreateSubSkillDto) {
    const valuesToSend: CreateSubSkillDto = {
      ...values,
      name: values.name,
      skillCategory: skillCategoryId,
    };
    setLoading(true);

    if (selectedSubCategory?.id) {
      updateSubSkill(selectedSubCategory?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('skillUpdatedSuccessfully'));
            searchParams.append('edited-sub-category', 'true');
            closeModal();
            fetchSubCategories(skillCategoryId);
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createSubSkill(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('skillCreatedSuccessfully'));
            searchParams.append('added-sub-category', 'true');
            closeModal();
            fetchSubCategories(skillCategoryId);
          }
        })
        .catch(() => {
          toast.error(t('failedToCreateSkill!'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    fetchSubCategories(skillCategoryId);
  }, [skillCategoryId]);

  const SkillFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('skillName'),
          type: InputTypes.INPUT,
          defaultValue: selectedSubCategory?.subCategoryName || '',
          inputProps: { type: 'text', maxLength: 35 },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [selectedSubCategory]
  );

  return (
    <GenericModal
      title={selectedSubCategory?.id ? t('editSkill') : t('addSkill')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={SkillFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default SkillSubCategoryForm;
