import React from 'react';
import { ExpensesReport } from 'types/ExpensesReport';
import { Row } from 'antd';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import ExpenseReportActions from 'components/Actions/ExpenseReportActions';
import i18next from '../i18n';

export const ExpenseReportColumns = [
  {
    title: i18next.t('employee'),
    dataIndex: 'employee',
    key: 'employee',
    default: false,
    render: (_: string, item: ExpensesReport) => (
      <>{`${item?.employee?.firstName} ${item?.employee?.lastName}`}</>
    ),
    order: 0,
    width: 300,
  },
  {
    title: i18next.t('expenseStatus'),
    dataIndex: 'status',
    key: 'status',
    default: false,
    render: (_: string, item: ExpensesReport) => (
      <Row justify="start">
        <Styled.ExpenseStatusTag status={item?.status || ''}>
          <p>{item?.status}</p>
        </Styled.ExpenseStatusTag>
      </Row>
    ),
    order: 1,
    width: 250,
  },
  {
    title: i18next.t('expenseItems'),
    dataIndex: 'expenseItems',
    key: 'expenseItems',
    render: (_: string, item: ExpensesReport) => (
      <>{item.expenseLines.length}</>
    ),
    default: false,
    order: 2,
    width: 200,
  },
  {
    title: i18next.t('totalAmount'),
    dataIndex: 'total',
    key: 'total',
    render: (_: string, item: ExpensesReport) => {
      const totalAmount = item.expenseLines.reduce(
        (total, line) => total + Number(line.amount),
        0
      );
      return totalAmount.toFixed(2);
    },
    default: false,
    order: 3,
    width: 200,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (record: string, row: ExpensesReport) => (
      <ExpenseReportActions id={row?.id} />
    ),
    default: false,
    order: 4,
    fixed: 'right',
  },
];
