import React, { useEffect, useState } from 'react';
import { toastErrorMessages } from 'utils/utilFunctions';
import { commentPost, likePost, pinPost } from 'api/feedService';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { fetchFeed } from 'redux/feed/actions';
import { useFeedData } from 'ui-v2/hooks/useFeedData';
import { Post } from 'types/Post';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import FeedPost from './FeedPost';
import CardItem from '../../Card';
import Content from '../../Content';
import AddPost from '../AddPost/AddPost';
import RightSection from '../../RightSection';
import { NoPostsMessage } from '../FeedStyled';

const FeedWrapper: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { list: post } = useFeedData();
  const { data: emp } = useEmployeeSkimData();

  useEffect(() => {
    dispatch(fetchFeed());
  }, [dispatch]);

  useEffect(() => {
    if (post) {
      const formattedPosts = post.data.map((p: Post) => ({
        id: p.id,
        content: p.content,
        likes: p.postLikes,
        comments: p.postComments,
        shares: 0,
        pinned: p.pinned || false,
        createdAt: p.createdAt,
        creator: p.employee,
      }));
      setPosts(formattedPosts);
    }
  }, [post]);

  const handleAddPost = () => {
    setShowModal(false);
    dispatch(fetchFeed());
  };

  const handleLike = async (postId: string) => {
    try {
      await likePost(postId);
      dispatch(fetchFeed());
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const handleComment = async (postId: string, comment: string) => {
    const valuesToSend = {
      postId,
      content: comment,
    };
    try {
      await commentPost(valuesToSend);
      dispatch(fetchFeed());
    } catch (error) {
      toastErrorMessages(error);
    }
  };
  const handlePin = async (postId: string, currentPinned: boolean) => {
    const valuesToSend = {
      pinned: !currentPinned,
    };

    try {
      await pinPost(postId, valuesToSend);
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post.id === postId) {
            return { ...post, pinned: !currentPinned };
          }
          return post;
        })
      );
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  return (
    <Content position="center">
      <>
        <CardItem
          title="Feed"
          rightButton={
            <RightSection
              buttonCallBack={() => setShowModal(true)}
              buttonText={t('Add Post')}
            />
          }
        >
          <>
            {posts.length === 0 ? (
              <NoPostsMessage>
                There are no posts yet. Create a post.
              </NoPostsMessage>
            ) : (
              posts.map((post) => (
                <FeedPost
                  key={post.id}
                  post={post}
                  emp={emp}
                  onLike={handleLike}
                  onComment={handleComment}
                  onPin={handlePin}
                />
              ))
            )}
          </>
        </CardItem>
        {showModal && (
          <AddPost
            open={showModal}
            onClose={() => setShowModal(false)}
            onSubmit={handleAddPost}
          />
        )}
      </>
    </Content>
  );
};
export default FeedWrapper;
