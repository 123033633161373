import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import {
  SkillsetMatching,
  SkillsetMatchingTypes,
} from 'types/SkillsetMatching';
import { t } from 'i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface IProps {
  row: SkillsetMatching;
}

const SkillsetMatchingActionsComponent: React.FC<IProps> = ({ row }) => {
  const navigate = useNavigate();

  const onView = () => {
    if (row?.type === SkillsetMatchingTypes.EMPLOYEE) {
      navigate(`/employees/employee-profile?id=${row?.id}`);
    } else {
      navigate(`/candidates/profile?id=${row?.id}`);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('viewProfile')}>
                <IconButton onClick={onView}>
                  <ViewProfileIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default SkillsetMatchingActionsComponent;
