import React, { useCallback } from 'react';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { StyledTrackingDateFilterDateActionItem } from './StyledTrackingDateFilter';
import { TRACKING_DATE_FORMAT_TYPE } from './utils';

interface TrackingDateActionProps {
  arrowDirection: 'left' | 'right';
}

const TrackingDateAction: React.FC<TrackingDateActionProps> = ({
  arrowDirection,
}) => {
  const {
    urlState: { startDate, endDate, employeeIds, projectIds },
    setUrlState,
    fetch,
  } = useTrackedHoursData();

  const onArrowClick = useCallback(() => {
    let diff = moment
      .duration(moment(startDate).diff(moment(endDate)))
      .as('days');
    diff = diff === 0 ? 1 : diff;
    let start;
    let end;
    if (arrowDirection === 'right') {
      start = moment(startDate)
        .subtract(diff, 'days')
        .format(TRACKING_DATE_FORMAT_TYPE.URL);
      end = moment(endDate)
        .subtract(diff, 'days')
        .format(TRACKING_DATE_FORMAT_TYPE.URL);
    } else {
      start = moment(startDate)
        .add(diff, 'days')
        .format(TRACKING_DATE_FORMAT_TYPE.URL);
      end = moment(endDate)
        .add(diff, 'days')
        .format(TRACKING_DATE_FORMAT_TYPE.URL);
    }
    if (start && end) {
      setUrlState({
        startDate: start,
        endDate: end,
      });
      fetch({
        searchOptions: {
          startDate: start,
          endDate: end,
          employees: employeeIds,
          projects: projectIds,
        },
      });
    }
  }, [startDate, endDate, projectIds, employeeIds]);

  return (
    <>
      {arrowDirection === 'left' && (
        <StyledTrackingDateFilterDateActionItem
          dividedRight
          onClick={onArrowClick}
        >
          <LeftOutlined />
        </StyledTrackingDateFilterDateActionItem>
      )}
      {arrowDirection === 'right' && (
        <StyledTrackingDateFilterDateActionItem bordered onClick={onArrowClick}>
          <RightOutlined />
        </StyledTrackingDateFilterDateActionItem>
      )}
    </>
  );
};

export default TrackingDateAction;
