import { Col, Row } from 'antd';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useEffect, useState } from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import { useTranslation } from 'react-i18next';
import { updateGoalState } from 'api/goalService';
import { StyledButton } from 'components/NewForms/FormStyled';
import { toast } from 'react-toastify';
import { useGoalData } from 'ui-v2/hooks/useGoalData';
import { useDispatch } from 'react-redux';
import { fetchGoals } from 'redux/goal/action';
import {
  StyledCenteredSlider,
  StyledCenteredSliderContainer,
  StyledLabel,
  StyledPercentage,
  StyledSliderContainer,
} from './GoalStyles';

interface IProps {
  open: boolean;
  closeModal: () => void;
  goalId: string;
}

interface Goal {
  id: string;
  completed: number;
}

export default function UpdateGoalModal({ open, closeModal, goalId }: IProps) {
  const [value, setValue] = useState(50);
  const { t } = useTranslation();
  const { list: goals } = useGoalData();

  const dispatch = useDispatch();

  const onSubmit = async (value: number, id: string) => {
    try {
      await updateGoalState(value, id);
      toast.success(t('goalUpdatedSuccessfully'));
      closeModal();
      const params = {
        pageOptions: { page: 1, take: 10 },
      };
      dispatch(fetchGoals(params));
    } catch (error) {
      toast.warning(t('couldNotUpdateTheGoal'));
    }
  };

  useEffect(() => {
    goals.map((goal: Goal) => {
      if (goal.id === goalId) {
        setValue(goal.completed);
      }
      return goal;
    });
  }, [goals, goalId]);

  return (
    <GenericModal
      title={t('updateGoal')}
      open={open}
      closeModal={closeModal}
      icon={<WorkExperienceIcon />}
    >
      <Row>
        <Col span={24}>
          <StyledSliderContainer>
            <StyledLabel>Goal state</StyledLabel>
            <StyledCenteredSliderContainer>
              <StyledCenteredSlider
                value={value}
                onChange={(value) => setValue(value)}
              />
              <StyledPercentage>{`${value}%`}</StyledPercentage>
            </StyledCenteredSliderContainer>
          </StyledSliderContainer>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <StyledButton onClick={closeModal} htmlType="reset" danger>
            {t('cancel')}
          </StyledButton>
        </Col>
        <Col span={11} offset={2}>
          <StyledButton
            onClick={() => onSubmit(value, goalId)}
            type="primary"
            htmlType="submit"
          >
            {t('confirm')}
          </StyledButton>
        </Col>
      </Row>
    </GenericModal>
  );
}
