import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { Calendar, Input } from 'antd';
import { DEFAULT_USER_DATE_FORMAT } from 'utils/constants';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { t } from 'i18next';
import { updateHolidays } from 'api/holidays';
import { toast } from 'react-toastify';
import { HolidayDetail } from 'types/Holidays';
import HeaderRender from './HeaderRender';
import CellRender from './CellRender';
import { PayrollHoliday } from '../PayrollDateModal/PayrollDateModal';
import CalendarPopUp from './CalendarPopUp';

interface PayrollDateCalendarProps {
  selectedDate: moment.Moment;
  holidays: PayrollHoliday[];
  setDate: (date: moment.Moment) => void;
  modal: boolean;
  title?: string;
  holidayObj?: any;
  setRemoved?: any;
}

const StyledLabel = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
`;

const PayrollDateCalendar: React.FC<PayrollDateCalendarProps> = ({
  holidays,
  selectedDate,
  setDate,
  title,
  modal,
  holidayObj,
  setRemoved,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [dateToRemove, setDateToRemove] = useState<Moment | null>(null);

  const onSelect = (date: Moment) => {
    if (modal) {
      const existingHoliday = holidays.find((holiday) =>
        isEqual(
          holiday.date.format(DEFAULT_USER_DATE_FORMAT),
          date.format(DEFAULT_USER_DATE_FORMAT)
        )
      );

      if (existingHoliday) {
        setDateToRemove(date);
        setConfirmVisible(true);
      } else {
        setDate(date);
        setIsModalVisible(true);
      }
    }
  };

  const handleRemoveHoliday = async () => {
    if (dateToRemove) {
      try {
        const updatedHolidays = JSON.parse(holidayObj?.holidayObject).filter(
          (holiday: HolidayDetail) =>
            !isEqual(
              moment(holiday.date).format(DEFAULT_USER_DATE_FORMAT),
              dateToRemove.format(DEFAULT_USER_DATE_FORMAT)
            )
        );
        await updateHolidays(holidayObj?.id, updatedHolidays);
        setRemoved(true);
        toast.success(t('holidayRemovedSuccessfully'));
      } catch (error) {
        toast.error(t('errorRemovingHoliday'));
      } finally {
        setConfirmVisible(false);
        setDateToRemove(null);
      }
    }
  };

  const handleCancelRemove = () => {
    setConfirmVisible(false);
    setDateToRemove(null);
  };

  const handleOk = async () => {
    try {
      const holidayDetail: any = [
        ...(JSON.parse(holidayObj?.holidayObject) || []),
        {
          name: inputValue,
          date: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
      ];
      await updateHolidays(holidayObj?.id, holidayDetail);
      toast.success(t('holidayAddedSuccessfully'));
      setInputValue('');
      setRemoved(true);
      setIsModalVisible(false);
    } catch (error) {
      toast.error(t('errorAddingHoliday'));
    }
  };

  const handleCancel = () => {
    setInputValue('');
    setIsModalVisible(false);
  };

  return (
    <>
      <Calendar
        fullscreen={false}
        value={selectedDate}
        headerRender={(config) => (
          <HeaderRender headerConfig={config} setDate={setDate} />
        )}
        onSelect={onSelect}
        dateFullCellRender={(date) => (
          <CellRender
            date={date}
            holiday={holidays?.find((holiday) =>
              isEqual(
                holiday.date.format(DEFAULT_USER_DATE_FORMAT),
                date.format(DEFAULT_USER_DATE_FORMAT)
              )
            )}
            isSelected={isEqual(
              date.format(DEFAULT_USER_DATE_FORMAT),
              selectedDate?.format(DEFAULT_USER_DATE_FORMAT)
            )}
          />
        )}
      />
      {modal && (
        <CalendarPopUp
          title={title}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <StyledLabel>{t('date')}</StyledLabel>
          <p>
            {selectedDate
              ? selectedDate.format('DD/MM/YYYY')
              : 'No date selected'}
          </p>
          <StyledLabel>{t('nameOrDescription')}</StyledLabel>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </CalendarPopUp>
      )}
      {confirmVisible && (
        <CalendarPopUp
          title={t('removeHoliday')}
          visible={confirmVisible}
          onOk={handleRemoveHoliday}
          onCancel={handleCancelRemove}
        >
          <p>{t('areYouSureYouWantToRemoveThisHoliday')}</p>
        </CalendarPopUp>
      )}
    </>
  );
};

export default PayrollDateCalendar;
