import { useSelector } from 'react-redux';

export function useFeedData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.feed
  );

  return {
    list: data,
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
