import { PlusSquareOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import CandidateDynamicForm from 'components/Candidate/CandidateDynamicForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCollapse } from './DynamicFormStyles';

export function getAllSubSkills(skills: any) {
  return [...skills.map((item: any) => item.subCategories.flat()).flat()];
}

export function toUpperCase(value: string) {
  const words = value?.split(' ');
  const toUpper = words?.map(
    (i: string) => i.charAt(0).toUpperCase() + i.slice(1)
  );
  return toUpper?.toString()?.replace(',', ' ');
}

export function appendSkills(
  values: any,
  skillSubCategoriesCandidates: any,
  defaultSkills: any,
  deletedSkills: any
) {
  const skillsKeys = Object.keys(values).filter((key: any) =>
    key.toString().includes('skills-matrix')
  );

  let skillSubCategories: any[] = [];
  if (skillSubCategoriesCandidates) {
    skillSubCategories = skillSubCategoriesCandidates?.map((item: any) => ({
      skillSubCategoryId: item.skillsSubCategory.id,
      yearsOfExperience: item.yearsOfExperience,
      score: item.score,
    }));
  }
  skillsKeys.forEach((skill: string) => {
    if (values[skill]) {
      skillSubCategories.push(...values[skill]);
    }
    delete values[skill];
  });

  let result = defaultSkills.map((item: any) => ({
    skillSubCategoryId: item.skillSubCategoryId,
    yearsOfExperience: item.yearsOfExperience,
    score: item.score,
  }));

  const defaultSkillsIDs = defaultSkills.map(
    (item: any) => item.skillSubCategoryId
  );
  const unChangedSkills: any[] = [];
  skillSubCategories.map((skill) => {
    if (
      skill?.skillSubCategoryId &&
      !defaultSkillsIDs.includes(skill.skillSubCategoryId)
    ) {
      unChangedSkills.push({
        skillSubCategoryId: skill.skillSubCategoryId,
        yearsOfExperience: skill.yearsOfExperience,
        score: skill.score,
      });
    }
    return skill;
  });
  result = [...result, ...unChangedSkills];

  if (deletedSkills.length) {
    deletedSkills.map((skill: any) => {
      result.pop(skill.index);
      return skill;
    });
  }

  return {
    ...values,
    skillSubCategories: result,
  };
}

function PlusIcon({ isActive }: { isActive: any }) {
  return <PlusSquareOutlined rotate={isActive ? 90 : 0} />;
}

export default function SkillsMatrix({
  fetchList,
  defaultSkills,
  onEditedSkillChange,
}: {
  fetchList: any;
  defaultSkills?: any[];
  onEditedSkillChange?: (index: number, field: any, deletedSkill?: any) => void;
}) {
  const [allSkills, setAllSkills] = useState([]);
  const [allSubSkills, setAllSubSkills] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchList()
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setAllSkills(response.data);
          setAllSubSkills(getAllSubSkills(response.data) as any);
        }
      })
      .catch(() => {
        console.log('error');
      });
  }, []);

  return (
    <>
      {allSkills.map((category: any) => (
        <StyledCollapse
          collapsible="header"
          accordion
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon={({ isActive }) => <PlusIcon isActive={isActive} />}
          key={`${category.id}collapse`}
        >
          <Collapse.Panel
            header={toUpperCase(category.categoryName)}
            key={category.id}
          >
            <CandidateDynamicForm
              key={`skills-matrix-${category.categoryName}`}
              categoryName={`skills-matrix-${category.categoryName}`}
              availableSkills={category.subCategories.map((item: any) => ({
                ...item,
                subCategoryName: toUpperCase(item.subCategoryName),
              }))}
              defaultSkills={defaultSkills}
              onEditedSkillChange={onEditedSkillChange}
            />
          </Collapse.Panel>
        </StyledCollapse>
      ))}
      <StyledCollapse
        collapsible="header"
        accordion
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={({ isActive }) => <PlusIcon isActive={isActive} />}
      >
        {allSubSkills && (
          <Collapse.Panel header={t('otherSkills')} key="other-skill">
            <CandidateDynamicForm
              key="skills-matrix-other-skills"
              categoryName="skills-matrix-other-skills"
              availableSkills={allSubSkills.map((item: any) => ({
                ...item,
                subCategoryName: toUpperCase(item.subCategoryName),
              }))}
              defaultSkills={defaultSkills}
              onEditedSkillChange={onEditedSkillChange}
            />
          </Collapse.Panel>
        )}
      </StyledCollapse>
    </>
  );
}
