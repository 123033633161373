/* eslint-disable react/jsx-one-expression-per-line */
import { Avatar, Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

import CardItem from 'ui-v2/components/Card';
import { TimeIcon } from 'Icons/TimeIcon';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import {
  convertUTCtoFormatedLocalTime,
  convertUTCtoLocalHour,
  isInterviewDatePassed,
} from 'utils/utilFunctions';
import { ScheduleIcon } from 'Icons/ScheduleIcon';
import { useDispatch, useSelector } from 'react-redux';
import { InterviewsState } from 'redux/candidates/types';
import { StyledButton } from 'components/NewForms/FormStyled';
import {
  clearScheduleInterview,
  fetchCandidateOpenPositionInterviews,
} from 'redux/candidates/actions';
import { RootState } from 'redux/store';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { useTranslation } from 'react-i18next';
import * as Styled from './InterviewStyles';
import EditInterviewModal from './EditInterviewModal';

interface InterviewListProps {
  isDisabled: boolean;
}

export default function InterviewList({ isDisabled }: InterviewListProps) {
  const { loaded: createInterviewLoaded } = useSelector(
    (state: RootState) => state.scheduleInterview
  );
  const { data: interviews, loading } = useSelector(
    (state: RootState) => state.interviews as InterviewsState
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    loading: candidateLoading,
    candidateLoaded,
    selectedCandidateOpenPosition,
  } = useCandidateData();

  const [modalId, setModalId] = useState<string>();

  const onAddInterview = () => {
    document.getElementById('schedule-interview-popover')?.click();
  };

  const onInterviewClick = (id: string) => {
    setModalId(id);
  };

  const closeModal = () => setModalId('');

  useEffect(() => {
    if (candidateLoaded && selectedCandidateOpenPosition?.data?.id) {
      dispatch(
        fetchCandidateOpenPositionInterviews(
          selectedCandidateOpenPosition.data.id
        )
      );
    }
  }, [selectedCandidateOpenPosition.data?.id, candidateLoaded]);

  useEffect(() => {
    if (candidateLoaded && selectedCandidateOpenPosition.data?.id) {
      // dispatch(
      //   fetchCandidateOpenPositionInterviews(
      //     selectedCandidateOpenPosition.data?.id
      //   )
      // );
      dispatch(clearScheduleInterview());
    }
  }, [createInterviewLoaded, candidateLoaded]);

  return (
    <>
      <CardItem
        title={t('interviews')}
        disabled={isDisabled}
        minHeight={100}
        rightButton={
          <Row justify="end">
            <Col
              style={{
                position: 'relative',
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  right: -16,
                }}
              >
                <TimeIcon size="large" />
              </span>
            </Col>
          </Row>
        }
      >
        <>
          <Skeleton
            loading={candidateLoading || loading}
            active
            title={false}
            paragraph={{ rows: 6, width: '100%' }}
          >
            <Styled.RowItem gutter={[0, 24]} justify="center">
              {interviews.interviews.length === 0 ? (
                <Col>
                  <Styled.Label>{t('noInterviewsScheduledYet')}</Styled.Label>
                </Col>
              ) : (
                <Styled.List span={24}>
                  <Row gutter={[0, 10]}>
                    {interviews.interviews?.map((item: any) => (
                      <Styled.InterviewItem
                        span={24}
                        onClick={() => onInterviewClick(item.id)}
                      >
                        <Row justify="space-between" align="middle">
                          <Col span={3}>
                            <Row>
                              <Avatar.Group maxCount={1}>
                                {item.partecipants?.map((item: any) => (
                                  <Avatar>
                                    {item.firstName
                                      .substring(0, 1)
                                      .toUpperCase()}
                                    {item.lastName
                                      .substring(0, 1)
                                      .toUpperCase()}
                                  </Avatar>
                                ))}
                              </Avatar.Group>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Col>
                              <Row justify="end">
                                <Styled.DateTimeContainer>
                                  <Styled.InterviewItemDate
                                    date={
                                      isInterviewDatePassed(item.date)
                                        ? ''
                                        : 'passed'
                                    }
                                  >
                                    {convertUTCtoFormatedLocalTime(item.date)}
                                  </Styled.InterviewItemDate>

                                  <Styled.InterviewItemTime
                                    date={
                                      isInterviewDatePassed(item.date)
                                        ? ''
                                        : 'passed'
                                    }
                                  >
                                    {` ${convertUTCtoLocalHour(item.date)}`}
                                  </Styled.InterviewItemTime>
                                </Styled.DateTimeContainer>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Styled.BadgeStyle
                              count={Number(item?.score)}
                              offset={[20, 10]}
                              color="#a3a9b7"
                            >
                              <Styled.InterviewItemLabel>
                                {item.interviewType.name}
                              </Styled.InterviewItemLabel>
                            </Styled.BadgeStyle>
                            <Styled.InterviewEmployeeLabel>
                              {item.partecipants
                                ?.map(
                                  (i: any) => `${i.firstName} ${i.lastName}`
                                )
                                .join(', ')}
                            </Styled.InterviewEmployeeLabel>
                          </Col>
                        </Row>
                      </Styled.InterviewItem>
                    ))}
                  </Row>
                </Styled.List>
              )}
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <Col span={24}>
                  <StyledButton
                    icon={<ScheduleIcon />}
                    onClick={onAddInterview}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      width: '100%',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '#344054',
                    }}
                  >
                    <Styled.IconSpace>{t('schedule')}</Styled.IconSpace>
                  </StyledButton>
                </Col>
              </RoleBasedButton>
            </Styled.RowItem>
          </Skeleton>
        </>
      </CardItem>
      {modalId && (
        <EditInterviewModal
          open={!!modalId}
          closeModal={closeModal}
          id={modalId}
        />
      )}
    </>
  );
}
