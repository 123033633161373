import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { StyledIcon } from 'components/common/DataGrid/DataGridStyles';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useHeader from 'ui-v2/hooks/useHeader';
import useExpenseReport from 'ui-v2/hooks/useExpenseReportData';
import { removeExpenseReport } from 'api/expensesService';
import { fetchExpenses } from 'redux/expenses/action';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface ExpensesReportProps {
  id: string;
}

const IdeaboxActions: React.FC<ExpensesReportProps> = ({ id }) => {
  const { take } = useHeader();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination } = useExpenseReport();

  const onViewClick = () => {
    navigate(`expense-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-expense',
        value: id,
      })
    );
  };

  const onDeleteClick = async () => {
    const response = await removeExpenseReport(id);

    if (response.status === 200) {
      toast.success(t('deletedExpense'));
      dispatch(
        fetchExpenses({
          pageOptions: { page: pagination?.page || 1, take },
        })
      );
      return;
    }

    toast.warning(t('couldNotDeleteThisExpense'));
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('expenseDetails')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>

          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              <Tooltip title={t('editExpense')}>
                <IconButton onClick={onEditClick}>
                  <EdititingIcon />
                </IconButton>
              </Tooltip>
            </Col>
          </RoleBasedButton>
          <RoleBasedButton btn={BUTTONS.DELETE_BUTTON}>
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t('areYouSureYouWantToDeleteThisExpense')}
                onConfirm={onDeleteClick}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledIcon>
                  <DeleteIcon />
                </StyledIcon>
              </Popconfirm>
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default IdeaboxActions;
