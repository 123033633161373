import { Typography } from 'antd';
import styled from 'styled-components';
import { TrackedHourType } from 'types/tracking';

export const StyledTrackingText = styled(Typography.Text)<{
  bold?: boolean;
  light?: boolean;
  color?: string;
  backGroundColor?: string;
  cursorPointer?: boolean;
  border?: boolean;
}>(({ theme, bold, backGroundColor, color, light, cursorPointer, border }) => ({
  ...(bold
    ? { ...theme.typography.body.bold.sm }
    : { ...theme.typography.body.medium.sm }),
  ...(color
    ? {
        color,
      }
    : { color: theme.colors.palette.primary.gray['700'] }),
  ...(backGroundColor
    ? {
        backgroundColor: backGroundColor,
      }
    : { backgroundColor: theme.colors.palette.primary.gray['100'] }),
  ...(light && { color: theme.colors.palette.primary.gray['500'] }),
  ...(cursorPointer && { cursor: 'pointer' }),
  ...(border && {
    border: `1px transparent ${theme.colors.palette.primary.gray['100']}`,
    padding: '.7rem',
    borderRadius: '10px',
  }),
}));

export const StyledTrackingButton = styled.div`
  padding: 0.7rem;
  background-color: #155eef;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
`;

export const StyledTrackingTag = styled(StyledTrackingText)<{
  variant?: 'success' | 'info' | 'danger' | 'secondary';
  ghost?: boolean;
  capitalize?: boolean;
}>(({ theme, variant, capitalize, bold }) => ({
  borderRadius: '4px',
  padding: '.2rem .5rem',
  color: theme.colors.palette.primary.gray[bold ? '700' : '500'],
  ...(variant === 'secondary' && {
    backgroundColor: theme.colors.palette.secondary.grayBlue['100'],
  }),
  ...(variant === 'success' && {
    backgroundColor: theme.colors.palette.primary.success['100'],
    color: theme.colors.palette.primary.success['500'],
  }),
  ...(variant === 'info' && {
    backgroundColor: theme.colors.palette.primary.blue['100'],
    color: theme.colors.palette.primary.blue['500'],
  }),
  ...(variant === 'danger' && {
    backgroundColor: theme.colors.palette.secondary.rose['100'],
    color: theme.colors.palette.secondary.rose['500'],
  }),
  ...(!variant && {
    backgroundColor: theme.colors.palette.primary.gray['100'],
    color: theme.colors.palette.primary.gray[bold ? '700' : '500'],
  }),
  ...(capitalize && {
    textTransform: 'capitalize',
  }),
  width: 'fit-content',
}));

export const StyledTrackingPoint = styled.div<{
  color: string;
}>(({ color }) => ({
  width: '8px',
  height: '8px',
  backgroundColor: color,
  borderRadius: '20px',
}));

export const StyledTrackingHourTypeTag = styled(StyledTrackingTag)<{
  hourType?: TrackedHourType;
}>(({ theme, hourType }) => ({
  borderRadius: '4px',
  ...(hourType === TrackedHourType.OVERTIME_HOURS && {
    backgroundColor: theme.colors.palette.secondary.orange['100'],
    color: theme.colors.palette.primary.gray['500'],
  }),
  ...(hourType === TrackedHourType.REGULAR_HOURS && {
    backgroundColor: theme.colors.palette.primary.gray['200'],
    color: theme.colors.palette.primary.gray['500'],
  }),
}));
