import React from 'react';
import { HeadcountType } from 'types/Headcount';
import i18next from '../i18n';

export const HeadcountColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <>{`${item?.firstName} ${item?.lastName}`}</>
    ),
    order: 0,
  },
  {
    title: i18next.t('department'),
    dataIndex: 'department',
    key: 'department',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <>{`${item?.workPosition?.department?.name}`}</>
    ),
    order: 1,
  },
  {
    title: i18next.t('workPosition'),
    dataIndex: 'workposition',
    key: 'workposition',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <>{`${item?.workPosition?.name}`}</>
    ),
    order: 2,
  },
];
