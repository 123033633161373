import { Col } from 'antd';
import {
  checkIn,
  checkOut,
  getCurrentAttendance,
} from 'api/employeeAttendanceService';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import CardItem from 'ui-v2/components/Card';
import { toastErrorMessages } from 'utils/utilFunctions';
import {
  StyledContainer,
  StyledCheckInInfo,
  StyledTimeTrackerButton,
  StyledRow,
} from './EmployeeStyles';

interface IProps {
  employeeId: string;
}

export default function EmployeeAttendance({ employeeId }: IProps) {
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [checkInTime, setCheckInTime] = useState<Date | null>(null);
  const [checkOutTime, setCheckOutTime] = useState<Date | null>(null);

  const isSameDay = (date1: Date, date2: Date): boolean =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  const fetchAttendanceStatus = async () => {
    try {
      const response = await getCurrentAttendance(employeeId);

      if (response.data) {
        const checkInDate = new Date(response.data.checkin);
        const today = new Date();

        if (isSameDay(checkInDate, today)) {
          setIsCheckedIn(true);
          setCheckInTime(checkInDate);

          if (response.data.checkout) {
            setCheckOutTime(new Date(response.data.checkout));
          } else {
            setCheckOutTime(null);
          }
        } else {
          setIsCheckedIn(false);
          setCheckInTime(null);
          setCheckOutTime(null);
        }
      } else {
        setIsCheckedIn(false);
        setCheckInTime(null);
        setCheckOutTime(null);
      }
    } catch (error) {
      console.log('Failed to fetch attendance status');
    }
  };

  useEffect(() => {
    fetchAttendanceStatus();
  }, [employeeId]);

  const handleCheckIn = async () => {
    try {
      const response = await checkIn(employeeId);
      if (response.data) {
        setIsCheckedIn(true);
        setCheckInTime(new Date());
      }
    } catch (error) {
      toastErrorMessages('Failed to check in');
    }
  };

  const handleCheckOut = async () => {
    try {
      const response = await checkOut(employeeId);
      if (response.data) {
        setIsCheckedIn(false);
        setCheckOutTime(new Date());
        fetchAttendanceStatus();
      }
    } catch (error) {
      toastErrorMessages('Failed to check out');
    }
  };

  return (
    <StyledContainer>
      <CardItem title={t('Employee Attendance')}>
        <>
          <StyledRow>
            {isCheckedIn ? (
              <StyledCheckInInfo>
                {t('Checked in at: ')}
                {checkInTime?.toLocaleTimeString() || t('Unknown')}
                <br />
                {checkOutTime ? (
                  <>
                    {t('Checked out at: ')}
                    {checkOutTime?.toLocaleTimeString() || t('Unknown')}
                  </>
                ) : null}
              </StyledCheckInInfo>
            ) : (
              <StyledCheckInInfo>{t('Not checked in')}</StyledCheckInInfo>
            )}
          </StyledRow>
          <StyledRow>
            <Col>
              {!isCheckedIn ? (
                <StyledTimeTrackerButton onClick={handleCheckIn}>
                  {t('Check In')}
                </StyledTimeTrackerButton>
              ) : (
                <StyledTimeTrackerButton onClick={handleCheckOut}>
                  {t('Check Out')}
                </StyledTimeTrackerButton>
              )}
            </Col>
          </StyledRow>
        </>
      </CardItem>
    </StyledContainer>
  );
}
