import React, { useEffect } from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { fetchGoals } from 'redux/goal/action';
import { useGoalData } from 'ui-v2/hooks/useGoalData';
import { deleteGoal } from 'api/goalService';

export default function GoalList() {
  const { columns } = useHeader();
  const dispatch = useDispatch();
  const { list, pagination, loading } = useGoalData();

  useEffect(() => {
    const params = {
      pageOptions: { page: 1, take: 10 },
    };
    dispatch(fetchGoals(params));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      pageOptions: { page, take: 10 },
    };
    dispatch(fetchGoals(params));
  };

  async function onDelete(ids: React.Key[]) {
    ids?.forEach(async (id) => {
      await deleteGoal(id?.toString());
      onPageChange(1);
    });
  }

  return (
    <Content position="stretch">
      <DataGrid
        loading={loading}
        columns={columns}
        data={list}
        paginationName="Goals"
        rowSelectionType="checkbox"
        totalItems={pagination?.itemCount}
        currentPage={pagination?.page}
        totalPages={pagination?.pageCount}
        onPageChange={onPageChange}
        onSelectedDelete={onDelete}
        showSearch
      />
    </Content>
  );
}
