import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { StyledTimeTrackerButton } from 'components/NewForms/FormStyled';
import { t } from 'i18next';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { StyledTrackingFilterPanelRow } from 'ui-v2/routes/tracking/trackingFilters/trackingFilterPanel/StyledTrackingFilterPanel';
import {
  getTimerState,
  pauseTimer,
  resumeTimer,
  startTimer,
  stopTimer,
} from 'api/trackedHoursService';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrjoectEmployeeAssignments } from 'redux/projects/actions';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AssigmentType } from 'types/Assigment';
import { StyledTrackingSummaryListContent } from '../trackingSummary/StyledTrackingSummary';

const TimeTracker: React.FC = () => {
  const {
    employeeAssignment: { data: assignments },
  } = useProjectsData();
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);
  const [form] = Form.useForm();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const startInterval = () => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  useEffect(() => {
    dispatch(fetchPrjoectEmployeeAssignments(authUser?.employee?.id ?? ''));
  }, []);

  useEffect(() => {
    const fetchTimerState = async () => {
      try {
        const response = await getTimerState();
        if (response.data) {
          setTime(response.data.totalRunningTime || 0);
          setIsActive(response.data.trackingHours.isRunning);
          setIsPaused(response.data.trackingHours.isPaused);

          if (
            response.data.trackingHours.isRunning &&
            !response.data.trackingHours.isPaused
          ) {
            startInterval();
          }
        }
      } catch (error) {
        console.error('Failed to fetch timer state:', error);
      }
    };

    fetchTimerState();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);
  const assignmentOptions = useMemo(() => {
    if (!assignments || assignments.length === 0) return [];

    return assignments.map((assignment: AssigmentType) => ({
      id: assignment?.id,
      label: assignment.project?.name,
      value: assignment?.id,
    }));
  }, [assignments]);

  const TimeTrackerForm: FormConfigurationType[][] = useMemo(() => {
    const formConfig: any[][] = [
      [
        {
          col: 24,
          offset: 1,
          name: 'assigmentId',
          label: t('projects'),
          type: InputTypes.SELECT,
          hideLabel: true,
          selectOptions: assignmentOptions,
          disabled: isActive,
        },
      ],
    ];
    return formConfig;
  }, [assignmentOptions, isActive]);

  const handleStart = async () => {
    const formValues = form.getFieldsValue(true);
    const assigmentId = formValues?.assigmentId;
    if (!isActive) {
      try {
        const response = await startTimer(assigmentId);
        if (response.data) {
          setIsActive(response.data.isRunning);
          setIsPaused(response.data.isPaused);
          startInterval();
        }
      } catch (error) {
        console.error('Failed to start timer:', error);
      }
    }
  };

  const handlePause = async () => {
    if (isActive && !isPaused) {
      try {
        const response = await pauseTimer();
        if (response.data) {
          setTime(response.data.totalRunningTime);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          setIsPaused(response.data.trackingHours.isPaused);
          setIsActive(response.data.trackingHours.isRunning);
        }
      } catch (error) {
        console.error('Failed to pause timer:', error);
      }
    } else if (isActive && isPaused) {
      try {
        const response = await resumeTimer();
        if (response.data) {
          setTime(response.data.totalRunningTime);
          setIsActive(response.data.trackingHours.isRunning);
          setIsPaused(response.data.trackingHours.isPaused);
          startInterval();
        }
      } catch (error) {
        console.error('Failed to resume timer:', error);
      }
    }
  };

  const handleStop = async () => {
    try {
      const response = await stopTimer();
      if (response.data) {
        setTime(response?.data?.isRunningTime || 0);
        setIsActive(response.data.isRunning);
        setIsPaused(response.data.isPaused);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      }
    } catch (error) {
      console.error('Failed to stop timer:', error);
    }
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')} : ${minutes
      .toString()
      .padStart(2, '0')} : ${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <StyledTrackingFilterPanelRow
        justify="space-between"
        align="middle"
        gutter={[0, 20]}
      >
        <Col xs={24} md={15}>
          <div style={{ width: '500px', marginBottom: '-15px' }}>
            <GenericForm formConfiguration={TimeTrackerForm} form={form} />
          </div>
        </Col>
        <Row align="middle" justify="center" style={{ gap: '16px' }}>
          <Col>
            <div style={{ font: 'bold', fontSize: '18px' }}>
              {formatTime(time)}
            </div>
          </Col>
          <Col>
            <StyledTimeTrackerButton
              className="start-button"
              onClick={handleStart}
              disabled={isActive}
            >
              {t('Start')}
            </StyledTimeTrackerButton>
          </Col>
          <Col>
            <StyledTimeTrackerButton
              type={isPaused ? 'primary' : 'default'}
              onClick={handlePause}
              disabled={!isActive}
            >
              {isPaused ? t('Resume') : t('Pause')}
            </StyledTimeTrackerButton>
          </Col>
          <Col>
            <StyledTimeTrackerButton
              danger
              onClick={handleStop}
              disabled={!isActive && time === 0}
            >
              {t('Stop')}
            </StyledTimeTrackerButton>
          </Col>
        </Row>
      </StyledTrackingFilterPanelRow>
      <StyledTrackingSummaryListContent />
    </>
  );
};

export default TimeTracker;
