export enum Attendance {
  FETCH_ATTENDANCE_START = 'FETCH_ATTENDANCE_START',
  FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS',
  FETCH_ATTENDANCE_ERRORED = 'FETCH_ATTENDANCE_ERRORED',
}

export interface AttendanceState {
  data: {
    data: Array<Array<number>>;
    xLabels: Array<string>;
    yLabels: Array<string>;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
