import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PurchaseRequestDataType } from 'types/PurchaseRequest';
import { fetchSinglePurchaseRequest } from 'redux/purchaseRequest/actions';

export function usePurchaseRequestData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.purchaseRequests
  );
  const {
    data: purchaseRequest,
    loading: purchaseRequestLoading,
    loaded: purchaseRequestLoaded,
    errored: purchaseRequestrrored,
    error: purchaseRequestError,
  } = useSelector((state: any) => state.purchaseRequest);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const purchaseRequestId =
    purchaseRequest?.id ??
    (searchParams.get('edit-purchase-request') as string);

  useEffect(() => {
    if (purchaseRequestId) {
      dispatch(fetchSinglePurchaseRequest(purchaseRequestId));
    }
  }, [purchaseRequestId]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
    purchaseRequest: {
      data:
        purchaseRequest ??
        data?.data?.find(
          (item: PurchaseRequestDataType) => item.id === purchaseRequestId
        ),
      loading: purchaseRequestLoading,
      loaded: purchaseRequestLoaded,
      errored: purchaseRequestrrored,
      error: purchaseRequestError,
    },
  };
}
