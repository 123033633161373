import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const FullWidthRow = styled(Row)`
  width: 100%;
`;

export const ProjectName = styled(Typography.Text)`
  display: block;
`;

export const SubstituteName = styled(Typography.Text)`
  display: flex;
  flex-direction: column;
`;

export const WrapperOverFlow = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  padding: 10px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
