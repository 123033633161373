import { useSelector } from 'react-redux';

export default function useExpenseReport() {
  const expenseReport = useSelector((state: any) => state.expenseReport);

  const {
    data: singleExpenseReport,
    loading: singleExpenseReportLoading,
    loaded: singleExpenseReportLoaded,
    errored: singleExpenseReportErrored,
    error: singleExpenseReportError,
  } = useSelector((state: any) => state.singleExpenseReport);

  return {
    data: expenseReport?.data?.data || [],
    loading: expenseReport?.loading,
    loaded: expenseReport?.loaded,
    error: expenseReport?.error,
    pagination: expenseReport?.data?.meta,

    singleExpenseReport,
    singleExpenseReportLoading,
    singleExpenseReportLoaded,
    singleExpenseReportErrored,
    singleExpenseReportError,
  };
}
