import { Mentions } from 'antd';
import styled from 'styled-components';

export const PostContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 12px;
`;

export const PostImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
`;

export const PostContent = styled.p`
  padding: 15px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin-bottom: -10px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 19px;
  border-top: 1px solid #f1f1f1;
`;

export const ActionButton = styled.button<{ hoverColor: string }>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
  &:focus {
    outline: none;
  }
`;

export const CommentsSection = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #f1f1f1;
`;

export const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const Comment = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0px;
  }
`;

export const CommentAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
`;

export const CommentContent = styled.div`
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
`;

export const CommentUser = styled.span`
  font-weight: bold;
  color: #333;
`;

export const CommentText = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
`;

export const CommentInputWrapper = styled.div`
  display: flex;
  padding: 10px 15px;
  background-color: #fff;
  border-top: 1px solid #f1f1f1;
`;

export const CommentInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 14px;
  color: #333;
  margin-right: 10px;

  &::placeholder {
    color: #aaa;
  }
`;

export const StyledMentions = styled(Mentions)`
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 12px;
  outline: none;
  font-size: 14px;
  color: #333;
  margin-right: 10px;

  &.ant-mentions {
    border-radius: 12px;
    border-color: #ddd;
  }

  &.ant-mentions-focused {
    border-color: #40a9ff;
    box-shadow: none;
  }

  .ant-mentions-input {
    padding: 5px;
    border-radius: 12px;
    height: 30px;
    line-height: 20px;
  }

  &::placeholder {
    color: #aaa;
  }
`;

export const AddCommentButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;
export const StyledUploadButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #007bff;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  input[type='file'] {
    display: none;
  }
`;
export const NoCommentsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: gray;
  font-size: 16px;
  font-style: italic;
  text-align: center;
`;
export const PinIconWrapper = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
  font-size: 1em;
`;
// Styled Components

export const EmployeeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
`;

export const EmployeeItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`;

export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const EmployeeName = styled.span`
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
`;

export const ShareIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
  color: #007bff;
  transition: color 0.2s ease;
  transform: rotate(-20deg);

  &:hover {
    color: #0056b3;
  }
`;

export const LoadingMessage = styled.p`
  text-align: center;
  font-size: 16px;
  color: #999;
`;
export const NoPostsMessage = styled.div`
  font-size: 18px;
  color: gray;
  text-align: center;
  margin: 20px 0;
`;

export const Count = styled.span`
  display: inline-block;
  width: 20px;
  text-align: center;
`;

export const TextWithPinWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 3px;
  font-size: 12px;
  font-style: italic;
  color: gray;
`;

export const CommentTimeWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 13px;
  color: gray;
`;
export const StyledSpinner = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ButtonLink = styled.button`
  background: transparent;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  padding: 0;
  font: inherit;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledViewMore = styled.div`
  text-align: center;
  cursor: pointer;
  color: #007bff;
  font-size: 16px;
`;
