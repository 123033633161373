import { EvaluationPeriodQueryParamType } from 'types/QueryParams';
import { apiURLs } from 'api/constants';
import {
  ASSET_SEARCH_FIELDS,
  CANDIDATE_SEARCH_FIELDS,
  CERTIFICATION_SEARCH_FIELDS,
  DEPARTMENT_SEARCH_FIELDS,
  EMPLOYE_SEARCH_FIELDS,
  EVALUATION_SEARCH_FIELDS,
  INTERVIEW_SEARCH_FIELDS,
  ONBOARDING_SEARCH_FIELDS,
  OPEN_POSITION_SEARCH_FIELDS,
  PROJECT_SEARCH_FIELDS,
  QUESTIONNAIRES_SEARCH_FIELDS,
  IDEABOX_SEARCH_FIELDS,
  TRAINING_SEARCH_FIELDS,
  USER_SEARCH_FIELDS,
  VENDOR_SEARCH_FIELDS,
  WORK_POSITION_SEARCH_FIELDS,
} from './searchable-fields.const';

export const getFields = (entity: string) => {
  switch (entity) {
    case '/candidates':
      return CANDIDATE_SEARCH_FIELDS;
    case '/open-positions':
      return OPEN_POSITION_SEARCH_FIELDS;
    case '/interviews':
      return INTERVIEW_SEARCH_FIELDS;
    case '/vendor':
      return VENDOR_SEARCH_FIELDS;
    case '/employees':
      return EMPLOYE_SEARCH_FIELDS;
    case '/evaluation':
      return EVALUATION_SEARCH_FIELDS;
    case '/training':
      return TRAINING_SEARCH_FIELDS;
    case '/users':
      return USER_SEARCH_FIELDS;
    case '/department':
      return DEPARTMENT_SEARCH_FIELDS;
    case '/project':
      return PROJECT_SEARCH_FIELDS;
    case '/onboarding':
      return ONBOARDING_SEARCH_FIELDS;
    case '/work-position':
      return WORK_POSITION_SEARCH_FIELDS;
    case '/asset':
      return ASSET_SEARCH_FIELDS;
    case '/certifications':
      return CERTIFICATION_SEARCH_FIELDS;
    case '/questionnaires':
      return QUESTIONNAIRES_SEARCH_FIELDS;
    case '/ideabox':
      return IDEABOX_SEARCH_FIELDS;
    default:
      return [];
  }
};

export function searchFields(queryParams: any, apiURL: string) {
  const filterFields = getFields(apiURL);
  if (apiURL === apiURLs.vendorQuestionnaire) {
    return {
      ...queryParams,
    };
  }
  if (apiURL === `${apiURLs.vendorQuestionnaire}-category`) {
    return {
      ...queryParams,
    };
  }
  if (apiURL === `${apiURLs.vendorQuestionnaire}-score`) {
    return {
      ...queryParams,
    };
  }
  if (apiURL === apiURLs.evaluation) {
    return {
      ...queryParams,
      filter: {
        filterFields: queryParams?.filter?.filterFields,
        filterText: queryParams?.filter?.filterText,
      },
    };
  }
  if (apiURL === apiURLs.employmentType) {
    return {
      ...queryParams,
      filter: {
        filterFields: queryParams?.filterFields,
        filterText: queryParams?.filterText,
      },
    };
  }
  if (apiURL === apiURLs.questionnaires) {
    return {
      ...queryParams,
      filterOptions: {
        filterFields,
        filterText: queryParams?.filterOptions?.filterText,
      },
    };
  }
  if (apiURL === apiURLs.vendor) {
    return {
      ...queryParams,
      filterOptions: {
        filterFields,
        filterText: queryParams?.filterOptions?.filterText,
      },
    };
  }
  if (apiURL === apiURLs.workPosition) {
    return {
      ...queryParams,
      filterOptions: {
        filterFields,
        filterText: queryParams?.filterOptions?.filterText,
      },
    };
  }

  if (apiURL === apiURLs.feed) {
    return {
      ...queryParams,
    };
  }

  return {
    ...queryParams,
    filterFields: getFields(apiURL),
  };
}

export function searchField(
  queryParams: EvaluationPeriodQueryParamType,
  apiURLs: string,
  searchTerm: any
) {
  const filterFields = getFields(apiURLs);
  const filter = {
    filterFields,
    filterText: searchTerm,
  };

  return {
    ...queryParams,
    filter: { ...queryParams.filter, ...filter },
  };
}
