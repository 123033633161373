import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { searchParamsAppend } from 'ui-v2/components/HeaderActions/config';
import { deletePayrollRule } from 'api/payrollRulesService';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchPayrollRules } from 'redux/payrollRules/actions';
import { usePayrollRuleData } from 'ui-v2/hooks/usePayrollRule';
import { fetchPayrollDates } from 'redux/payrollReport/actions';
import { deletePayrollReport } from 'api/payrollReportService';
import EyeIcon from 'Icons/EyeIcon';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const PayrollRulesActions: React.FC<Id> = ({ id }) => {
  const navigate = useNavigate();
  const { pagination } = usePayrollRuleData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onViewClick = () => {
    navigate(`payroll-rule-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-rule',
        value: id,
      })
    );
  };

  const onDeleteClick = async () => {
    try {
      const response = await deletePayrollRule(id);

      if (response.status === 200) {
        toast.success(`${t('deletePayrollRuleSuccess')}!`);
        dispatch(fetchPayrollRules({ page: pagination?.page || 1, take: 10 }));
      }
    } catch (e) {
      toast.warning(`${t('deletePayrollRuleFailed')}!`);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('details')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t('edit')}>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="topLeft"
              title={`${t('deletePayrollRule')}?`}
              onConfirm={onDeleteClick}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const PayrollDatesActions: React.FC<{
  id: string;
  tenantLocationId: string;
  reportId: string;
}> = ({ id, tenantLocationId, reportId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onViewClick = () => {
    navigate(`view-report?date=${id}&tenantLocationId=${tenantLocationId}`);
  };

  const onDeleteClick = async () => {
    try {
      const response = await deletePayrollReport(reportId);
      if (response.status === 200) {
        toast.success(t('deletePayrollReportSuccess'));
        dispatch(fetchPayrollDates());
        return;
      }
    } catch {
      toast.warning(t('deletePayrollReportFailed'));
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('viewPayroll')}>
              <IconButton onClick={onViewClick}>
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              placement="topLeft"
              title={`${t('deletePayrollReport')}?`}
              onConfirm={onDeleteClick}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('deletePayroll')}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PayrollRulesActions;
