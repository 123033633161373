import { Col, Form, Row } from 'antd';
import {
  createEmergencyContact,
  updateEmergencyContact,
} from 'api/employeeEmergencyContactsService';
import {
  prefixSelector,
  phoneNumberValidator,
} from 'components/NewForms/EmployeeForm/components/utils';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssetsIcon } from 'Icons/AssetsIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { EmergencyContactDto } from 'types/EmergencyContact';
import { InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import { toastErrorMessages } from 'utils/utilFunctions';

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId?: any;
  id: string;
}

export default function AddEmergencyContactModal({
  open,
  closeModal,
  employeeId,
  id,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { employeeDetail, employee } = useEmployeesData();
  const { t } = useTranslation();

  const contacts = employeeDetail?.emergencyContact;
  const contact = useMemo(
    () => contacts?.find((item: { id: string }) => item.id === id),
    [contacts, id]
  );
  const relationshipOptions = [
    {
      id: 'friend',
      label: t('friend'),
      value: 'Friend',
    },
    {
      id: 'coworker',
      label: t('coworker'),
      value: 'Coworker',
    },
    {
      id: 'family',
      label: t('family'),
      value: 'Family',
    },
  ];

  const AddWorkExperienceFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'fullName',
          label: t('fullName'),
          type: 'input',
          defaultValue: contact?.fullName,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'relationship',
          label: t('relationship'),
          type: 'select',
          defaultValue: contact?.relationship,
          selectOptions: relationshipOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: contact?.phoneNumber,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: prefixSelector[0].id,
            placeholder: '',
            prefixWidth: 75,
          },
        },
        {
          col: 11,
          offset: 2,
          name: 'email',
          label: t('email'),
          type: 'input',
          defaultValue: contact?.email,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 6,
          offset: 0,
          name: 'country',
          label: t('country'),
          type: 'input',
          defaultValue: contact?.country,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'city',
          label: t('city'),
          type: 'input',
          defaultValue: contact?.city,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 3,
          name: 'address',
          label: t('address'),
          type: 'input',
          defaultValue: contact?.address,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [contact, id]
  );

  const onSubmit = () => {
    setLoading(true);
    const formValues: EmergencyContactDto = form.getFieldsValue(true);

    if (id) {
      return updateEmergencyContact(id, formValues)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(fetchEmployeesDetail(employee?.id));
            toast.success(t('successfullyUpdatedContact'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return createEmergencyContact({
      ...formValues,
      employeeId,
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch(fetchEmployeesDetail(employeeId));
          toast.success(t('successfullyaddedContact'));
          closeModal();
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <GenericModal
      title={id ? t('editemergencyContact') : t('addemergencyContact')}
      open={open}
      closeModal={closeModal}
      icon={<AssetsIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={closeModal}
                loading={loading}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
