import { OpenPositionType } from 'interfaces/OpenPosition';
import { Task } from './Task';
import { CandidatesType, CandidateType } from './Candidates';
import { EmployeeType } from './Employee';

export interface InterviewTypeModel {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  score: number;
  firstName?: string;
  lastName?: string;
}

export interface InterviewCurrentType {
  id: string;
  comments: string;
  date: Date;
  candidateOpenPositionId: string;
  candidate: CandidatesType;
  interviewType: InterviewTypeModel;
  partecipants: EmployeeType[];
}
export interface InterviewType {
  id: string;
  comments: string;
  comment?: string;
  date: Date;
  candidate?: CandidatesType;
  candidateOpenPositionId: string;
  interviewType: InterviewTypeModel;
  partecipants: EmployeeType[];
  tasks: Task[];
  score?: number;
  name?: string;
}

export interface InterviewModel {
  id: string;
  date: Date;
  comment: string;
  candidate: CandidatesType;
  interviewType: InterviewTypeModel;
  companyId: string;
}

export interface InterviewTypeDTO {
  id?: string;
  comment?: string;
  comments?: string;
  date: Date | string;
  interviewType?: InterviewTypeModel;
  candidateOpenPositionId: string;
  offboardingId?: string;
  candidateOpenPositions?: any;
  interviewTypeId: any;
  tasks?: Task[];
  partecipants: any[];
  score?: number;
}

export interface InterviewCandidateOpenPositionType {
  id: string;
  candidate: CandidateType;
  openPositions: OpenPositionType;
}

export interface InterviewOrderType {
  id: string;
  name: string;
  order: number;
}

export interface InterviewResponseType {
  id: string;
  comments: string;
  date: string;
  interviewType: InterviewOrderType;
  candidateOpenPositions: InterviewCandidateOpenPositionType;
}

export interface InterviewInputType {
  date: string;
  comments?: string;
  partecipants: Array<string>;
  interviewTypeId: string;
  candidateOpenPositionId: string;
  time?: string;
  candidateId: any;
}

export enum InterviewNames {
  EXIT_INTERVIEW = 'Exit interview',
  INTERVIEW = 'Interview',
  PRACTICAL_TASK_TESTS = 'Practical task/tests',
  CULTURE_FIT_MEETING = 'Culture-fit meeting',
}
