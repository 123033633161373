import { Dispatch } from 'redux';
import { getAllGoals } from 'api/goalService';
import { QueryParamsDTO } from 'types/QueryParams';
import { GoalAction } from './types';

export const fetchGoals =
  (queryParams: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: GoalAction.FETCH_GOAL_START,
    });
    getAllGoals(queryParams)
      .then((res) => {
        dispatch({
          type: GoalAction.FETCH_GOAL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: GoalAction.FETCH_GOAL_ERRORED,
          payload: e,
        });
      });
  };
export const clearFetchGoals = () => (dispatch: Dispatch) => {
  dispatch({
    type: GoalAction.CLEAR_GOAL,
  });
};
