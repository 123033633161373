import { Col, Row, Switch } from 'antd';
import React, { useState } from 'react';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';

interface FeatureFlagsProps {
  featureId: string;
  tenantId: string;
  status: boolean;
}

const FeatureFlagActions: React.FC<FeatureFlagsProps> = ({
  featureId,
  tenantId,
  status,
}) => {
  const [isOn, setIsOn] = useState(status);
  const { updateTenantFeatureStatus } = useTenantFeatureData();

  const onChange = () => {
    setIsOn(!isOn);
    updateTenantFeatureStatus(tenantId, featureId, !isOn);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Switch checked={isOn} onChange={onChange} />
        </Row>
      </Col>
    </Row>
  );
};

export default FeatureFlagActions;
