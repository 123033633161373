import { Button, Card, Col, DatePicker, Row, Select, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import Styled from 'styled-components';

export const StyledColumn = Styled(Column)`
  width: 100%;
`;

export const StyledColHalfWidth = Styled(Col)`
  width: 50%;
`;
export const StyledColFullfWidth = Styled(Col)`
  width: 100%;
`;
export const StyledRow = Styled(Row)`
  padding: 0 2rem;
`;

export const StyledEdit = Styled.span`
  padding-right: 0.4em;
`;

export const StyledDatePicker = Styled(DatePicker)`
  width: 20rem;
`;
export const StyledDatePickerFullWidth = Styled(DatePicker)`
  width: 100%;
`;
export const StyledButton = Styled(Button)`
  width: 100%;
`;
export const StyledButtonLink = Styled(Button)`
&[class*='ant-btn'] {
    display: block;
    margin-top: 10px;
    padding-left: 0px;
    padding: 0;
  } 
`;
export const StyledSelect = Styled(Select)`
  width: 100%;
  border-radius: 5px;
 
`;

export const StyledButtonPosition = Styled(Button)`
  font-size: 0.9em;
  padding-left: 0px;
  padding-bottom: 0px;
  display: block;
`;

export const EmergencyTitle = Styled(Typography)`
  font-weight: semi-bold;
  font-size: 1.8em;
  color: #b83c30;
`;

export const StyledRowEmergecyDescription = Styled(Row)`
  padding: 0 1rem;
`;

export const StyletEmployeeAvatar = Styled('div')`
 
  & .ant-card-meta{
    width: 30rem;
    float: left; 
  }   

  & .ant-avatar {
    background-color: #105089;
    font-size: 1.3em;
  }

  & .inicials {
    color: #ffff;
  }

  & .title-size {
    font-size: 1em;
  }

  & .ant-upload-list {
    display: none;
  }

  & .upload {
    cursor: pointer;
    background-color: rgb(16, 80, 137, 0.9)
  }

  @media screen and (min-width: 0px) {
      text-align: center;

    & .ant-avatar ,.ant-skeleton-avatar {
      width: 110px;
      height: 110px;
      line-height: 110px;

    }

    & .ant-card-meta {
      justify-content: center;
      align-items: top;
      display: inline-flex;
      flex-direction: column;
      margin: 0px;
    }
    
    & .ant-skeleton, .ant-skeleton-content {
      justify-content: center;
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      margin: 0;
     }
    
     & input {
      width: 20.5rem;
      margin-bottom: 0.5rem;

    }

    & .ant-card-meta-detail {
      margin-top: 0.5rem;
      margin-left: -0.5rem;
    }

    & .title-size {
      text-align: left;
      margin-top: 0.5rem;
      margin-left: 0.5rem;

    }

  }

  @media screen and (min-width: 768px) {
      text-align: start;

    & .ant-card-meta{
      justify-content: start;
      text-align: baseline;
      flex-direction: row;
    }

    & .ant-avatar, .ant-skeleton-avatar {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
      & .ant-skeleton, .ant-skeleton-content {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 60%;
     }
    

    & .details {
      display: flex-column;
    }

    & input {
      margin: 0 0 0.5rem 0;

      width: 10rem;
      margin-right: 0.5rem;
    }
    
    & .ant-card-meta-detail {
      margin-top: 0.5rem;
      padding-left: 0.5rem;
    }
   

    & .ant-card-meta-avatar {
    align-self: baseline;   

    }

  & .input-style--title {
    width: 10rem;
  }
   & .title-size {
      text-align: left;
      margin: 0;
      padding-bottom: 0.5rem;
    }
  
  }
`;

export const StyledEmployeeInfoCard = Styled(Card)`

  border-radius: 5px;

  & .typography-size, .title-size {
    font-size: 1em;
  }

  & .typography-size--status {
    font-size: 0.9em;
    margin-top: 7px;
  }

  & .ant-skeleton-title  {
    margin: 0;
  }

  & .input-style {
    width: 20rem;
    margin-right: 1rem;
  }

  & .datepicker > .ant-typography{
    padding-top: 0.5rem;
  }

  & .employee-info--select{
    width: 20rem;
  }

  & .timeline--positions {
    padding-top: 0.5rem;
    overflow: scroll;
  }

  & .timeline--positions-on-edit {
    padding-top: 0.5rem;
    height: 40rem;
  }

  & .status-change-select {
    width: 5rem;
  }
  @media screen and (min-width: 0px) {
    & h5 {
      padding-top: 2rem;
      
    }
    
    & .timeline--positions {
      height: auto;
    }
  }

   @media screen and (min-width: 768px){
      & h5 {
      padding-top: 0.5rem;
    }

    & .timeline--positions {
      height: 23rem;
    }
   }
`;

export const StyledButtonFloatRight = Styled(Button)`
  float: right;
`;

export const StyledDetails = Styled(Row)`
  padding: 0 4rem;

  & .ant-col > article {
    margin-bottom: 1rem;
  }
`;

export const StyledDiv = Styled.div`
width: 100%;
`;
