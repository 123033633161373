import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFeatureFlagsTenants,
  updateFeatureFlagsTenant,
} from 'redux/featureFlagsTenant/action';
import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';

export function useTenantFeatureData() {
  const tenantFeatureList = useSelector(
    (state: any) => state.featureFlagsTenant
  );
  const dispatch = useDispatch();

  const getTenantFeatureList = (tenantId: string) => {
    // fire api call by tenantid
    // store to redux

    // filter calls this
    dispatch(fetchFeatureFlagsTenants(tenantId));
  };

  const updateTenantFeatureStatus = (
    tenantId: string,
    featureId: string,
    status: boolean
  ) => {
    const updater: UpdateFeatureFlagsTenantDTO = {
      tenantId,
      featureId,
      status,
    };
    // fire api call update
    // store in redux
    dispatch(updateFeatureFlagsTenant(updater));
  };

  return {
    data: tenantFeatureList?.data || [],
    getTenantFeatureList,
    // updateData,
    updateTenantFeatureStatus,
  };
}
