import { Col, Row, Form } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import DateHolidays from 'date-holidays';
import GenericForm from 'components/NewForms/Form';
import { InputTypes } from 'types/FormTypes';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import PayrollDateCalendar from '../PayrollDateCalendar';
import { PayrollHoliday } from './PayrollDateModal';
import HolidaySelectableList from '../PayrollDateCalendar/HolidaySelectableList';

interface PayrollDateModalContentProps {
  onCloseModal: () => void;
  onFinish: (
    nationalHolidays: Date[],
    date: Date,
    tenantLocationId: string
  ) => void;
}

const PayrollDateModalContent: React.FC<PayrollDateModalContentProps> = ({
  onCloseModal,
  onFinish,
}) => {
  const { t, i18n } = useTranslation();
  const { tenantPersisted: tenant } = useTenantsData();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [form] = Form.useForm();
  const [selectedHolidays, setSelectedHolidays] = useState<PayrollHoliday[]>(
    []
  );

  const holidays: PayrollHoliday[] = useMemo(() => {
    const countryCode = Country.getAllCountries().find(
      (country) => country.name === tenant?.country
    )?.isoCode;

    if (!countryCode) {
      return [];
    }

    const dateHolidays = new DateHolidays(countryCode);
    dateHolidays.setLanguages(i18n.language);

    const endOfMonth = moment(selectedDate).endOf('month');
    const startOfMonth = moment(selectedDate).startOf('month');
    const foundHolidays = dateHolidays
      .getHolidays(selectedDate.get('year'))
      .map((el) => ({
        name: el.name,
        date: moment(el.date),
      }))
      .filter(
        (h) =>
          h.date.format('MM/YYYY') === moment(selectedDate).format('MM/YYYY') &&
          moment(h.date).isSameOrBefore(endOfMonth) &&
          moment(h.date).isSameOrAfter(startOfMonth)
      );

    return foundHolidays;
  }, [tenant, selectedDate, i18n]);

  const onGenerateReport = useCallback(() => {
    const { value: tenantLocationId } = form.getFieldValue('tenantLocationId');
    if (selectedDate && tenantLocationId) {
      onFinish(
        selectedHolidays?.map((el) => el.date.toDate()),
        selectedDate.toDate(),
        tenantLocationId
      );
    }
  }, [selectedHolidays, selectedDate, selectedHolidays, form]);

  const setDate = (date: moment.Moment) => {
    setSelectedDate(date);
    setSelectedHolidays([]);
  };

  const formConfiguration: any = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    []
  );

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <PayrollDateCalendar
          holidays={holidays}
          selectedDate={selectedDate}
          setDate={setDate}
          modal={false}
        />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <HolidaySelectableList
              holidays={holidays}
              selectedHolidays={selectedHolidays}
              setSelectedHolidays={setSelectedHolidays}
            />
          </Col>
          <Col span={24}>
            <GenericForm formConfiguration={formConfiguration} form={form} />
          </Col>
          <Col span={11}>
            <StyledButton htmlType="reset" danger onClick={onCloseModal}>
              {t('cancel')}
            </StyledButton>
          </Col>
          <Col span={11} offset={2}>
            <StyledButton
              type="primary"
              disabled={!selectedDate}
              onClick={onGenerateReport}
            >
              {t('generateReport')}
            </StyledButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PayrollDateModalContent;
