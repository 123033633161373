import React from 'react';
import { Moment } from 'moment';
import { Avatar } from 'antd';

import { TimeOffRequest } from 'types/TimeOff';
import { Center, StyledAvatar } from './CellRenderStyles';

interface IProps {
  cellDate: Moment;
  requests: Array<TimeOffRequest>;
  onSelect: (requests: Array<TimeOffRequest>) => void;
  isHoliday: boolean;
  holidayName: string | undefined;
}

const CellRender = ({
  cellDate,
  requests,
  onSelect,
  isHoliday,
  holidayName,
}: IProps) => (
  <Center
    key={cellDate.toString()}
    onClick={() => onSelect(requests)}
    style={isHoliday ? { backgroundColor: '#add8e6' } : {}}
  >
    <Avatar.Group maxCount={2}>
      {requests.map((item) => (
        <StyledAvatar size={25} style={{}}>
          {`${item?.employee?.firstName.charAt(
            0
          )} ${item?.employee?.lastName.charAt(0)}`}
        </StyledAvatar>
      ))}
    </Avatar.Group>
    {isHoliday && <div>{holidayName}</div>}
  </Center>
);

export default React.memo(CellRender);
