import React from 'react';
import EmployeePersonalInfo from 'ui-v2/components/Employees/PersonalInfo';
import { formatDate } from 'utils/utilFunctions';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';

const EmployeeProfile = () => {
  const { employeeBasicProfile, employeeDetail } = useEmployeesData();
  const item = employeeDetail || employeeBasicProfile;

  return (
    <EmployeePersonalInfo
      birthDate={formatDate(item?.birthDate as unknown as Date)}
      email={item?.email}
      status={
        (item?.employeeEmploymentType &&
          item.employeeEmploymentType[0]?.employmentType?.name) ||
        ''
      }
      phoneNumber={item?.phoneNumber}
      phoneNumberPrefix={item?.phoneNumberPrefix}
      gender={item?.gender}
      maritalStatus={item?.martialStatus}
      employeeIdentifier={item?.employeeIdentifier}
      loading={!employeeBasicProfile && !employeeDetail}
    />
  );
};
export default EmployeeProfile;
