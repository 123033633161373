import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { EdititingIcon } from 'Icons/EditIcon';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  BUTTONS,
  searchParamsAppend,
} from 'ui-v2/components/HeaderActions/config';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { t } from 'i18next';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const ProjectActions: React.FC<Id> = ({ id }) => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const onViewClick = () => {
    navigate(`project-details?id=${id}`);
  };

  const onEditClick = () => {
    setSearchParams((searchParams: any) =>
      searchParamsAppend({
        searchParams,
        key: 'edit-project',
        value: id,
      })
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('projectDetails')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <RoleBasedButton btn={BUTTONS.EDIT_BUTTON}>
            <Col>
              {authUserRole === AUTH_ROLES.EMPLOYEE ? (
                ''
              ) : (
                <Tooltip title={t('Edit Project')}>
                  <IconButton onClick={onEditClick}>
                    <EdititingIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Col>
          </RoleBasedButton>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectActions;
