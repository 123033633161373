import { removeExpenseReport } from 'api/expensesService';
import DataGrid from 'components/common/DataGrid/DataGrid';
import ExpenseReportModalForm from 'pages/Expenses/ExpenseReportModalForm';
import AddExpenseReportForm from 'pages/Expenses/AddExpenseReportForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  clearFetchSingleExpenseReport,
  fetchExpenses,
} from 'redux/expenses/action';
import Content from 'ui-v2/components/Content';
import useExpenseReport from 'ui-v2/hooks/useExpenseReportData';
import useHeader from 'ui-v2/hooks/useHeader';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function ExpensesList() {
  const { columns, take } = useHeader();
  const { data, loading, pagination } = useExpenseReport();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalAddVisible, setModalAddVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const params = {
      pageOptions: { page: 1, take: 10 },
    };
    dispatch(clearFetchSingleExpenseReport());
    dispatch(fetchExpenses(params));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      pageOptions: { page, take },
      filterOptions: { filterText: searchTerm },
    };
    dispatch(fetchExpenses(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      pageOptions: { take },
      filterOptions: { filterText: searchTerm },
    };
    dispatch(fetchExpenses(params));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    if (searchParams.get('new-expense') === 'true') {
      searchParams.delete('new-expense');
      setSearchParams(searchParams);
      setModalAddVisible(true);
    }
    if (searchParams.get('edit-expense')) {
      setModalVisible(true);
    }
  }, [searchParams]);

  async function deleteExpenseReportById(id: string | null) {
    if (!id) return;
    removeExpenseReport(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('deletedExpense'));
          dispatch(
            fetchExpenses({
              pageOptions: { page: pagination?.page, take },
            })
          );
        }
      })
      .catch(toastErrorMessages);
  }

  async function onDelete(ids: React.Key[]) {
    const arrayOfPromises: Array<Promise<unknown>> = [];
    ids?.forEach(async (id) => {
      arrayOfPromises.push(deleteExpenseReportById(id?.toString()));
    });
    await Promise.all(arrayOfPromises);
  }

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={columns}
          data={data}
          paginationName="ExpensesReport"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onSelectedDelete={onDelete}
          onPageChange={onPageChange}
          showSearch
          onSearch={onSearch}
        />
        {modalVisible && (
          <ExpenseReportModalForm
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              searchParams.delete('edit-expense');
              setSearchParams(searchParams);
              dispatch(clearFetchSingleExpenseReport());
            }}
          />
        )}

        {modalAddVisible && (
          <AddExpenseReportForm
            open={modalAddVisible}
            closeModal={() => {
              setModalAddVisible(false);
              searchParams.delete('new-expense');
              setSearchParams(searchParams);
              // dispatch(clearFetchSingleExpenseReport());
            }}
          />
        )}
      </>
    </Content>
  );
}
