import { CurrencyType } from './Candidates';
import { EmployeeType } from './Employee';

export enum ExpenseReportStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export enum ExpenseReportCategory {
  MEAL = 'meal',
  TRAVEL = 'travel',
  SUPPLY = 'supply',
  OTHER = 'other',
}
export interface DocumentType {
  fileName: string;
  content: string;
}

export interface ProjectDetails {
  name: string;
  id: string;
}
export interface CreateExpenseLines {
  key: number;
  id: number;
  expenseDate: Date;
  category: ExpenseReportCategory;
  amount: string;
  description?: string;
  currency?: CurrencyType;
}

export interface ExpensesReport {
  id: string;
  document: DocumentType;
  status?: ExpenseReportStatus;
  employee: EmployeeType;
  expenseLines: Array<CreateExpenseLines>;
  project: ProjectDetails;
}

export interface CreateExpensesReport {
  document: DocumentType;
  status?: ExpenseReportStatus;
  employeeId: string;
  projectId: string;
  expenseLines: Array<CreateExpenseLines>;
}

export interface UpdateExpenseStatus {
  status: ExpenseReportStatus;
}
