import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryParamsDTO } from 'types/QueryParams';
import {
  clearSkillsetMatching,
  fetchSkillsetMatchingByOpenPositionId,
} from 'redux/skillsetMatching/action';

export function useSkillsetMatchingData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.skillsetMatchingReducer
  );
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const id = searchParams.get('id') as string;

  const params: QueryParamsDTO = {
    pageOptions: {
      page: 1,
      take: 10,
    },
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchSkillsetMatchingByOpenPositionId(id, params));
    }

    return () => {
      dispatch(clearSkillsetMatching());
    };
  }, [id]);

  return {
    list: data?.data ?? [],
    pagination: data?.meta,
    loading,
    loaded,
    errored,
    error,
  };
}
