import agent from 'api/agent';
import { ExpenseQueryParamsType } from 'types/QueryParams';
import {
  CreateExpensesReport,
  UpdateExpenseStatus,
} from 'types/ExpensesReport';
import { apiURLs } from './constants';

export const getSingleExpenseReport = (id: string) =>
  agent.get(`${apiURLs.expenses}/${id}`);

export const getExpenseReport = (payload: ExpenseQueryParamsType) =>
  agent.post(`${apiURLs.expenses}/get`, payload);

export const createExpenseReport = (payload: CreateExpensesReport) =>
  agent.post(apiURLs.expenses, payload);

export const removeExpenseReport = (id: string) =>
  agent.delete(`${apiURLs.expenses}/${id}`);

export const updateExpenseReport = (
  id: string,
  payload: CreateExpensesReport
) => agent.patch(`${apiURLs.expenses}/${id}`, payload);

export const updateExpenseReportStatus = (
  id: string,
  payload: UpdateExpenseStatus
) => agent.post(`${apiURLs.expenses}/${id}`, payload);

export const getExpenseDocument = (id: string) =>
  agent.get(`${apiURLs.expenses}/document/${id}`);
