import agent from 'api/agent';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getPeopleSuggestionBasedOnOpenPosition = (
  id: string,
  queryParams?: QueryParamsDTO,
  yearsOfExperienceFilter?: boolean,
  applied?: boolean
) =>
  agent.post(
    `${apiURLs.skillsSubCategory}/peopleSuggestionBasedOnOpenPosition/${id}`,
    { queryParams, yearsOfExperienceFilter, applied }
  );
