export enum SkillsetMatchingTypes {
  CANDIDATE = 'candidate',
  ARCHIVED_CANDIDATE = 'archivedCandidate',
  EMPLOYEE = 'employee',
}
export interface SkillsetSkills {
  id: string;
  subCategoryName: string;
  yearsOfExperience: number;
  description?: string;
}

export interface SkillsetMatching {
  id: string;
  firstName: string;
  lastName: string;
  type: SkillsetMatchingTypes;
  skills: Array<SkillsetSkills>;
}
